export const option = {
  backgroundColor: 'transparent',
  xAxis3D: {
    name: '',
    type: 'category'
  },
  yAxis3D: {
    name: '',
    type: 'category',
    data: ['']
  },
  zAxis3D: {
    type: 'value',
    name: ''
  },
  grid3D: {
    boxWidth: 200,
    boxDepth: 30,
    axisPointer: {
      show: false
    },
    light: {
      main: {
        intensity: 1.2
      },
      ambient: {
        intensity: 0.3
      }
    },
    viewControl: {
      alpha: 10,
      beta: 20,
      minAlpha: 10,
      maxAlpha: 10,
      minBeta: 20,
      maxBeta: 20
    }
  },
  grid: [
    {
      top: '15%',
      left: '20%',
      bottom: '15%',
      right: '15%'
    }
  ],
  series: [
    {
      type: 'bar3D',
      barSize: 15,
      stack: 'stack',
      shading: 'lambert',
      itemStyle:{
        opacity : 0.8
      },
      encode: {
        z: [1]
      }
    }
  ],
  label: {},
  title: [{}],
  resize: [false,false]
}

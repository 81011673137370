export const queryOptionsMock = [
  {
    unitId: '4',
    unitName: '表格',
    unitIconFont: 'icon-biaoge',
    list: [
      {
        id: '41',
        name: '表格',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/biaoge.png?tenant_code=paas',
        type: 'table',
        width: '410px',
        height: '340px',
        option: {
          processType: 'display',
          seriesType: '0',
          grid: [
            {
              top: '5%',
              left: '5%',
              bottom: '5%',
              right: '5%'
            }
          ]
        }
      },
      {
        id: '42',
        name: '复杂表格',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/fuzabiaoge.png?tenant_code=paas',
        type: 'antd-table',
        width: '960px',
        height: '300px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '6',
    unitName: '表单',
    unitIconFont: 'icon-form',
    list: [
      {
        id: '61',
        name: '输入框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shurukuang.png?tenant_code=paas',
        type: 'form-input',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '62',
        name: '下拉框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/xialakuang.png?tenant_code=paas',
        type: 'form-select',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '63',
        name: '数字区间',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shuzhiqujian.png?tenant_code=paas',
        type: 'form-inputNumber',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '64',
        name: '日期区间',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/riqiqujian.png?tenant_code=paas',
        type: 'form-rangePicker',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '65',
        name: '日期',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/rili.png?tenant_code=paas',
        type: 'form-datePicker',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '66',
        name: '单选框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/danxuan.png?tenant_code=paas',
        type: 'form-radio',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '67',
        name: '多选框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duoxuan.png?tenant_code=paas',
        type: 'form-checkbox',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '68',
        name: '查询按钮',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/chaxun.png?tenant_code=paas',
        type: 'form-button',
        width: '65px',
        height: '50px',
        option: {
          processType: 'other',
          seriesType: '0',
          type: 'primary'
        }
      },
      {
        id: '601',
        name: '开关',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/chaxun.png?tenant_code=paas',
        type: 'form-switch',
        width: '165px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '602',
        name: '按钮',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/chaxun.png?tenant_code=paas',
        type: 'form-btn',
        width: '165px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '2',
    unitName: '柱状图',
    unitIconFont: 'icon-zhuzhuangtu02-01',
    list: [
      {
        id: '11',
        name: '基础折线图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-line.png?tenant_code=paas',
        type: 'chart-line',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '12',
        name: '基础面积图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/area-line.png?tenant_code=paas',
        type: 'chart-line',
        option: {
          processType: 'display',
          seriesType: '0',
          series: [
            {
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'line',
              seriesLayoutBy: 'row'
            }
          ]
        }
      },
      {
        id: '21',
        name: '基础柱状图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '22',
        name: '堆叠柱状图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'display',
          seriesType: '0',
          series: [
            {
              stack: 'Ad',
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'bar'
            }
          ]
        }
      },
      {
        id: '23',
        name: '基础条形图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-strip-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'display',
          seriesType: '0',
          yAxis: [{ type: 'category' }],
          xAxis: [{ type: 'value' }],
          series: [
            {
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'bar'
            }
          ]
        }
      },
      {
        id: '24',
        name: '堆叠条形图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-strip-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'display',
          seriesType: '0',
          yAxis: [{ type: 'category' }],
          xAxis: [{ type: 'value' }],
          series: [
            {
              stack: 'Ad',
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'bar'
            }
          ]
        }
      },
      {
        id: '31',
        name: '基本饼图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/cake-pie.png?tenant_code=paas',
        type: 'chart-pie',
        option: {
          processType: 'display',
          seriesType: '0',
          series: [
            {
              legendHoverLink: false,
              grid: [
                {
                  x: '0',
                  y: '0',
                  y2: '0',
                  x2: '0'
                }
              ],
              legend: {
                selectedMode: false
              },
              itemStyle: {},
              label: {
                show: true,
                formatter: '{b}: {@[1]}({d}%)'
              },
              type: 'pie',
              radius: ['0%', '70%']
            }
          ]
        }
      },
      {
        id: '32',
        name: '环形图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-annular.png?tenant_code=paas',
        type: 'chart-pie',
        option: {
          processType: 'display',
          seriesType: '0',
          series: [
            {
              legendHoverLink: false,
              grid: [
                {
                  x: '0',
                  y: '0',
                  y2: '0',
                  x2: '0'
                }
              ],
              legend: {
                selectedMode: false
              },
              itemStyle: {},
              label: {
                show: true,
                formatter: '{b}: {@[1]}({d}%)'
              },
              type: 'pie',
              radius: ['40%', '70%']
            }
          ]
        }
      }
    ]
  },
  {
    unitId: '5',
    unitName: '文本',
    unitIconFont: 'icon-text',
    list: [
      {
        id: '51',
        name: '文本',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'text',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '52',
        name: '复杂文本',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'complexText',
        width: '260px',
        height: '50px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '53',
        name: '时间',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'time',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '54',
        name: '翻牌器',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shuzifanpaiqi.png?tenant_code=paas',
        type: 'flap',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '57',
        name: '描述列表',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'descriptions',
        width: '400px',
        height: '200px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '71',
        name: '图片',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/tupian.png?tenant_code=paas',
        type: 'image',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '9',
    unitName: '功能',
    unitIconFont: 'icon-text',
    list: [
      {
        id: '91',
        name: '打印',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'outPrint',
        width: '65px',
        height: '50px',
        option: {
          processType: 'output',
          seriesType: '0'
        }
      },
      {
        id: '92',
        name: '导出EXCEL',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'outExcel',
        width: '103px',
        height: '50px',
        option: {
          processType: 'output',
          seriesType: '0',
          type: 'primary'
        }
      }
    ]
  },
  {
    unitId: '10',
    unitName: '容器',
    unitIconFont: 'icon-rongqi',
    list: [
      {
        id: '101',
        name: '标签页',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'form-tabs',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '102',
        name: '模态框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'antd-modal',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '11',
    unitName: '地图',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '111',
        name: '中国地图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'chart-chinaMap',
        width: '250px',
        height: '250px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '1000',
    unitName: '蓝思羽',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '1001',
        name: '列表',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'chart-chinaMap',
        width: '250px',
        height: '250px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '12',
    unitName: '数据',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '121',
        name: '数据路由',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'data',
        width: '50px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      }
    ]
  },
]

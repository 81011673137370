import useMoveableTarget from '@/hooks/useMoveableTarget'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { InputNumber, Select, Switch } from 'ant-design-vue'
import { defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import { fontFamilyList } from '@/utils/constance'

export default defineComponent({
  name: 'XAxis',
  order: 10,
  setup() {
    const store = useStore()
    const { componentConfig, moveablerRef, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto'
    ]) as IState

    const handleChange = (type: string, value: any) => {
      const propKeyMap = {
        axisLabelShow: ['xAxis', '0', 'axisLabel', 'show'],
        splitAreaShow: ['xAxis', '0', 'splitArea', 'show'],
        fontFamily: ['xAxis', '0', 'axisLabel', 'textStyle', 'fontFamily'],
        fontSize: ['xAxis', '0', 'axisLabel', 'textStyle', 'fontSize'],
        splitLineShow: ['xAxis', '0', 'splitLine', 'show'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })

      const { targetCharts, options } = useMoveableTarget(selecto)
      options.xAxis = componentConfig.value.optionData.xAxis
      console.log('options', options)
      targetCharts.setOption(options, false)
    }

    const xAxisSlot = () => (
      <>
        <div class="x-axis-box common-setting-item-box">
          <div class="setting-row">
            <div class="setting-row__label">是否显示</div>
            <div class="setting-row__content">
              <Switch
                size="small"
                v-model:checked={componentConfig.value.optionData.xAxis[0].axisLabel.show}
                onChange={value => handleChange('axisLabelShow', value)}
              />
            </div>
          </div>
          <div class="setting-row">
            <div class="setting-row__label">轴背景</div>
            <div class="setting-row__content">
              <Switch
                size="small"
                v-model:checked={componentConfig.value.optionData.xAxis[0].splitArea.show}
                onChange={value => handleChange('splitAreaShow', value)}
              />
            </div>
          </div>
          <div class="setting-row">
            <div class="setting-row__label">分割线</div>
            <div class="setting-row__content">
              <Switch
                size="small"
                v-model:checked={componentConfig.value.optionData.xAxis[0].splitLine.show}
                onChange={value => handleChange('splitLineShow', value)}
              />
            </div>
          </div>
          {
            componentConfig.value.optionData.yAxis[0].axisLabel.textStyle ?
              <div class="setting-row">
                <div class="setting-row__label">字体</div>
                <div class="setting-row__content">
                  <Select
                    v-model:value={
                      componentConfig.value.optionData.xAxis[0].axisLabel.textStyle.fontFamily
                    }
                    size="small"
                    onChange={value => handleChange('fontFamily', value)}
                  >
                    {fontFamilyList.map(item => (
                      <Select.Option value={item.value}>{item.label}</Select.Option>
                    ))}
                  </Select>
                </div>
                <div class="setting-row__content">
                  <InputNumber
                    size="small"
                    min={11}
                    precision={0}
                    v-model:value={
                      componentConfig.value.optionData.xAxis[0].axisLabel.textStyle.fontSize
                    }
                    onChange={value => handleChange('fontSize', value)}
                  />
                </div>
              </div>
              : null
          }
        </div>
      </>
    )
    const _order = getCurrentInstance().proxy.$options.order
    return () => <SettingItemBox title="X轴" order={_order} v-slots={xAxisSlot} />

  },
})

import type { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { contentOptions, intervalOptions } from '@/utils/constance'
import { Input, InputNumber, Select } from 'ant-design-vue'
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import _ from 'lodash'

export default defineComponent({
  name: 'TimeConfig',
  order: 0,
  setup(props, ctx) {
    const instance: any = getCurrentInstance()
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
    })
    const state = reactive({
      color: '',
      colorPickerShow: 'none',
    })
    const store = useStore()
    const { mainJson, moveablerRef, selecto }: any = userMapState('editor', [
      'mainJson',
      'moveablerRef',
      'selecto'
    ]) as IState

    const componentConfig = computed(() => {
      return mainJson.value.body.children.find(config => config.id === store.state.editor.componentConfig.id)
    })

    watchEffect(() => {
      state.color = componentConfig.value?.option?.generalTitle?.color
    })

    //修改时间格式设置
    const handleChangeTimeConfig = (type, value) => {
      const propKeyMap = {
        showContent: ['timeConfig', 'showContent'],
        interval: ['timeConfig', 'interval'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })
      const { targetCharts, options } = useMoveableTarget(selecto)
      options.timeConfig[type] = value
      targetCharts.setOption(options)
    }

    const textSlot = () => (
      <div class="title-box common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">显示内容</div>
          <div class="setting-row__content">
            <a-checkbox-group
              onChange={(value) => {
                handleChangeTimeConfig('showContent', value)
              }}
              v-model:value={componentConfig.value.option.timeConfig.showContent}
              options={contentOptions}/>
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">间隔符</div>
          <div class="setting-row__content">
            <div class="setting-row__content">
              <a-radio-group
                onChange={(e) => {
                  handleChangeTimeConfig('interval', e.target.value)
                }}
                v-model:value={componentConfig.value.option.timeConfig.interval}>
                {
                  intervalOptions.map(item => (
                    <a-radio value={item.value}>{item.label}</a-radio>
                  ))
                }
              </a-radio-group>
            </div>
          </div>
        </div>
      </div>
    )

    return () => componentConfig.value ? <SettingItemBox title="时间格式设置" v-slots={textSlot}/> : null
  }
})

import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { PlusOutlined, CloseOutlined, LinkOutlined } from '@ant-design/icons-vue'
import { Input, Upload, Select, RadioGroup, RadioButton, InputNumber, Checkbox } from 'ant-design-vue'
import SettingItemBox from '../settingItemBox'
import SettingItemRow from '../settingItemRow'
import _ from 'lodash'
import {
  defineComponent,
  reactive,
  watchEffect,
  ref,
  watch
} from 'vue'
import { ColorPicker } from 'vue-color-kit'
import { useStore } from 'vuex'
import API from '@/api'

export default defineComponent({
  name: 'Background',
  setup(props) {
    const state = reactive({
      backgroundImageWidth: 0,
      backgroundImageHeight: 0,
      scale: 0,
      backgroundSrc: '',
      colorPickerShow: false,
      repeat: false,
      attachment: false,
    })
    const BackgroundImageRef = ref<any | null>(null)
    const store = useStore()
    const { viewerBackgroundRef, mainJson }: any = userMapState('editor', [
      'viewerBackgroundRef',
      'mainJson',
    ]) as IState


    watchEffect(() => {
      state.backgroundSrc = mainJson.value.body.style.backgroundImage
      state.repeat = mainJson.value.body.style.backgroundRepeat == 'no-repeat' ? false : true
      state.attachment = mainJson.value.body.style.backgroundAttachment == 'scroll' ? false : true
    })


    // 设置Element的背景
    const setElementAttribute = (attribute, value) => {
      if (attribute) {
        if (attribute === 'backgroundImage') {
          viewerBackgroundRef.value.style[attribute] = `url(${value})`
        } else {
          viewerBackgroundRef.value.style[attribute] = value
        }
      }
      // attribute && (viewerBackgroundRef.value.style.attribute = value)
      // viewerBackgroundRef.value.style.backgroundImage = `url(${backgroundImage})`
      // viewerBackgroundRef.value.style.backgroundRepeat = 'no-repeat'
      // viewerBackgroundRef.value.style.backgroundPosition = 'center'
      // viewerBackgroundRef.value.style.backgroundSize = 'cover'
    }

    // 设置store中的属性
    const setStoreAttribute = (type: string, value: string) => {
      store.commit('editor/SET_PAGE_ATTRIBUTE', {
        attribute: type,
        value,
      })
      store.dispatch('editor/setMainJsonStyle', {
        path: type,
        value,
      })
    }

    // 清除背景
    const cleanBackground = e => {
      e.stopPropagation()
      setStoreAttribute('backgroundImage', '')
      setElementAttribute('backgroundImage', '')
    }

    // 上传前校验
    const beforeUpload = file => {
      console.log('file', file)
      return true
    }

    // 上传图片
    const handleCustomRequest = info => {
      const formData = new FormData()
      formData.append('file', info.file)
      API.pageManage.uploadBackgroundImage(formData, mainJson.value.pageID).then((res: any) => {
        if (!res.success) return
        state.backgroundSrc = res.data.filePath
        setStoreAttribute('backgroundImage', res.data.filePath)
        setElementAttribute('backgroundImage', res.data.filePath)
      })
    }

    // 修改背景颜色
    const handleChangeBackgroundColor = backgroundColor => {
      setElementAttribute('backgroundColor', backgroundColor)
      setStoreAttribute('backgroundColor', backgroundColor)
    }

    // 修改背景颜色
    const changeColor = _.debounce(handleChangeBackgroundColor, 1000)

    // 设置背景属性
    const handleSetBackground = (attribute, value) => {
      setElementAttribute(attribute, value)
      setStoreAttribute(attribute, value)
    }

    // 背景插槽
    const backgroundSlot = () => (
      <>
        <div>
          <div class="setting-row color-picker-box">
            <div class="setting-row__label">背景颜色</div>
            <div class="setting-row__content">
              <input
                type="button"
                class="colorBtn"
                style={{
                  backgroundColor:
                    viewerBackgroundRef.value.style.backgroundColor,
                }}
                onClick={e => {
                  state.colorPickerShow = true
                }}
                onBlur={(e: any) => {
                  // color-picker-box 内元素不触发
                  if (
                    e.relatedTarget &&
                    e.relatedTarget.parentElement.className.indexOf('color') !=
                    '-1'
                  ) {
                    if (e.relatedTarget.tagName == 'INPUT') {
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener('blur', (el: any) => {
                        setTimeout(() => (state.colorPickerShow = false), 100)
                      })
                    } else {
                      e.target.focus()
                    }
                    return
                  }
                  setTimeout(() => (state.colorPickerShow = false), 100)
                }}
              ></input>
              {state.colorPickerShow && (
                <ColorPicker
                  theme="dark"
                  suckerCanvas="null"
                  suckerArea={[]}
                  color={viewerBackgroundRef.value.style.backgroundColor}
                  onChangeColor={value => {
                    // changeColor(value.hex)
                    const color = `rgba(${value.rgba.r},${value.rgba.g},${value.rgba.b},${value.rgba.a})`
                    changeColor(color)
                  }}
                  {...(props as any)}
                />
              )}
            </div>
          </div>

          <div class="setting-row">
            <div class="setting-row__label">背景图片</div>
            <div class="setting-row__content">
              <Upload
                name="background"
                listType="picture-card"
                class="background-upload"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={handleCustomRequest}
              >
                {state.backgroundSrc !== '' ? (
                  <>
                    <img
                      ref={BackgroundImageRef}
                      src={state.backgroundSrc}
                      class="background-image"
                      alt="background"
                      onLoad={(e: any) => {
                        if (mainJson.value.body.style.backgroundSize == '') {
                          state.backgroundImageWidth = e.target.naturalWidth
                          state.backgroundImageHeight = e.target.naturalHeight
                          state.scale = state.backgroundImageWidth / state.backgroundImageHeight
                        } else {
                          // state.backgroundImageWidth = mainJson.value.body.style.backgroundSize
                          console.log(mainJson.value.body.style.backgroundSize.replace('px','').split(/\s+/))
                          let _size = mainJson.value.body.style.backgroundSize.replace('px','').split(/\s+/)
                          state.backgroundImageWidth = parseInt(_size[0])
                          state.backgroundImageHeight = parseInt(_size[1])
                        }
                      }}
                    />
                    <CloseOutlined onClick={cleanBackground} />
                  </>
                ) : (
                  <PlusOutlined />
                )}
              </Upload>
            </div>
          </div>

          <div class="setting-row" v-show={BackgroundImageRef.value}>
            <div class="setting-row__label">背景位置</div>
            <div class="setting-row__content">
              <RadioGroup
                size="small"
                v-model:value={mainJson.value.body.style.backgroundPosition}
                onChange={event =>
                  handleSetBackground('backgroundPosition', event.target.value)
                }
              >
                <RadioButton value="top left">左上</RadioButton>
                <RadioButton value="center center">中央</RadioButton>
                <RadioButton value="top right">右上</RadioButton>
              </RadioGroup>
              {/* <Input
              size="small"
              v-model:value={mainJson.value.body.style.backgroundPosition}
              onChange={event =>
                handleSetBackground('backgroundPosition', event.target.value)
              }
            /> */}
            </div>
          </div>

          <div class="setting-row" v-show={BackgroundImageRef.value}>
            <div class="setting-row__label">背景大小</div>
            <div class="setting-row__content">
              {/* <Select
              v-model:value={mainJson.value.body.style.backgroundSize}
              onChange={(value: string) =>
                handleSetBackground('backgroundSize', value)
              }
              size="small"
            >
              {backgroundSizeList.map(item => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select> */}
              <InputNumber
                style={{ width: '45%' }}
                value={state.backgroundImageWidth}
                addonBefore="W"
                size="small"
                precision={0}
                onChange={(value: number) => {
                  state.backgroundImageWidth = value
                  state.backgroundImageHeight = Number(value / state.scale)
                  handleSetBackground('backgroundSize', `${state.backgroundImageWidth}px ${state.backgroundImageHeight}px`)
                }}
              />
              <LinkOutlined style={{ width: '20px' }} />
              <InputNumber
                style={{ width: '45%' }}
                value={state.backgroundImageHeight}
                addonBefore="H"
                size="small"
                precision={0}
                onChange={(value: number) => {
                  state.backgroundImageHeight = value
                  state.backgroundImageWidth = Number(value * state.scale)
                  handleSetBackground('backgroundSize', `${state.backgroundImageWidth}px ${state.backgroundImageHeight}px`)
                }}
              />
            </div>
          </div>

          <div class="setting-row" v-show={BackgroundImageRef.value}>
            <div class="setting-row__label">是否重复</div>
            <div class="setting-row__content">
              {/* <Select
              v-model:value={mainJson.value.body.style.backgroundRepeat}
              onChange={(value: string) =>
                handleSetBackground('backgroundRepeat', value)
              }
              size="small"
            >
              {backgroundRepeatList.map(item => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select> */}
              <Checkbox
                v-model:checked={state.repeat}
                v-model:value={mainJson.value.body.style.backgroundRepeat}
                onChange={event =>
                  handleSetBackground('backgroundRepeat', event.target.value ? '' : 'no-repeat')
                }
              />

              {/* <Input
                size="small"
                v-model:value={mainJson.value.body.style.backgroundRepeat}
                onChange={event =>
                  handleSetBackground('backgroundRepeat', event.target.value)
                }
              /> */}
            </div>
          </div>

          {/* <div class="setting-row" v-show={BackgroundImageRef.value}>
            <div class="setting-row__label">背景定位</div>
            <div class="setting-row__content">
              <Input
                size="small"
                v-model:value={mainJson.value.body.style.backgroundOrigin}
                onChange={event =>
                  handleSetBackground('backgroundOrigin', event.target.value)
                }
              />
            </div>
          </div> */}

          <div class="setting-row" v-show={BackgroundImageRef.value}>
            <div class="setting-row__label">图片滚动</div>
            <div class="setting-row__content">
              <Checkbox
                v-model:checked={state.attachment}
                v-model:value={mainJson.value.body.style.backgroundAttachment}
                onChange={event =>
                  handleSetBackground('backgroundAttachment', event.target.value ? 'scroll' : 'local')
                }
              />
              {/* <Input
                size="small"
                v-model:value={mainJson.value.body.style.backgroundAttachment}
                onChange={event =>
                  handleSetBackground('backgroundAttachment', event.target.value)
                }
              /> */}
            </div>
          </div>
        </div>
      </>
    )

    return () => <SettingItemBox title="背景" v-slots={backgroundSlot} />
  },
})

export const option = {
  dataset: {
    source: []
  },
  options: [],
  activeKey: "1",
  enableExtraParams: false,
  tabType: 'line',
  tabBarStyle: {},
  series: [
    {
      type: 'tabs'
    }
  ]
}

import { defineComponent, reactive, ref, nextTick, onMounted, defineAsyncComponent } from 'vue'
import type { DefineComponent } from 'vue'
import {
  Button,
  InputNumber,
  Input,
  Modal
} from 'ant-design-vue'
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons-vue"
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import { userMapState } from '@/store/userMapper'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import useMoveableTarget from '@/hooks/useMoveableTarget'

import { NumberDecimal } from 'ant-design-vue/lib/input-number/src/utils/MiniDecimal'
import './index.less'
import _, { forEach, remove } from 'lodash'
// import draggable from 'vuedraggable'

const Draggable = defineAsyncComponent<DefineComponent<any>>(
  () => import('vuedraggable') as any
) as any

export default defineComponent({
  name: 'PropertiesConfig',
  order: 2,
  setup(props, { emit }) {
    const store = useStore()
    const { componentConfig, selecto, moveablerRef, mainJson }: any = userMapState('editor', [
      'moveablerRef',
      'mainJson',
      'componentConfig',
      'selecto',
    ])



    const state = reactive({
      showDialog: false,
      column: 3,
      init: false
    })



    // 渲染组件
    const renderComponent = (key: string[], value: any) => {
      const _value = JSON.parse(JSON.stringify(value))
      
      const _key = key.join()
      const { targetCharts, options } = useMoveableTarget(selecto)
      const _options = { ...options, ...componentConfig.value.optionData }

      const oldIds = _value.map(item => item.model)
      const result = options.model.filter(item => !oldIds.includes(item.model))
      const newIds = result.map(item => item.model)
      value.map(item => {
        if (newIds.includes(item.model)) {
          item.hidden = true
        }
      })

      // 复杂对象需要手动赋值替换（array or object）
      if (Array.isArray(_options[_key])) {
        _options[_key] = value
      }
      console.log('_options---', _options, componentConfig.value.optionData)
      targetCharts.setOption(_options, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const setProperty = (key: string[], value: any): void => {
      useSetOptionProperty({
        id: componentConfig.value.id,
        key,
        value,
      })

      renderComponent(key, value)
    }


    // 打开配置弹窗
    const handleOpenModal = () => {
      initDragTable()
      state.showDialog = true

    }

    const onDelete = (index: number) => {
      // componentConfig.value.optionData.indexSource.splice(index, 1)
      // setProperty(['indexSource'], componentConfig.value.optionData.indexSource)
    }

    // 基础属性配置
    const BasicPropertiesConfig = () => (
      <div class="common-setting-item-box">

        <div class="setting-row">
          <div class="setting-row__label">列配置</div>
          <div class="setting-row__content">
            <Button type="primary" size="small" onClick={handleOpenModal}>
              属性设置
            </Button>
          </div>
        </div>
      </div>
    )

    // console.log(props.staticData)

    // const list = ref([
    //   { name: "John 1", id: 0 },
    //   { name: "Joao 2", id: 1 },
    //   { name: "Jean 3", id: 2 },
    //   { name: "Jean 4", id: 3 },
    //   { name: "Jean 5", id: 4 },
    //   { name: "Jean 6", id: 5 },
    //   { name: "Jean 7", id: 6 },
    //   { name: "Jean 8", id: 7 }
    // ])


    // const converData = (ob) => {
    //   let _data = []
    //   let obKey = Object.keys(ob)
    //   for (let i = 0; i < obKey.length; i++) {
    //     let _index = {}
    //     _index['model'] = obKey[i]
    //     _index['value'] = ob[obKey[i]]
    //     _index['label'] = obKey[i]
    //     _index['tname'] = ''
    //     _index['span'] = 1
    //     _data[i] = _index
    //   }
    //   return _data
    // }

    const arrChunk = (e, l) => {
      let num = 0;
      let _data = [];
      for (let i = 0; i < e.length; i++) {
        // if (!e[i].hidden) {
        if (i % l == 0 && i != 0 || (e[i - 1] && (e[i].span + e[i - 1].span) > l)) {
          _data.push(e.slice(num, i))
          num = i;
        }
        if ((i + 1) == e.length) {
          _data.push(e.slice(num, (i + 1)))
        }
        // } else {
        //   if (_datafix.value.findIndex(item => item.label == e[i].label) == -1) _datafix.value.push(e[i])
        // }
      }
      return _data;
    }

    // console.log('分割',chunk(list.value,3))
    let _data = ref([])

    let _datafix = ref([])

    // Modal 确定
    const handleConfirm = () => {
      let _opt = _data.value.reduce((prev, curr) => (prev.concat(curr)), [])
      
      setProperty(['model'], _opt)

      state.showDialog = false
    }

    const initDragTable = () => {
      const { targetCharts, options } = useMoveableTarget(selecto)
      const list = options.model
      state.column = options.column
      let _list = _.cloneDeep(list)
      for (let i = 0; i < _list.length; i++) {
        if ((_list[i].hidden) == true) {
          if (_datafix.value.findIndex(item => item.label == _list[i].label) == -1) _datafix.value.push(_list[i])
          _list.splice(i, 1);
        }
      }
      _data.value = arrChunk(_list, options.column)
      // if (!state.init) {
      //   _data.value = arrChunk(list, options.column)
      //   state.init = true
      // } else {
      //   _data.value = computedData(_data.value)
      // }
      _data.value = computedData(_data.value)
    }

    const dragMove = (e) => {
      // __data.value = computedData(__data.value)
      // console.log(e)
      // e.dragged.style.position = 'relative'
      // console.log(_data.value)
      let _box = document.querySelectorAll('.box-group')
      _box.forEach(item => {
        item.parentElement.style.border = '0px'
      })
      // if(e.related.className == 'box-group') e.to.parentNode.style.border = '0px'
      if (e.to.className == 'box-group') e.to.parentNode.style.border = '2px dotted #999'
    }
    // 上沿触发
    const upEnd = (e) => {
      _data.value.unshift([e.item._underlying_vm_])
      _data.value = computedData(_data.value)
    }
    // 下沿触发
    const downEnd = (e) => {
      _data.value.push([e.item._underlying_vm_])
      _data.value = computedData(_data.value)
    }

    const dragEnd = (e) => {
      //去除空的 数组
      _data.value = computedData(_data.value)
      // _data.value.forEach((arr,index)=>{
      //   if (arr.length == 0) delete _data.value[index]
      // })
    }

    const computedData = (ob) => {

      ob.map((items, rindex) => {
        let sumSpan = 0
        if (items.length == 0) {
          delete ob[rindex]
        } else {
          items.map((item, cindex) => {
            item.span = 1
            if ((item.span + sumSpan) <= state.column) {
              if (ob[rindex].length > 1) item.span = 1
              sumSpan = sumSpan + item.span
              if (cindex + 1 == ob[rindex].length) {
                if (sumSpan < state.column) {
                  item.span = item.span + state.column - sumSpan
                }
              }
            } else {
              items.splice(cindex, 1)
              if (ob[rindex + 1]) { //如果有下一行
                ob[rindex + 1].push(item)
              } else {
                ob[rindex + 1] = []
                ob[rindex + 1].push(item)
              }
            }
          })
        }
      })

      return ob
    }

    const toHidden = (e) => {
      let sindex = 0
      for (let i in _data.value) {
        if (_data.value[i]) sindex = sindex + _data.value[i].length
      }
      if (sindex <= 1) return
      for (let i in _data.value) {
        let _this = _data.value[i].findIndex(item => item.label === e.label)
        if (_this != -1) {
          _data.value[i].splice(_this, 1)
          // _data.value[i].hidden = true
          e.hidden = true
          _datafix.value.push(e)
        }
      }
      _data.value = computedData(_data.value)
    }

    const toShow = (e) => {
      let _this = _datafix.value.findIndex(item => item.label == e.label)
      if (_this != -1) {
        _datafix.value.splice(_this, 1)
        e.hidden = false
        if (_data.value[_data.value.length - 1]) {
          _data.value[_data.value.length - 1].push(e)
        } else {
          _data.value[_data.value.length - 1] = [e]
        }
      }
      _data.value = computedData(_data.value)
    }

    const nullArr = ref([])

    // 配置Modal
    const SettingTableModal = () => (
      <>
        <Modal
          v-model:visible={state.showDialog}
          forceRender
          destroyOnClose
          key={new Date().getTime()}
          title="列配置"
          width="80%"
          bodyStyle={{ height: 'auto' }}
          onOk={handleConfirm}
        >
          <div>
            <h4>展示字段</h4>
            <table class="descriptionsTable" key={new Date().getTime()}>
              <thead>
                <tr>
                  <td colspan={state.column}>
                    <Draggable
                      class="box-group"
                      group="a"
                      onAdd={upEnd}

                      item-key="label"
                      v-slots={{
                        item: () => {
                          return (
                            <span></span>
                          )
                        }
                      }}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {_data.value.map((_list, index) => {
                  // _data.value[index] = ref(_list)
                  return (
                    <Draggable
                      // id="first"
                      v-model={_data.value[index]}
                      class="list-group"
                      group="a"
                      tag="tr"
                      onMove={dragMove}
                      onUnchoose={dragEnd}
                      // group={{ name: 'people', pull: 'clone', put: false }}
                      item-key="label"
                      v-slots={{
                        item: (element: { element: { label: string, span: number, tname: string } }) => {
                          return (
                            <td
                              class="list-group-item"
                              key={element.element.label}
                              colspan={element.element.span}
                              style={{ 'width': (element.element.span / state.column * 100) + '%' }}
                            >
                              <Input
                                addonBefore={[element.element.label]}
                                placeholder={element.element.label}
                                v-model:value={element.element.tname}
                              />
                              <Button
                                shape="circle"
                                size='small'
                                onClick={() => toHidden(element.element)}
                                v-slots={{
                                  icon: () => {
                                    return (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                }} />
                            </td>
                          )
                        }
                      }}
                    />
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan={state.column}>
                    <Draggable
                      class="box-group"
                      group="a"
                      item-key="down"
                      onAdd={downEnd}
                      v-slots={{
                        item: () => {
                          return (
                            <span></span>
                          )
                        }
                      }}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div>
            <h4 v-show={_datafix.value.length > 0}>隐藏字段</h4>
            <table class="descriptionsTable" key={new Date().getTime()}>
              <tbody>
                {
                  <Draggable
                    disabled={_datafix.value.length > 0 ? false : true}
                    // id="first"
                    v-model={_datafix.value}
                    class="list-group"
                    group="a"
                    tag="tr"
                    // onMove={dragMove}
                    onUnchoose={dragEnd}
                    // group={{ name: 'people', pull: 'clone', put: false }}
                    item-key="label"
                    v-slots={{
                      item: (element: { element: { label: string, span: number, tname: string } }) => {
                        return (
                          <td
                            class="list-group-item"
                            key={element.element.label}
                            colspan={element.element.span}
                            style={{ 'width': (_datafix.value.length / 100) + '%' }}
                          >
                            <Input
                              addonBefore={[element.element.label]}
                              placeholder={element.element.label}
                              v-model:value={element.element.tname}
                              disabled
                            />
                            <Button
                              shape="circle"
                              size='small'
                              onClick={() => toShow(element.element)}
                              v-slots={{
                                icon: () => {
                                  return (
                                    <EyeOutlined />
                                  )
                                }
                              }} />
                          </td>
                        )
                      }
                    }}
                  />
                }
              </tbody>
            </table>
          </div>
        </Modal>
      </>
    )

    const renderDomSlot = () => (
      <div class="setting-container">
        {/* 基础配置 */}
        <BasicPropertiesConfig />
        {/* 列配置Modal */}
        <SettingTableModal />
      </div>
    )

    return () => <SettingItemBox title="表格配置" v-slots={renderDomSlot} />
  },
})


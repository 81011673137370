export const option = {
  backgroundColor: 'transparent',
  legend: [{}],
  yAxis: [
    {
      gridIndex: '0'
    }
  ],
  xAxis: [
    {
      type: 'category',
      boundaryGap: false
    }
  ],
  grid: [
    {
      top: '15%',
      left: '20%',
      bottom: '15%',
      right: '15%'
    }
  ],
  series: [
    {
      itemStyle: {},
      type: 'line',
      seriesLayoutBy: 'row',
      smooth: true
    }
  ],
  label: {},
  title: [{}],
  resize: [false,false]
}

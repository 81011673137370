export const option = {
  dataset: {
    source: []
  },
  generalTitle: {
    fontAlign: 'left'
  },
  timeConfig: {
    showContent: ['date', 'week', 'time'],
    interval: '1'
  }
}

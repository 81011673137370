import type { IState } from "@/store/modules/editor"
import { userMapState } from "@/store/userMapper"
import { fontFamilyList } from "@/utils/constance"
import { Input, InputNumber, Select } from "ant-design-vue"
import { BoldOutlined, ItalicOutlined, AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined } from "@ant-design/icons-vue"
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watchEffect } from "vue"
import { useStore } from "vuex"
import { ColorPicker } from 'vue-color-kit'
import SettingItemBox from '../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import type { PropType } from 'vue'
import { IBaseText } from '@/types'
import _ from 'lodash'
export default defineComponent({
  name: 'GeneralTitle',
  order: 0,
  props: {
    option: {
      type: Object as PropType<IBaseText>,
    },
    staticData: Array
  },
  setup(props, ctx) {
    const instance: any = getCurrentInstance()
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
    })
    const state = reactive({
    })
    const store = useStore()
    const { mainJson, moveablerRef, selecto }: any = userMapState('editor', [
      'mainJson',
      'moveablerRef',
      'selecto'
    ]) as IState

    const componentConfig = computed(() => {
      return mainJson.value.body.children.find(config => config.id === store.state.editor.componentConfig.id)
    })

    const handleChangeContent = (value) => {
      const { targetCharts, options } = useMoveableTarget(selecto)
      targetCharts.props.staticData.source[1][0] = value

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['staticData', 'source', '1', '0'],
        value
      })

      // options.generalTitle.text = value
      targetCharts.setOption(options)
    }

    const handleChangeStyle = (type, value) => {

      const propKeyMap = {
        fontSize: ['generalTitle', 'fontSize'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })
      const { targetCharts, options } = useMoveableTarget(selecto)
      options.generalTitle[type] = value
      targetCharts.setOption(options)
    }

    const textSlot = () => (
      <>
        <div class="setting-row">
          <div class="setting-row__label">字体大小</div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              min={11}
              precision={0}
              addon-after="px"
              v-model:value={componentConfig.value.option.generalTitle.fontSize}
              onChange={value => handleChangeStyle('fontSize', value)}
            />
          </div>
        </div>
      </>
    )

    return () => componentConfig.value ? <SettingItemBox title="文本设置" v-slots={textSlot} /> : null
  }
})

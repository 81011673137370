import { computed, defineComponent, onMounted, reactive } from 'vue'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import { Form, Tabs } from 'ant-design-vue'
import SettingItemBox from '../settingItemBox'
import IdSlot from './public/id'
import Remark from './public/remark'
import TypeSlot from './public/type'
import AnotherNameSlot from './public/anotherName'
import DataSourceSlot from './public/dataSource'
import AutoUpdateSlot from './public/autoUpdate'
import AutoAppendSlot from './private/autoAppend'
import ParamsConfig from './private/paramsConfig'
import MockEditor from './public/mockEditor'

interface IComponent {
  name: string
  order: number
  component: any
}

export default defineComponent({
  name: 'dataTabPane',
  setup(props) {
    // 当前选中的组件配置
    const { componentConfig }: any = userMapState('editor', [
      'componentConfig',
    ]) as IState

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
    }

    const state = reactive({
      activeTab: 'mock'
    })

    // 所有注册的属性组件列表(静态数据不需要ref或者reactive包裹)
    let allComponents: IComponent[] = []

    // 是否有组件ID
    const hasComponentId = computed(() => componentConfig.value.id)

    const isEntry = computed(() => componentConfig.value.optionData.processType === 'entry')
    const isOutput = computed(() => componentConfig.value.optionData.processType === 'output')


    const showApiType = computed(() => componentConfig.value.api.type && !isOutput.value)

    const showApiConfig = computed(() => hasComponentId.value && !isOutput.value)

    const isAntdTable = computed(()=>componentConfig.value.type === 'antd-table')

    const showParamsConfig = computed(() => hasParamsConfig.value && (isEntry.value || isOutput.value))



    const hasAppendConfig = computed(() => {
      const { api } = componentConfig.value
      return Object.keys(api).includes('appendConfig')
    })

    const hasParamsConfig = computed(() => {
      const { api } = componentConfig.value
      return Object.keys(api).includes('paramsConfig')
    })

   
    // 首字母大写
    const firstCapitalize = (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    // 组件排序
    const sortComponent = components => {
      return components.sort((a, b) => a.order - b.order)
    }

    const BaseConfigSlot = () => (
      <>
        <SettingItemBox title="基本配置">
          {hasComponentId.value ? <IdSlot/> : null}
          {hasComponentId.value ? <AnotherNameSlot/> : null}
          {showApiType.value ? <TypeSlot/> : null}
          {hasComponentId.value ? <Remark/> : null}
        </SettingItemBox>
      </>
    )

    // const TabsSlot = () => (
    //   <Tabs
    //     size="small"
    //     type="card"
    //     v-model:activeKey={state.activeTab}
    //   >
    //     <Tabs.TabPane key="mock" tab="Mock" style={{ padding: '10px 0' }}>
    //       {hasComponentId.value ? <MockEditor/> : null}
    //     </Tabs.TabPane>
    //     <Tabs.TabPane key="api" tab="Api" style={{ padding: '10px' }}>
    //       {/* {hasComponentId.value ? <DataSourceSlot/> : null} */}
    //       {hasComponentId.value ? <AutoUpdateSlot/> : null}
    //       {/* {hasAppendConfig.value ? <AutoAppendSlot/> : null} */}
    //       {(hasParamsConfig.value && isForm.value) ? <ParamsConfig/> : null}
    //     </Tabs.TabPane>
    //   </Tabs>
    // )

    return () => (
      <>
        {hasComponentId.value ? <BaseConfigSlot/> : null}
        {hasComponentId.value ? <DataSourceSlot/> : null}
        {(showApiConfig.value && !isAntdTable.value) ? <AutoUpdateSlot/> : null}
        {/* {hasAppendConfig.value ? <AutoAppendSlot/> : null} */}
        {showParamsConfig.value ? <ParamsConfig/> : null}
        {showApiConfig.value ? <MockEditor/> : null}
      </>
    )
  },
})

import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { defineComponent, reactive, } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Input, } from 'ant-design-vue'

export default defineComponent({
  name: 'formLabel',
  order: 4,
  setup(props, ctx) {
    const store = useStore()
    const { componentConfig, moveablerRef, mainJson, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'mainJson',
      'selecto'
    ]) as IState
    const state = reactive({
      
    })
  

    
    const handleChange = (type: string, value: any) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: [type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: type,
        path: ['option', type],
        value: componentConfig.value.optionData[type],
      })

      options[type] = value
      targetCharts.setOption(options, false)
    }

    // 边框插槽
    const labelSlot = () => (
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">标题内容</div>
          <div class="setting-row__content">
            <Input
              size="small"
              onChange={e => handleChange('label', e.target.value)}
              v-model:value={componentConfig.value.optionData.label}
              placeholder="请输入标题内容"
            />
          </div>
        </div>
      </div>
    )

    return () => <SettingItemBox title="标签文本" v-slots={labelSlot} />
  },
})


import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { Form, Input } from 'ant-design-vue'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import * as echarts from 'echarts'
import { isDisabled } from '@/utils'

import useMoveableTarget from '@/hooks/useMoveableTarget'

export default defineComponent({
  name: 'Grid',
  order: 3,
  setup(props, ctx) {
    const store = useStore()
    const {
      componentConfig,
      moveablerRef,
      selecto
    }: any = userMapState(
      'editor',
      [ 'componentConfig', 'moveablerRef', 'selecto']
    ) as IState
      
    // 位置尺寸
    const paddingsData = [
      {
        addonBefore: '上',
        ref: 'paddingTop',
        label: 'top',
      },
      {
        addonBefore: '下',
        ref: 'paddingBottom',
        label: 'bottom',
      },
      {
        addonBefore: '左',
        ref: 'paddingLeft',
        label: 'left',
      },
      {
        addonBefore: '右',
        ref: 'paddingRight',
        label: 'right',
      },
    ]

    // 设置边框
    const handleOptionsChange = (type, value) => {
      // const selected = moveablerRef.value?.$_moveable.target
      // const _this = echarts.getInstanceByDom(selected[0].firstChild)
      // const _opt = _this?.getOption()
      // _opt.grid[0][type] = value.target._value
      // _this.setOption(_opt, false)

      const { targetCharts, options } = useMoveableTarget(selecto)
      options.grid[0][type] = value
      targetCharts.setOption(options, false)

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: type,
        value: value
      })
      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path:['option','grid','0',type],
        // target: 'option',
        // key: 'grid',
        value: value
      })
    }

    // 边框插槽
    const gridSlot = () => (
      <Form
        layout="inline"
        style={{ paddingLeft: '6px' }}
        wrapperCol={{ style: { width: '110px' } }}
      >
        {paddingsData.map(item => (
          <Form.Item>
            {/* disabled={isDisabled(componentConfig.value.optionData.grid[0][item.label])} */}
            <Input
              addonBefore={item.addonBefore}
              size="small"
              ref={item.ref}
              v-model:value={componentConfig.value.optionData.grid[0][item.label]}
              onChange={e => {handleOptionsChange(item.label, e.target.value)}}
            />
          </Form.Item>
        ))}
      </Form>
    )

    return () => <SettingItemBox title="边框" v-slots={gridSlot} />
  },
})
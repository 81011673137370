import { computed, defineComponent, onMounted, reactive } from 'vue'
import SettingItemBox from '../settingItemBox'
import { VAceEditor } from 'vue3-ace-editor'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { debounce } from 'lodash'
export default defineComponent({
  name: 'CustomStyle',
  components: { VAceEditor },
  setup() {
    const store = useStore()
    const { mainJson }: any = userMapState('editor', ['mainJson'])

    const state = reactive({
      lang: 'less',
      lessStr: '',
      options: {
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        wrap: true,
        tabSize: 2,
        showPrintMargin: false,
        fontSize: 12,
        useWorker: false,
        highlightSelectedWord: true,
      },
    })

    // BI主题
    const isDark = computed(() => mainJson.value.isDark)
    // aceEditor主题
    const theme = computed(() => (isDark.value ? 'clouds_midnight' : 'chrome'))

    const editorStyle = () => ({
      height: '500px',
    })

    const handleChange = debounce(() => {
      console.log('state.lessStr--', state.lessStr)
      store.commit('editor/SET_PAGE_CUSTOM_CLASS', state.lessStr)
    }, 500)

    const editorSlot = () => (
      <VAceEditor
        style={editorStyle()}
        value={state.lessStr}
        v-model:value={state.lessStr}
        onChange={handleChange}
        lang="css"
        theme={theme.value}
        options={state.options}
      />
    )

    onMounted(() => {
      state.lessStr = mainJson.value.body.style.customStyle ?? ''
    })

    return () => <SettingItemBox title="自定义样式" v-slots={editorSlot} />
  },
})

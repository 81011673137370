import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import {
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue'
import { Upload, Button } from 'ant-design-vue'
import {
  defineComponent,
  reactive,
} from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { ColorPicker } from 'vue-color-kit'
import _ from 'lodash'
import API from '@/api'


export default defineComponent({
  name: 'Background',
  setup(props) {
    const state = reactive({
      loading: false,
      colorPickerShow: false,
    })
    const store = useStore()
    const { componentConfig, moveablerRef, selecto, mainJson }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto',
      'mainJson'
    ]) as IState

    // 设置Element的背景
    const setBackgroundDefault = (el, backgroundImage) => {
      el.style.backgroundImage = `url(${backgroundImage})`
      el.style.backgroundRepeat = componentConfig.value.backgroundRepeat
      el.style.backgroundPosition = componentConfig.value.backgroundPosition
      el.style.backgroundSize = componentConfig.value.backgroundSize
    }

    // 清除背景
    const cleanBackground = e => {
      e.stopPropagation()
      
      const target = moveablerRef.value.target[0]
      setBackgroundDefault(target, '')
      store.commit('editor/SET_BACKGROUND', {
        backgroundImage: '',
        backgroundColor: componentConfig.value.backgroundColor,
      })
      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: 'backgroundImage',
        value: '',
      })
    }

    // 上传钩子
    const beforeUpload = file => {
      console.log('file', file)
      return true
    }

     // 上传图片
     const handleCustomRequest = info => {
      const formData = new FormData()
      formData.append('file', info.file)
      
      API.pageManage.uploadBackgroundImage(formData, mainJson.value.pageID).then((res: any) => {
        if (!res.success) return
        const target = moveablerRef.value.target[0]
        setBackgroundDefault(target, res.data.filePath)
        store.commit('editor/SET_BACKGROUND', {
          backgroundImage: res.data.filePath,
          backgroundColor: componentConfig.value.backgroundColor,
        })
        store.dispatch('editor/setMainJsonAttribute', {
          id: componentConfig.value.id,
          path: 'backgroundImage',
          value: res.data.filePath,
        })
      })
    }

    // 修改背景颜色
    const handleChangeBackgroundColor = backgroundColor => {
      const target = moveablerRef.value.target[0]
      target.style.backgroundColor = backgroundColor
      store.commit('editor/SET_BACKGROUND', {
        backgroundColor,
        backgroundImage: componentConfig.value.backgroundImage
      })
      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: 'backgroundColor',
        value: backgroundColor,
      })
    }


    // 修改背景颜色
    const changeColor = _.debounce(handleChangeBackgroundColor, 1000)

    const uploadButton = (
      <div>
        {state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      </div>
    )

    // 背景插槽
    const backgroundSlot = () => (
      <div>
        <div class="setting-row color-picker-box">
          <div class="setting-row__label">背景颜色</div>
          <div class="setting-row__content">
            <input type="button" class="colorBtn"
              style={{ backgroundColor: componentConfig.value.backgroundColor }}
              onClick={
                (e) => {
                  state.colorPickerShow = true
                }
              }
              onBlur={
                (e: any) => {
                  // color-picker-box 内元素不触发
                  if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                    if (e.relatedTarget.tagName == 'INPUT') {
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener(
                        'blur',
                        (el: any) => {
                          setTimeout(() => state.colorPickerShow = false,100)
                        }
                      )
                    } else {
                      e.target.focus()
                    }
                    return
                  } else {
                    setTimeout(() => state.colorPickerShow = false,100)
                  }
                }
              }
            ></input>
            {
              state.colorPickerShow &&
              <ColorPicker
                theme="dark"
                suckerCanvas="null"
                suckerArea={[]}
                color={componentConfig.value.backgroundColor}
                onChangeColor={(value) => {
                  // console.log('value----------', value)
                  // value.rgba
                  const color = `rgba(${value.rgba.r},${value.rgba.g},${value.rgba.b},${value.rgba.a})`
                  changeColor(color)
                }}
                {...(props as any)}
              />
            }
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__label">背景图片</div>
          <div class="setting-row__content">
            <Upload
              name="background"
              listType="picture-card"
              class="background-upload"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={handleCustomRequest}
            >
              {componentConfig.value.backgroundImage ? (
                <>
                  <img
                    src={componentConfig.value.backgroundImage}
                    class="background-image"
                    alt="background"
                  />
                  <CloseOutlined onClick={cleanBackground} />
                </>
              ) : (
                uploadButton
              )}
            </Upload>
          </div>
        </div>

      </div>
    )

    return () => <SettingItemBox title="背景" v-slots={backgroundSlot} />
  },
})

export const option = {
  backgroundColor: 'transparent',
  legend: [{ show: true }],
  series: [
    {
      legendHoverLink: false,
      grid: [
        {
          x: '0',
          y: '0',
          y2: '0',
          x2: '0'
        }
      ],
      legend: {
        selectedMode: false
      },
      itemStyle: {},
      label: {
        show: true,
        formatter: '{b}: {@[1]}({d}%)'
      },
      type: 'pie',
      radius: ['0%', '70%']
    }
  ],
  label: {
    show: true
  },
  title: [{}],
  resize: [false,false]
}

import store from '@/store'

interface IParam {
  id?: string
  key: string | Array<string>
  value: any
}

export default function useSetOptionProperty({ id, key, value }: IParam): void {
  store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
    attribute: key,
    value,
  })

  const path = Array.isArray(key) ? ['option', ...key] : ['option', key]

  store.dispatch('editor/setMainJsonAttribute', {
    id,
    path,
    value,
  })
}

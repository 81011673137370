import { defineComponent } from 'vue'
import ScreenSize from './screenSize'
import Background from './background'
import CustomStyle from './customStyle'

export default defineComponent({
  name: 'pageTabPane',
  props: {
    // 是否是专业版
    isProfessional: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {


    
    // 多个插槽写法
    return () => (
      <>
        <ScreenSize />

        <Background />

        { props.isProfessional ? <CustomStyle /> : null }
        
      </>
    )
  },
})

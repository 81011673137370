import { IApiOptions } from '@/types'
import { getQueryVariable } from '@/utils/index'
import API from '@/api'
import { IResponseResult } from '@/utils/request'
import { message } from 'ant-design-vue'
import { reactive, watch } from 'vue'
import { get, groupBy } from 'lodash'
import { queueTask } from '@/utils/queueTask'
import Sys from '@/utils/systemInfo'
import regExp from '@/utils/regExp'

interface IParams {
  pageId: string
  dyncApiId: string,
  isPublish: number,
  objectFormat?: number;
  controlCode: string,
  nowReqJson: string,
  isAutomaticDataSource: boolean,
  controlCaption?: string
}

interface IRecordApi {
  id: string
  params: string
}

interface IResponseData {
  data?: Record<string, any>
  timestamp?: number | string
}

const validateDataNode = (value: string) => {
  if (value.length === 1) {
    return /^[a-zA-Z_]$/.test(value)
  }
  return regExp.dataNode.test(value)
}

const loadingApiList: IRecordApi[] = []//api 加载队列

const responseDataMap = reactive<Record<string, IResponseData>>({}) //返回参数 集合

class RequestApi {
  apiOptions: IApiOptions
  apiId: string
  currentComponent: string
  apiType: string
  queryParams: string | undefined
  isAutomaticDataSource: boolean
  reloadCallBack?: Function
  appendCallBack?: Function
  finishCallBack?: Function
  useMockCallBack?: Function

  constructor(apiOptions: IApiOptions) {
    this.apiOptions = apiOptions
    this.apiId = apiOptions.dataSourceId
    this.currentComponent = apiOptions.controlCode
    this.apiType = apiOptions.apiType
    this.isAutomaticDataSource = apiOptions.apiType === 'auto'
    this.reloadCallBack = apiOptions.reloadCallBack
    this.appendCallBack = apiOptions.appendCallBack
    this.finishCallBack = apiOptions.finishCallBack
    this.useMockCallBack = apiOptions.useMockCallBack
    try {
      this.queryParams = JSON.stringify(apiOptions.query_params)
    } catch (e) {
      this.queryParams = undefined
    }

    // this.request()
  }

  runWithApi(cb: Function) {
    if (!['api', 'mod'].includes(this.apiType)) return
    cb && cb()
  }

  //当前api 已经在加载队列中
  hasLoading(): boolean {
    // return loadingApiList.includes(this.apiId)
    return loadingApiList.findIndex(item => item.id === this.apiId) !== -1
  }

  //从 加载队列中 移除api
  removeApi() {
    this.runWithApi(() => {
      // const _index = loadingApiList.findIndex(item => item === this.apiId)
      const _index = loadingApiList.findIndex(item => item.id === this.apiId)
      loadingApiList.splice(_index, 1)
      console.log('RequestApi has remove', this.apiId)
    })
  }

  //在 加载队列中 记录 api
  recordApi() {
    this.runWithApi(() => {
      // loadingApiList.push(this.apiId)
      loadingApiList.push({
        id: this.apiId,
        params: this.queryParams
      })
      responseDataMap[this.apiId] = {}
      console.log('RequestApi has recorded', loadingApiList, new Date())
    })
  }

  //保存 api 返回的数据
  saveResponse(res: IResponseResult) {
    responseDataMap[this.apiId] = {
      data: res,
      timestamp: res.timestamp
    }
  }

  //校验老数据是否符合组件使用条件
  validateOldData(): boolean {
    if (this.queryParams !== loadingApiList.find(item => item.id === this.apiId)?.params) return false
    if (!Object.keys(responseDataMap)?.includes(this.apiId)) return false
    if (responseDataMap[this.apiId].data === null) return false //旧数据为null，重新请求api
    const _currentTime = new Date().getTime()//当前时间戳
    const reloadTime = this.apiOptions.reloadTime || 10 //默认间隔时间 10s
    //如果当前时间 减去 上次数据保存的时间 超过了该组件的 刷新间隔时间 则请求接口
    return _currentTime - Number(responseDataMap[this.apiId].timestamp) < reloadTime * 1000
  }

  //请求参数
  getParams(): IParams {
    const params = {
      request_body: {},
      request_header: {},
      rest_params: {},
      query_params: this.apiOptions.query_params || {},
    }
    return {
      pageId: getQueryVariable('pageid') || Sys.getPageId(),
      dyncApiId: this.apiId,
      isPublish: 0,
      objectFormat: this.apiOptions.objectFormat,
      controlCode: this.currentComponent,
      nowReqJson: JSON.stringify(params),
      isAutomaticDataSource: this.isAutomaticDataSource,
      controlCaption: this.apiOptions.anotherName
    }
  }

  // 1、仅限API 模式，auto模式下走正常request请求，且不缓存数据
  // 2、如果 当前api不在请求中 查看 相同api有无数据 或 数据符不符合条件 ，符合则直接使用保存的数据；不符合正常调用api
  // 3、如果当前api正在请求（其他组件调用），则等待改组件调用结束 调用其返回的参数 减少请求次数
  getData() {
    if (this.isAutomaticDataSource) {//auto模式
      return this.query()
    }
    // console.log('RequestApi Start ===============', new Date(), this.apiOptions)
    // console.log('RequestApi loadingApiList', loadingApiList, responseDataMap)
    if (this.hasLoading()) {//当前 有相同 API 正在请求
      // console.log('RequestApi hasLoading')
      return new Promise(resolve => {
        const stopWatch = watch(() => responseDataMap[this.apiId], () => {
          stopWatch()
          resolve(responseDataMap[this.apiId].data)
        }, { deep: true })
      })
    } else {//当前无有相同 API 请求
      if (!this.validateOldData()) {//旧的缓存数据 不符合 组件使用条件
        return this.query()
      } else {//符合
        return new Promise(resolve => {
          resolve(responseDataMap[this.apiId].data)
        })
      }
    }
  }

  query() {
    return new Promise(((resolve, reject) => {
      this.recordApi()
      API.pageManage.requestApi(this.getParams()).then((res: IResponseResult) => {
        if (res.code === 'STORED_PROCEDURE_IS_NO' && this.isAutomaticDataSource) {
          this.useMockCallBack(res)
        } else {
          this.removeApi()
          // if (res.data === null) message.warning('result data is null')
          if (res.data === null) reject(res)
          this.saveResponse(res)
          resolve(res)
        }
      }).catch((err) => {
        this.removeApi()
        reject(err)
      })
    }))
  }

  getDataWithNode(res: IResponseResult): IResponseResult {
    const { dataNode } = this.apiOptions
    if (!dataNode) return res
    const validate = validateDataNode(dataNode)
    const isObject = typeof res.data === 'object' && !Array.isArray(res.data)
    let _data
    if (isObject && validate) {
      // const path = dataNode.split('.')
      const path = dataNode
      // console.log(`RequestApi data node is '${path}'`)
      _data = get(res.data, path, null)
    } else {
      _data = null
      const name = this.apiOptions.anotherName || this.currentComponent
      message.warning(`组件 ${name}，不存在数据节点"${dataNode}"`)
    }
    return {
      ...res,
      data: _data
    }
  }

  getReloadData() {
    const p = this.getData()
    p && p.then((res: any) => {
      if (!res) return false
      const _res = this.getDataWithNode(res)
      this.reloadCallBack && this.reloadCallBack(_res)
      this.finishCallBack && this.finishCallBack(_res)
    })
  }

  getPagination() {
    const p = this.getData()
    p && p.then((res: any) => {
      if (!res) return false
      const _res = this.getDataWithNode(res)
      const { data } = _res as any
      const _data = data
      //删除 _data.source 第一行 数据 需要配合 sourceHeader 使用
      _res.data?.shift()
      let tempGroup = []
      let dataLength = _data?.length
      let pageSize: number = this.apiOptions.appendPagination
      let pageCount: number = Math.ceil(dataLength / pageSize)
      let pageData: any = []
      for (let i = 1; i <= pageCount; i++) {
        pageData = _data?.slice((i - 1) * pageSize, i * pageSize)
        tempGroup.push(pageData)
      }
      this.appendCallBack && this.appendCallBack(tempGroup)
      this.finishCallBack && this.finishCallBack(_res)
    })
  }

  getGroup() {
    const p = this.getData()
    p && p.then((res: any) => {
      if (!res) return false
      const _res = this.getDataWithNode(res)
      const { data } = _res as any
      const _data = data
      let tempGroup = []
      const groupKey = _data[0].indexOf(this.apiOptions.appendGroupKey)
      // let title = _res.data.data.source[0]
      // _res.data.data.source.shift()            //删除 _data.source 第一行 数据 需要配合 sourceHeader 使用
      let groupData = groupBy(_data, (item: any) => {
        return item[groupKey]
      })
      for (let key in groupData) {
        // let rowData = [title,...groupData[key]]
        let rowData = [...groupData[key]]
        rowData.forEach((item: any) => {        //删除维度列
          item.splice(groupKey, 1)
        })
        tempGroup.push(rowData)
      }
      this.appendCallBack && this.appendCallBack(tempGroup)
      this.finishCallBack && this.finishCallBack(_res)
    })
  }

  getDefault() {
    const p = this.getData()
    p && p.then((res: any) => {
      if (!res) return false
      const _res = this.getDataWithNode(res)
      const { data } = _res as any
      const _data = data
      _res.data?.shift()
      this.appendCallBack && this.appendCallBack(_data)
      this.finishCallBack && this.finishCallBack(_res)
    })
  }

  request() {
    const { reload, reloadTime, append, appendType } = this.apiOptions as IApiOptions
    reload ? queueTask(reloadTime * 1000, () => {
      this.getReloadData()
    }) : this.getReloadData()
    if (append) {
      if (appendType == 'pagination') {
        this.getPagination()
      } else if (appendType == 'group') {
        this.getGroup()
      } else { // null
        this.getDefault()
      }
    }
  }
}

export default RequestApi

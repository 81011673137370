import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import { defineComponent, reactive, } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Switch } from 'ant-design-vue'

export default defineComponent({
  name: 'checkboxSize',
  order: 4,
  setup(props, ctx) {
    const store = useStore()
    const { componentConfig, moveablerRef, mainJson, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'mainJson',
      'selecto'
    ]) as IState
    const state = reactive({

    })

    // 渲染组件
    const renderComponent = (key: string[], value: any) => {
      const _key = key.join()
      const { targetCharts, options } = useMoveableTarget(selecto)
      const _options = { ...options, ...componentConfig.value.optionData }
      // 复杂对象需要手动赋值替换（array or object）
      if (Array.isArray(_options[_key])) {
        _options[_key] = value
      }
      console.log('_options---', _options, componentConfig.value.optionData)
      targetCharts.setOption(_options, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const setProperty = (key: string[], value: any): void => {
      useSetOptionProperty({
        id: componentConfig.value.id,
        key,
        value,
      })
      renderComponent(key, value)
    }

    const handleChange = (key: string[], value: any): void => {
      setProperty(key, value)
    }

    // 边框插槽
    const resizeSlot = () => (
      <>
        <div class="common-setting-item-box">
          <div
            class="setting-row"
          >
            <div class="setting-row__label">继承剩余页面高度</div>
            <div class="setting-row__content">
              <Switch
                disabled={!mainJson.value.body.style.isMAXPageHeight}
                size="small"
                v-model:checked={componentConfig.value.optionData.resize[1]}
                onChange={(isFixedPageSizeH: boolean) =>
                  handleChange(['resize','1'], isFixedPageSizeH)
                }
              />
            </div>
          </div>

          <div
            class="setting-row"
          >
            <div class="setting-row__label">继承剩余页面宽度</div>
            <div class="setting-row__content">
              <Switch
                disabled={!mainJson.value.body.style.isMAXPageWidth}
                size="small"
                v-model:checked={componentConfig.value.optionData.resize[0]}
                onChange={(isFixedPageSizeW: boolean) =>
                  handleChange(['resize','0'], isFixedPageSizeW)
                }
              />
            </div>
          </div>
        </div>
      </>
    )

    return () => <SettingItemBox title="标签文本" v-slots={resizeSlot} />
  },
})

/**
 * id: 必须 唯一标识
 * _type: 类型
 * title: 标题
 * dataIndex: 映射的字段
 * width: 宽 number
 * fixed: 是否固定
 * isSummary: 是否统计
 * summary: 统计单位
 * isShow: 是否显示
 * resizable: 是否可拖拽
 * ellipsis: 让单元格内容根据宽度自动省略
 * __href: 链接字段
 * isProcessDefault: 是否使用默认 默认是纯色、否则是跟随值大小有区间颜色 默认为false
 * isTagIndex: 是否是索引 用tag作为索引、会有作为索引特定的颜色配置 默认为false
 * allowWrapperLine: 是否允许换行
 */
const mockColumns = [
  {
    id: '1',
    title: '姓名',
    dataIndex: 'name',
    level: 1,
    width: 120,
    fixed: false,
    isSummary: false,
    summary: '',
    isShow: true,
    ellipsis: true,
    resizable: true,
    allowWrapperLine: false
  },
  {
    id: '2',
    title: '年龄',
    dataIndex: 'age',
    level: 1,
    width: 120,
    fixed: false,
    summary: '',
    isShow: true,
    ellipsis: true,
    resizable: true,
    allowWrapperLine: false
  },
  {
    id: '3',
    title: '性别',
    dataIndex: 'sex',
    level: 1,
    width: 120,
    fixed: false,
    isSummary: false,
    summary: '',
    isShow: true,
    ellipsis: true,
    resizable: true,
    allowWrapperLine: false
  },
  {
    id: '4',
    title: '班级',
    dataIndex: 'class',
    level: 1,
    width: 120,
    fixed: false,
    isSummary: false,
    summary: '',
    isShow: true,
    ellipsis: true,
    resizable: true,
    allowWrapperLine: false
  },
  {
    id: '5',
    title: '学校',
    dataIndex: 'school',
    level: 1,
    width: 120,
    fixed: false,
    isSummary: false,
    isShow: true,
    ellipsis: true,
    resizable: true,
    allowWrapperLine: false
  },
  {
    id: '6',
    title: '人数',
    dataIndex: 'personNumber',
    level: 1,
    width: 120,
    fixed: false,
    isSummary: true,
    summary: '个',
    isShow: true,
    ellipsis: true,
    resizable: true,
    allowWrapperLine: false
  }
]

export const option = {
  propertiesConfig: true,
  chooseType: 0,   // 选择类型 0 -> 无 1 -> checkbox 2 -> radio
  rowSelection: null, // 多选配置
  hasIndex: false,    // 是否显示索引
  isScrollLoadModel: false,
  scrollToSpeed: 10000,
  scrollToRePlay: true,
  isPolling: false,   // 是否轮循
  pollingTime: 5,  // 轮循时间
  indexSource: [], // 索引图片数组
  total: 10,
  current: 1,
  // pageSize: null,
  series: [
    {
      type: 'table'
    }
  ],
  dataset: {
    source: {
      columns: [],
      columnTotal: {},
      dataset: {
        data: []
      }
    }
  },
  'show-summary': true,
  showAllSummary: false,
  isFixedSummary: true, // 是否固定汇总行
  isFixedPageSizeW: false,
  isFixedPageSizeH: false,
  'sum-text': '汇总',
  'show-header': true,
  size: 'small',
  fit: true,
  border: true,
  stripe: false,
  'highlight-current-row': true,
  'cell-style': {},
  'row-style': {},
  columns: mockColumns,
  rowChange: false,
  columnClick: false
}

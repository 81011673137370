import { r, d, h, a } from "@/units"
import { ref, reactive } from 'vue'
export default d({
  name: 'pageEditorLayoutContent',
  setup: (props, context) => {
    const VNode = {
      render() {
        return h(
          r("a-layout-content"),
          () => context.slots.default && context.slots.default()
        )
      }
    }
    return () => h(VNode)
  }
})
import { computed, defineComponent, onMounted, onUnmounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import {
  Cascader,
  message,
  Select,
  RadioGroup,
  InputNumber,
  Input,
} from 'ant-design-vue'
import Sys from '@/utils/systemInfo'
import useSetAttribute from '@/hooks/useSetAttribute'
import SettingItemBox from '../../settingItemBox'
import SettingItemRow from '../../settingItemRow'
import API from '@/api'
import { useRoute } from 'vue-router'
import regExp from '@/utils/regExp'
import { IObject } from '@/types'
import { getComponentsFormat } from '@/utils'
import { debounce } from 'lodash'
import RequestApi from '@/utils/RequestApi'
import type { CascaderProps, RadioGroupProps } from 'ant-design-vue'

interface IDataNode {
  apiId: string
  select: string[]
  options: CascaderProps['options']
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

interface IStateReactive {
  showDataSource: boolean
  showDSList: boolean
  fetching: boolean
  isError: boolean
  inheritTypeMap: RadioGroupProps['options']
  errTimer: any | null
  dataNode: IDataNode
}

const { Option } = Select
export default defineComponent({
  name: 'Type',
  order: 2,
  setup() {
    const store = useStore()
    const route = useRoute()
    const { componentConfig, mainJson }: any = userMapState('editor', [
      'componentConfig',
      'mainJson',
    ]) as IState

    const state = reactive<IStateReactive>({
      showDataSource: false,
      showDSList: false,
      fetching: false,
      isError: false,
      inheritTypeMap: [
        { label: '当前数据', value: '0' },
        { label: '全部数据', value: '1' },
      ],
      errTimer: null,
      dataNode: {
        apiId: '',
        select: [],
        options: []
      }
    })

    const setAttribute = useSetAttribute(store)

    //是否有选中的数据
    const currentId = computed<string>(() => componentConfig.value.id)

    const dataSourceId = computed<string>(() => componentConfig.value.api.dataSourceId)

    const hasUpStream = computed<boolean>(() => !!componentConfig.value.api.paramsConfig.upStream?.length)

    const isOutput = computed<boolean>(() => componentConfig.value.optionData.processType === 'output')

    const isApi = computed<boolean>(() => componentConfig.value.api.type === 'api')

    const isInheritApi = computed<boolean>(() => componentConfig.value.api.inheritType === '1')

    const notFoundContent = computed<undefined | null>(() => (state.fetching ? undefined : null))

    onMounted(() => {
      initInheritType()
      setDataNodeOptions()
    })

    onUnmounted(() => {
      state.errTimer = null
    })

    watch(
      () => currentId.value,
      () => {
        initInheritType()
        setDataNodeOptions()
      }
    )

    watch(
      () => componentConfig.value.api.dataNode,
      (value) => {
        if (state.dataNode.select.join('.') !== value) {
          state.dataNode.select = value?.split('.') || []
        }
      }, { immediate: true }
    )

    const setDataNodeOptions = () => {
      if (!isApi.value || !dataSourceId.value) return
      const controlCode = componentConfig.value.id
      const requestApi = new RequestApi({
        dataSourceId: dataSourceId.value,
        controlCode,
        anotherName: componentConfig.value.anotherName,
        apiType: componentConfig.value.api.type,
        objectFormat: getComponentsFormat(componentConfig.value.type,componentConfig.value.api.type),
        reloadCallBack: (res: any) => {
          console.log('reloadCallBack', res)
          const { data } = res
          state.dataNode.options = getDataNodeOpt(data)
        }
      })
      requestApi.request()
    }

    const getDataNodeOpt = (obj: Record<string, any>, path?: string[]) => {
      const keys = Object.keys(obj)
      if (!keys?.length) return []
      const res = []
      keys.forEach(key => {
        const item: any = obj[key]
        const _path: string[] = !!path?.length ? [...path, key] : [key]
        const _pathStr = _path.join('.')
        const option: Option = {
          // value: _pathStr,
          // label: _pathStr,
          value: key,
          label: key,
        }
        if (item?.constructor === Object) {
          option.children = getDataNodeOpt(item, _path)
        }
        res.push(option)
      })
      return res
    }

    const initInheritType = () => {
      if (isOutput.value) {
        if (!Object.keys(componentConfig.value.api).includes('inheritType')) {
          handleChangeInherit('inheritType', '0')
        }
      }
    }

    const saveDataSource = (apiId: string) => {
      const params = {
        apiId,
        controlCode: currentId.value,
      }
      return new Promise(resolve => {
        API.pageManage.saveOrUpdate(params).then((res: any) => {
          if (!res?.success) return message.error(res?.message || '保存失败')
          message.success(res?.message || '保存成功')
          resolve(true)
        })
      })
    }

    //选择API 数据源
    const handleChangeApi = (attribute: string, value: any) => {
      saveDataSource(value).then(() => {
        handleChangeAttribute(attribute, value)
        setDataNodeOptions()
      })
    }

    //选择属性
    const handleChangeAttribute = (attribute: string, value: any) => {
      // const setAttribute = useSetAttribute(store)
      setAttribute(currentId.value, attribute, value)
    }

    //选择继承方式
    const handleChangeInherit = (attribute: string, value: any) => {
      // const setAttribute = useSetAttribute(store)
      setAttribute(currentId.value, attribute, value)
    }

    const validateDataNode = (value: string) => {
      if (value.length === 1) {
        return /^[a-zA-Z_]$/.test(value)
      }
      return regExp.dataNode.test(value)
    }

    //改变数据节点
    const handleChangeDataNode = debounce((attribute: string, value: any) => {
      const validateRes = validateDataNode(value)
      if (state.errTimer) {
        clearTimeout(state.errTimer)
        state.errTimer = null
      }
      state.errTimer = setTimeout(() => {
        state.isError = false
      }, 10 * 1000)
      state.isError = !validateRes
      setAttribute(currentId.value, attribute, value)
    }, 300)


    const handleSelectDataNode = (attribute: string, value: string[]) => {
      const node = value.join('.')
      setAttribute(currentId.value, attribute, node)
    }

    const handleViewData = () => {
      state.showDataSource = true
    }

    const handleClose = () => {
      state.showDataSource = false
    }

    const NotFoundContent = () => state.fetching && <a-spin size="small"/>

    const SelectApiSlot = () => (
      <SettingItemRow title={'选择数据'}>
        <div style={{ display: 'flex' }}>
          <Select
            size="small"
            v-model:value={componentConfig.value.api.dataSourceId}
            v-model:notFoundContent={notFoundContent.value}
            disabled={!isApi.value}
            onChange={value => handleChangeApi('dataSourceId', value)}
            v-slots={{ notFoundContent: () => <NotFoundContent/> }}
          >
            {mainJson.value?.apiList?.map(item => (
              <Option value={item.id}>{item.apiName}</Option>
            ))}
          </Select>
        </div>
      </SettingItemRow>
    )

    const SetDataNode = () => (
      <SettingItemRow title={'数据节点'}>
        <Input
          size="small"
          disabled={!isApi.value}
          v-model:value={componentConfig.value.api.dataNode}
          class={state.isError ? 'error-form-item' : ''}
          onChange={e =>
            handleChangeDataNode('dataNode', e.target.value)
          }
        />
        {
          state.isError ? <div class="error-alert">数据节点仅支持英文、数字、_ 和 .</div> : ''
        }
      </SettingItemRow>
    )

    const SelectDataNode = () => (
      <SettingItemRow title={'数据节点'}>
        <Cascader
          size="small"
          changeOnSelect
          v-model:value={state.dataNode.select}
          v-model:notFoundContent={notFoundContent.value}
          v-model:options={state.dataNode.options}
          disabled={!isApi.value}
          onChange={(value: string[]) => {
            handleSelectDataNode('dataNode', value)
          }}
          v-slots={{ notFoundContent: () => <NotFoundContent/> }}
        />
      </SettingItemRow>
    )

    const InheritTypeSlot = () => (
      <>
        <SettingItemRow title={'继承方式'}>
          <RadioGroup
            size="small"
            disabled={!hasUpStream.value}
            v-model:value={componentConfig.value.api.inheritType}
            optionType={'button'}
            options={state.inheritTypeMap}
            onChange={value =>
              handleChangeInherit('inheritType', value.target.value)
            }
          />
        </SettingItemRow>
        {isInheritApi.value ? (
          <>
            <SettingItemRow title={'数据上限'}>
              <InputNumber
                size="small"
                min={1}
                max={99999}
                defaultValue={9999}
                step={1}
                precision={0}
                disabled={!hasUpStream.value}
                v-model:value={componentConfig.value.api.iPageSize}
                onChange={value => handleChangeInherit('iPageSize', value)}
              />
            </SettingItemRow>
            <SettingItemRow title={'解析属性'}>
              <Input
                size="small"
                disabled={!hasUpStream.value}
                v-model:value={componentConfig.value.api.analyzeProperties}
                onChange={e =>
                  handleChangeInherit('analyzeProperties', e.target.value)
                }
              />
            </SettingItemRow>
          </>
        ) : null}
      </>
    )

    const DataSourceSlot = () => (
      <>{isOutput.value ? <InheritTypeSlot/> :
        <>
          <SelectApiSlot/>
          {/* <SetDataNode/> */}
          <SelectDataNode/>
        </>
      }</>
    )
    return () => <SettingItemBox title="API数据源" v-slots={DataSourceSlot}/>
  },
})

import useMoveableTarget from '@/hooks/useMoveableTarget'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { Input, InputNumber, Select, Switch } from 'ant-design-vue'
import { defineComponent, nextTick, onBeforeMount, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import { fontFamilyList } from '@/utils/constance'
import { ColorPicker } from 'vue-color-kit'
import _ from 'lodash'

export default defineComponent({
  name: 'Title',
  order: 0,
  setup(props) {
    const store = useStore()
    const { componentConfig, moveablerRef, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto'
    ]) as IState

    const state = reactive({
      colorPickerShow: false
    })

    const titleDefaultOptions = [
      {
        textStyle: {
          fontSize: 14
        },
      },
    ]
    
    const handleChange = (type: string, value: any) => {
      const propKeyMap = {
        show: ['title', '0', 'show'],
        text: ['title', '0', 'text'],
        top: ['title', '0', 'top'],
        left: ['title', '0', 'left'],
        fontSize: ['title', '0', 'textStyle', 'fontSize'],
        fontFamily: ['title', '0', 'textStyle', 'fontFamily'],
        color: ['title', '0', 'textStyle', 'color'],
      }

      const propValueMap = {
        show: componentConfig.value.optionData.title.show,
        text: componentConfig.value.optionData.title.text,
        fontSize: componentConfig.value.optionData.title.textStyle?.fontSize,
        fontFamily:
          componentConfig.value.optionData.title.textStyle?.fontFamily,
          color: componentConfig.value.optionData.title.textStyle?.color,
      }
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })
      
      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })

      const { targetCharts, options } = useMoveableTarget(selecto)
      options.title = componentConfig.value.optionData.title
      targetCharts.setOption(options, false)
    }

    const changeColor = _.debounce(handleChange, 1000)

    const titleSlot = () => (
      <div class="title-box common-setting-item-box">
        { console.log('-----titleSlot', componentConfig.value.optionData.title) }
        {/* <div class="setting-row">
          <div class="setting-row__label">是否显示</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.title[0].show}
              onChange={value => handleChange('show', value)}
            />
          </div>
        </div> */}
        <div class="setting-row">
          <div class="setting-row__label">标题内容</div>
          <div class="setting-row__content">
            <Input
              size="small"
              onChange={e => handleChange('text', e.target.value)}
              v-model:value={componentConfig.value.optionData.title[0].text}
              placeholder="请输入标题内容"
            />
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__label">上</div>
          <div class="setting-row__content">
            <Input
              size="small"
              onChange={e => handleChange('top', e.target.value)}
              v-model:value={componentConfig.value.optionData.title[0].top}
              placeholder="请输入距离顶部距离"
            />
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__label">左</div>
          <div class="setting-row__content">
            <Input
              size="small"
              onChange={e => handleChange('left', e.target.value)}
              v-model:value={componentConfig.value.optionData.title[0].left}
              placeholder="请输入距离顶部距离"
            />
          </div>
        </div>
        <div class="setting-row color-picker-box">
          <div class="setting-row__label">字体颜色</div>
          <div class="setting-row__content">
            <input type="button" class="colorBtn"
              style={{ backgroundColor: componentConfig.value.optionData.title[0].textStyle.color }}
              onClick={
                (e) => {
                  state.colorPickerShow = true
                }
              }
              onBlur={
                (e:any) => {
                  // color-picker-box 内元素不触发
                  if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                    if(e.relatedTarget.tagName == 'INPUT'){
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener(
                        'blur',
                        (el:any) => {
                          setTimeout(() => state.colorPickerShow = false,100)
                        }
                      )
                    }else{
                      e.target.focus()
                    }
                    return
                  }
                  setTimeout(() => state.colorPickerShow = false,100)
                }
              }
            ></input>
            {
              state.colorPickerShow && 
              <ColorPicker
                theme="dark"
                suckerCanvas="null"
                suckerArea={[]}
                color={componentConfig.value.optionData.title[0].textStyle.color}
                onChangeColor={(value) => {
                  changeColor('color', value.hex)
                }}
                {...(props as any)}
              />
            }
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__label">字体</div>
          <div class="setting-row__content">
            <Select
              v-model:value={
                componentConfig.value.optionData.title[0].textStyle.fontFamily
              }
              size="small"
              onChange={value => handleChange('fontFamily', value)}
            >
              {fontFamilyList.map(item => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              min={11}
              precision={0}
              v-model:value={
                componentConfig.value.optionData.title[0].textStyle.fontSize
              }
              onChange={value => handleChange('fontSize', value)}
            />
          </div>
        </div>
      </div>
    )

    return () => <SettingItemBox title="标题" v-slots={titleSlot} />
  },
})

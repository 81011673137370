import Driver from 'driver.js';
import { nextTick } from 'process';
const driverListLight = [
  {
    element: '#first-light', // 这是需要高亮的元素
    popover: { // 引导展示数据
      title: '图例组件说明', // 这是标题
      description: '在界面左侧，我们为您提供了多种图例样式，你可以将需要的图形鼠标左键拖入到中间画布中。', // 这里是内容，支持HTML字符串
      position: 'right', // 引导展示位置
    }
  },
  {
    element: '#second-light',
    popover: {
      title: '页面属性说明', // 这是标题
      // description: '配置组件样式，我们提供普通配置和代码配置两种配置方式', // 这里是内容，支持HTML字符串
      description: '当切换到页面属性标签后，你可在此修改画布的大小和画布背景颜色，为了使您设计的页面和实际投放的页面更加一致，建议将画布大小设置为实际投放屏幕的大小哦~', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#third-light',
    popover: {
      title: '样式属性说明', // 这是标题
      description: '将图形拖到画布后，即可在右侧看到该图形对应的样式属性，在样式标签属性中，你可以调整数据图的颜色、大小、字体等各种属性', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#fouth-light',
    popover: {
      title: '数据属性说明', // 这是标题
      description: '当切换到数据属性标签后，你可为该图形配置对应的展示数据。先择您在上方“数据管理”按钮中预先配置好的数据作为数据源，在保存后图形的具体数值即为选择数据源中传入的数值。', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#fifth-light',
    popover: {
      title: 'Mock配置说明', // 这是标题
      description: '你也可以在此处修改图形自带的Mock数据，修改后即可在画布中即时查看图形的样式变化，以便于您更好的调整数据图的各项参数', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#sixth-light',
    popover: {
      title: '保存内容说明', // 这是标题
      description: '当您将页面配置好后，可点击“预览按钮”查看BI页面的展示效果，保存并提交该页面即可进行实际使用啦~', // 这里是内容，支持HTML字符串
      position: 'bottom' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
]

const driverListDark = [
  {
    element: '#first-dark', // 这是需要高亮的元素
    popover: { // 引导展示数据
      title: '图例组件说明', // 这是标题
      description: '在界面左侧，我们为您提供了多种图例样式，你可以将需要的图形鼠标左键拖入到中间画布中。', // 这里是内容，支持HTML字符串
      position: 'right', // 引导展示位置
    }
  },
  {
    element: '#second-dark',
    popover: {
      title: '页面属性说明', // 这是标题
      // description: '配置组件样式，我们提供普通配置和代码配置两种配置方式', // 这里是内容，支持HTML字符串
      description: '当切换到页面属性标签后，你可在此修改画布的大小和画布背景颜色，为了使您设计的页面和实际投放的页面更加一致，建议将画布大小设置为实际投放屏幕的大小哦~', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#third-dark',
    popover: {
      title: '样式属性说明', // 这是标题
      description: '将图形拖到画布后，即可在右侧看到该图形对应的样式属性，在样式标签属性中，你可以调整数据图的颜色、大小、字体等各种属性', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#fouth-dark',
    popover: {
      title: '数据属性说明', // 这是标题
      description: '当切换到数据属性标签后，你可为该图形配置对应的展示数据。先择您在上方“数据管理”按钮中预先配置好的数据作为数据源，在保存后图形的具体数值即为选择数据源中传入的数值。', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#fifth-dark',
    popover: {
      title: 'Mock配置说明', // 这是标题
      description: '你也可以在此处修改图形自带的Mock数据，修改后即可在画布中即时查看图形的样式变化，以便于您更好的调整数据图的各项参数', // 这里是内容，支持HTML字符串
      position: 'left' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
  {
    element: '#sixth-dark',
    popover: {
      title: '保存内容说明', // 这是标题
      description: '当您将页面配置好后，可点击“预览按钮”查看BI页面的展示效果，保存并提交该页面即可进行实际使用啦~', // 这里是内容，支持HTML字符串
      position: 'bottom' // 引导展示位置，支持 top, left, bottom, right, center
    }
  },
]

const driver = new Driver({
  padding: 0,
  className: 'scoped-class',
  stageBackground: '#535353',
  closeBtnText: '退出', // 关闭文本
  overlayClickNext: false, // 蒙层点击是否执行下一步
  prevBtnText: '上一步', // 上一步文本
  nextBtnText: '下一步', // 下一步按钮标题
  doneBtnText: '结束',
  onDeselected: (Element) => {
    localStorage.setItem('isDriver', "1")
  },
  onHighlighted: (Element:any) =>{
    const getPopover = Element.popover
    nextTick(() => {
      if(["first-light", "first-dark"].includes(Element.node.id) ){
        getPopover.node.style.top = "100px"
      }else if(["fifth-light", "fifth-dark"].includes(Element.node.id)){
        setTimeout(()=>{
          getPopover.node.style.top = "450px"
        },500)
      }
    })
  },
  onReset: (Element) => {
    const guides = document.getElementsByClassName('guide')[0]
    guides.remove()
  }
})

const driverStart = () => {
  const isDriver = localStorage.getItem('isDriver')
  const mainJson = JSON.parse(localStorage.getItem('mainjson'))
  const driverList = mainJson.isDark ? driverListDark : driverListLight
  if (!isDriver) {
    nextTick(()=>{
      driver.defineSteps(driverList)
      driver.start()
    })
  }
}

export const driversOpen = ()=>{
  const next = document.getElementById('next')
  const drivers:any = document.getElementsByClassName('driver')[0]
  const isDriver = localStorage.getItem('isDriver')
  const closed = document.getElementById('close')
  const light:any = document.getElementsByClassName('light')[0]
  const dark:any = document.getElementsByClassName('dark')[0]
  if (!isDriver) {
    drivers.style.display = 'block'
    next.onclick = () => {
      drivers.style.display = 'none'
      light.style.display = 'block'
      dark.style.display = 'block'
      driverStart()
    }
    closed.onclick = () => {
      drivers.style.display = 'none'
      localStorage.setItem('isDriver', "1")
    }
  }else{
    drivers.className = "d-none"
  }
}
import type { IState } from "@/store/modules/editor"
import { userMapState } from "@/store/userMapper"
import { fontFamilyList } from "@/utils/constance"
import { Radio } from "ant-design-vue"
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watchEffect } from "vue"
import { useStore } from "vuex"
import SettingItemBox from '../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import type { PropType } from 'vue'
import { IBaseText } from '@/types'
import _ from 'lodash'
export default defineComponent({
  name: 'Layout',
  order: 0,
  props: {
    option: {
      type: Object as PropType<IBaseText>,
    },
    staticData: Array
  },
  setup(props, ctx) {
    const instance: any = getCurrentInstance()
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
    })
    const state = reactive({
    })
    const store = useStore()
    const { mainJson, moveablerRef, selecto }: any = userMapState('editor', [
      'mainJson',
      'moveablerRef',
      'selecto'
    ]) as IState

    const componentConfig = computed(() => {
      return mainJson.value.body.children.find(config => config.id === store.state.editor.componentConfig.id)
    })


    const handleChangeStyle = (type, value) => {

      const propKeyMap = {
        layout: ['layout']
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })
      const { targetCharts, options } = useMoveableTarget(selecto)
      options[type] = value
      
      targetCharts.setOption(options)
    }

    const textSlot = () => (
      <>
        <div class="title-box common-setting-item-box">
          <div class="setting-row">
            <div class="setting-row__label">布局</div>
            <div class="setting-row__content">
              <a-radio-group
                default-value="center"
                size="small"
                onChange={(value) => {
                  handleChangeStyle('layout', value.target.value)
                }}
                v-model:value={componentConfig.value.option.layout}
              >
                <a-radio-button value="horizontal">
                  Horizontal
                </a-radio-button>
                <a-radio-button value="vertical">
                  Vertical
                </a-radio-button>
              </a-radio-group>
            </div>
          </div>
        </div>
      </>
    )

    return () => componentConfig.value ? <SettingItemBox title="文本设置" v-slots={textSlot} /> : null
  }
})

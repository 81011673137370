import { defineComponent, ref, watch } from 'vue'
import StyleTabPane from './styleTabPane'
import DataTabPane from './dataTabPane'
import PageTabPane from './pageTabPane'
import ProfessionalTabPane from './professionalTabPane'
import { Tabs } from 'ant-design-vue'
import './index.less'

export default defineComponent({
  name: 'SettingPane',
  props: {
    isProfessional: Boolean
  },
  setup(props, ctx) {
    const activeTab = ref('style')
    watch(() => props.isProfessional, (newVal) => {
      if (newVal === true) {
        activeTab.value = 'professional'
      } else {
        activeTab.value = 'style'
      }
        
    })
    const handleChange = activeKey => {
      console.log('activeKey', activeKey, activeKey)
    }

    return () => (
      <>
        <div style={{ width: '300px' }} class="setting-sider">
          <Tabs
            size="small"
            type="card"
            v-model:activeKey={activeTab.value}
            onChange={handleChange}
          >
            {/* <Tabs.TabPane key="style" tab="样式" forceRender={false}  style={{display:props.isProfessional?'none':'block'}}>
            <StyleTabPane></StyleTabPane>
          </Tabs.TabPane> */}
            {
              props.isProfessional ? <Tabs.TabPane key="professional" tab="专业">
                <ProfessionalTabPane></ProfessionalTabPane>
              </Tabs.TabPane>
              :
              null
            }
            <Tabs.TabPane key="style" tab="样式">
                <StyleTabPane></StyleTabPane>
              </Tabs.TabPane>
            <Tabs.TabPane key="data" tab="数据">
              <DataTabPane></DataTabPane>
            </Tabs.TabPane>
            <Tabs.TabPane key="page" tab="页面">
              <PageTabPane isProfessional={props.isProfessional}></PageTabPane>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </>
    )
  },
})


import { defineComponent, ref, reactive, toRefs, computed, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { cloneDeep, debounce } from 'lodash'
import { getQueryVariable } from '@/utils'
import API from '@/api'
import { message } from 'ant-design-vue'
import { DYNC_BI_CODE } from '@/utils/const'
import { getPageItemInfo } from '@/api/dataViews'

interface IFormData {
  pageName: string
  pageEname: string
  pageType: string
  pageId: string
  tagName: string
  tagId: string
  isPageDataSource: boolean
  dataResourceId: string
}

interface ITag {
  label: string
  value: string
}

interface IPageParams {
  // groupId: string
  // groupName: string
  // pageType: string
  pageName: string
  pageEname: string
  dataResourceId: string
  // remark: string
}

interface IState {
  showModal: boolean
  formData: IFormData
  tagsList: ITag[]
  tagsListCopy: ITag[]
  dataSourceList: Record<string, any>[]
  labelCol: Record<string, any>
}

const regExName = /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/
const regExEname =/^(?=[A-Za-z])[A-Za-z_\d]+$/ // /^[a-zA-Z][a-zA-Z0-9_]+$/

const defaultTagList: ITag[] = [
  { label: 'InformationDisplay', value: 'Web Mock1' },
  { label: 'MonitoringScreen', value: 'Web Mock2' },
  { label: 'DataAnalysis', value: 'Web Mock3' }
]

const regExEnameFunc = (value: string) => {
  // if (value.length === 1) {
  //   return /^[A-Za-z_\-\d/]+$/.test(value)
  // }
  return regExEname.test(value)
}

export default defineComponent({
  name: 'CreatePageModal',
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  emits: ['created', 'saved', 'close'],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const formRef = ref<null | any>()
    const state = reactive<IState>({
      showModal: false,
      formData: {
        pageName: '',
        pageEname: '',
        pageType: '',
        pageId: '',
        tagName: '',
        tagId: '',
        isPageDataSource: false,
        dataResourceId: undefined
      },
      tagsList: [],
      tagsListCopy: [],
      dataSourceList: [],
      labelCol: {
        style: {
          width: '100px'
        }
      }
    })
    const hasPageId = computed(() => !!state.formData.pageId)
    const isPageDataSource = computed(() => state.formData.isPageDataSource)

    const getDataSourceList = () => {
      API.pageManage.getDataResource().then((res: any) => {
        state.dataSourceList =
          res.data?.map(item => ({
            label: item.resourceName,
            value: item.id
          })) || []
      })
    }

    const getTagList = () => {
      API.pageManage
        .getTagList()
        .then((res: any) => {
          state.tagsList = res.data?.map(item => ({
            value: item.id,
            label: item.groupName
          }))
        })
        .finally(() => {
          if (!state.tagsList.length) {
            state.tagsList = cloneDeep(defaultTagList)
          }
          state.tagsListCopy = cloneDeep(state.tagsList)
        })
    }

    //获取数据源信息
    const getPageInfo = () => {
      const { pageId } = state.formData
      const token: string = getQueryVariable('token')

      if (!pageId) return
      getPageItemInfo(pageId).then((res: any) => {
        state.formData.pageId = res.data.id
        state.formData.pageName = res.data.pageName
        state.formData.pageEname = res.data.pageEname
        state.formData.pageType = res.data.pageType
        // state.formData.tagName = res.data.groupName
        // state.formData.tagId = res.data.groupId
        state.formData.isPageDataSource =
          !!res.data.dataResourceId && res.data.dataResourceId !== '0'
        state.formData.dataResourceId = res.data.dataResourceId !== '0' ? res.data.dataResourceId : undefined
       // addTagOptions(state.formData.tagName)
      })
    }

    const addTagOptions = (tagName: string) => {
      if (!state.tagsList.find(item => item.label === tagName)) {
        const copy = cloneDeep(state.tagsListCopy)
        state.tagsList = [{ value: '', label: tagName }].concat(copy)
      }
    }

    const onSearchTag = debounce((tagName: string) => {
      if (!regExEnameFunc(tagName)) {
        state.formData.tagName = ''
        return message.warning('页面标签仅支持英文字母')
      }
      addTagOptions(tagName)
    }, 300)

    const onSelectTag = (id, { label }) => {
      const tag = state.tagsListCopy.find(item => item.value === id)
      state.formData.tagId = id || ''
      state.formData.tagName = label
      if (!tag) {
        //新增tag,同步到 tag备份列表中
        state.tagsListCopy.unshift({ value: '', label })
      }
      state.tagsList = cloneDeep(state.tagsListCopy)
    }

    const handleSave = () => {
      formRef.value
        .validateFields()
        .then(() => {
          const { formData } = state
          if (!state.formData.isPageDataSource) formData.dataResourceId = '0'
          const params: IPageParams = {
            // groupId: formData.tagId?.includes('Web Mock') ? '' : formData.tagId,
            // groupName: formData.tagName,
            // pageType: formData.pageType,
            pageName: formData.pageName,
            pageEname: formData.pageEname,
            dataResourceId: formData.dataResourceId || '0',
            // remark: ''
          }
          hasPageId.value ? savePageConfig(params) : createPageInfo(params)
        })
        .catch(err => {
          console.log('err', err)
        })
    }

    //创建页面
    const createPageInfo = (params: IPageParams) => {
      API.pageManage.createPageInfo(params).then((res: any) => {
        state.formData.pageId = res.data || ''
        emit('created', state.formData)
        emit('close')
        sessionStorage.setItem('pageId', res.data)
        savedWindow()
      })
    }
    //编辑保存页面
    const savePageConfig = (params: IPageParams) => {
      API.pageManage.savePageInfo(state.formData.pageId, params).then((res: any) => {
        emit('saved', state.formData)
        emit('close')
        savedWindow()
      })
    }

    const handleCancel = () => {
      if (hasPageId.value) {
        emit('close')
      } else {
        closeWindow()
      }
    }

    const afterClose = () => {
      emit('close')
    }

    //关闭当前窗口
    const closeWindow = () => {
      if (window.opener && !window.opener.closed) {
        window.opener.postMessage('closed', window.location)
      } else {
        window.close()
      }
    }
    //保存后 通知父页面
    const savedWindow = () => {
      if (window.opener && !window.opener.closed) {
        window.opener.postMessage('saved', window.location)
      }
    }

    const validateName = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请输入页面名称')
      } else {
        if (!regExName.test(value)) {
          return Promise.reject('请输入正确的页面名称（由中文汉字、英文字母和数字组成）')
        }
        return Promise.resolve()
      }
    }
    const validateEname = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请输入页面ID')
      } else {
        if (!regExEnameFunc(value)) {
          return Promise.reject('请输入正确的页面ID（由英文字母和数字组成）')
        }
        return Promise.resolve()
      }
    }
    const rules = reactive({
      pageName: [{ required: true, validator: validateName, trigger: 'change' }],
      pageEname: [{ required: true, validator: validateEname, trigger: 'change' }]
    })

    const init = () => {
      state.formData.pageId = getQueryVariable('pageid') || sessionStorage.getItem('pageId')
      state.formData.pageType = getQueryVariable('pageType')
      getDataSourceList()
      getPageInfo()
     // getTagList()
    }

    watchEffect(() => {
      state.showModal = props.visible
      if (props.visible) {
        init()
      }
    })

    return {
      formRef,
      ...toRefs(state),
      hasPageId,
      isPageDataSource,
      rules,
      onSearchTag,
      onSelectTag,
      handleCancel,
      handleSave,
      afterClose
    }
  }
})

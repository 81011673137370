import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import { InputNumber, Select, Switch } from 'ant-design-vue'
import useSetAttribute from '@/hooks/useSetAttribute'
import SettingItemBox from '../../settingItemBox'
import SettingItemRow from '../../settingItemRow'

export default defineComponent({
  name: 'AutoUpdate',
  order: 3,
  setup() {
    const store = useStore()
    const { componentConfig }: any = userMapState('editor', ['componentConfig',]) as IState

    //是否有选中的数据
    const currentId = computed(() => componentConfig.value.id)

    const isMock = computed(() => componentConfig.value.api.type === 'mock')

    //是否自动刷新
    const isAutoReload = computed(() => componentConfig.value.api?.reload || false)

    //选择属性
    const handleChangeAttribute = (attribute: string, value: any) => {
      const setAttribute = useSetAttribute(store)
      setAttribute(currentId.value, attribute, value)
    }

    const requestSlot = () => (
      <SettingItemRow title={'请求频率'}>
        <InputNumber
          size="small"
          onChange={(value) => {
            handleChangeAttribute('reloadTime', value)
          }}
          v-model:value={componentConfig.value.api.reloadTime}
          disabled={isMock.value}
          min={10}
          defaultValue={10}/>
        <span>秒/次</span>
      </SettingItemRow>
    )

    const autoUpdateSlot = () => (
      <>
        <SettingItemRow title={'自动更新'}>
          <Switch
            size="small"
            disabled={isMock.value}
            v-model:checked={componentConfig.value.api.reload}
            onChange={(e) => {
              handleChangeAttribute('reload', e)
            }}/>
        </SettingItemRow>
        {isAutoReload.value ? requestSlot() : null}
      </>
    )


    return () => <SettingItemBox title="自动更新" v-slots={autoUpdateSlot}/>
  }
})

import { r, d, h } from "@/units"
import { reactive } from "vue"
import './index.less'
import { Popover } from "ant-design-vue"
import { QuestionCircleFilled } from '@ant-design/icons-vue'
export default d({
  name: 'pagePopover',
  props: {
    title: String,
    content: String,
  },
  setup: (props, context) => {
    const VNode = {
      // render() {
      //   return h(
      //     Popover,
      //     {
      //       title: props.title,
      //       content: props.content
      //     },
      //     context.slots.default && context.slots.default()
      //   )
      // }
      render() {
        return h(
          Popover,
          null,
          {
            default: () => h(r("a-button"), {
              type: "primary",
              shape: "circle",
            }, ()=>[h(QuestionCircleFilled)]),
            title: () => h('div', '新手指引'),
            content: () => [
              h('div', '空格 + 鼠标拖拽 == 拖拽画布'),
              h('div', '双击辅助线 == 快速删除辅助线'),
              h('div', 'Delete == 删除组件'),
              h('div', 'Command(CTRL) + (+ -)  == 屏幕缩放'),
              h('div', 'Ctrl | Commond + C == 克隆组件'),
              h('div', 'Shift + 鼠标左键 == 多选组件'),
            ]
          }
        )
      }
    }
    return () => h(VNode)
  }
})
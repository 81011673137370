import { computed, defineComponent, nextTick, onMounted } from 'vue'
import SettingItemBox from '../settingItemBox'
import { Form, InputNumber } from 'ant-design-vue'
import { useStore } from 'vuex'
import { isDisabled } from '@/utils'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import Moveable from 'vue3-moveable'
import { getElementInfo } from 'moveable'

export default defineComponent({
  name: 'LocationSize',
  setup() {
    const store = useStore()
    const {
      componentConfig,
      moveablerRef,
      selecto
    }: any = userMapState(
      'editor',
      ['componentConfig', 'moveablerRef', 'selecto']
    ) as IState

    // const componentConfig = computed(() => store.getters.getEditor.componentConfig)

    // 层级
    const Z_INDEX = 'zIndex'

    // 如果是修改x y信息，则触发draggable
    // 如果修改 width height，则触发resizable
    const locations = ['x', 'y']

    // 位置尺寸
    const locationSizeData = [
      {
        addonAfter: 'px',
        addonBefore: 'H',
        ref: 'selectHeight',
        label: 'height',
        min: 50,
      },
      {
        addonAfter: 'px',
        addonBefore: 'X',
        ref: 'selectX',
        label: 'left',
      },
      {
        addonAfter: 'px',
        addonBefore: 'W',
        ref: 'selectWidth',
        label: 'width',
        min: 50,
      },
      {
        addonAfter: 'px',
        addonBefore: 'Y',
        ref: 'selectY',
        label: 'top',
      },
      {
        addonAfter: '层',
        addonBefore: 'Z',
        ref: 'selectZ',
        label: 'zIndex',
        min: 0,
      },
    ]

    // 设置位置和尺寸
    const handleChange = (type, value) => {
      if (type == Z_INDEX) {
        !value && (value = 0)
        selecto.value.getSelectedTargets()[0].style['z-index'] = value
        store.commit('editor/SET_LOCATION_SIZE_ATTRIBUTE', {
          attribute: 'zIndex',
          value,
        })
        store.dispatch('editor/setMainJsonAttribute', {
          id: componentConfig.value.id,
          path: ['style','zIndex'],
          value
        })
        return
      } else {
        !value && (value = 20)
        store.commit('editor/SET_LOCATION_SIZE_ATTRIBUTE', {
          attribute: type,
          value,
        })
      }
      // 兼容moveable
      const convertType = {
        'width': 'offsetWidth',
        'height': 'offsetHeight',
        'left': 'x',
        'top': 'y',
      }
      
      let models = locations.includes(convertType[type]) ? 'draggable' : 'resizable'
      moveablerRef.value.request(
        models,
        {
          [convertType[type]]: value,
        },
        true
      )
    }

    // 位置和尺寸插槽
    const locationSizeSlot = () => (
      <>
        <Form
          layout="inline"
          style={{ paddingLeft: '6px' }}
          wrapperCol={{ style: { width: '110px' } }}
        >
          {locationSizeData.map(item => (
            <Form.Item>
              <InputNumber
                addonAfter={item.addonAfter}
                addonBefore={item.addonBefore}
                size="small"
                ref={item.ref}
                min={item.min}
                precision={0}
                v-model:value={componentConfig.value.locationSize[item.label]}
                disabled={isDisabled(componentConfig.value.locationSize[item.label])}
                onChange={value => handleChange(item.label, value)}
              />
            </Form.Item>
          ))}
        </Form>

        <div class="row" style="display: flex; padding: 10px 0; font-size: 12px">
          <div class="row-label" style="flex: 0 0 65px; padding-left: 10px">组件类型:</div>
          <div class="row-value">{ componentConfig.value.type }</div>
        </div>
      </>
    )

    return () => (
      <SettingItemBox title="位置和尺寸" v-slots={locationSizeSlot} />
    )
  },
})

import { defineComponent } from 'vue'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import SettingItemRow from '../../settingItemRow'

export default defineComponent({
  name: 'Id',
  order: 0,
  setup() {
    const { componentConfig }: any = userMapState('editor', ['componentConfig',]) as IState

    return () => (
      <SettingItemRow title={'组件ID'}>
        {componentConfig.value.id}
      </SettingItemRow>
    )
  }
})

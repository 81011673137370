import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import { defineComponent, reactive, } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Select } from 'ant-design-vue'

export default defineComponent({
  name: 'datePickerPicker',
  order: 3,
  setup(props, ctx) {
    const store = useStore()
    const { componentConfig, moveablerRef, mainJson, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'mainJson',
      'selecto'
    ]) as IState
    const state = reactive({
      
    })

    // 渲染组件
    const renderComponent = (key: string[], value: any) => {
      const _key = key.join()
      const { targetCharts, options } = useMoveableTarget(selecto)
      const _options = { ...options, ...componentConfig.value.optionData }
      // 复杂对象需要手动赋值替换（array or object）
      if (Array.isArray(_options[_key])) {
        _options[_key] = value
      }
      console.log('_options---', _options, componentConfig.value.optionData)
      targetCharts.setOption(_options, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const setProperty = (key: string[], value: any): void => {
      useSetOptionProperty({
        id: componentConfig.value.id,
        key,
        value,
      })
      renderComponent(key, value)
    }

    const handleChange = (key: string[], value: any): void => {
      setProperty(key, value)
    }

    // 日期模式插槽
    const pickerSlot = () => (
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">选择模式</div>
          <div class="setting-row__content">
            <Select
              size="small"
              v-model:value={componentConfig.value.optionData.picker}
              onChange={(picker: string) => handleChange(['picker'], picker)}
            >
              <Select.Option value="date">默认</Select.Option>
              <Select.Option value="month">月</Select.Option>
              <Select.Option value="week">周</Select.Option>
              <Select.Option value="year">年</Select.Option>
              <Select.Option value="quarter">季度</Select.Option>
            </Select>
          </div>
        </div>
      </div>
    )

    return () => <SettingItemBox title="选择器类型" v-slots={pickerSlot} />
  },
})

import { computed, defineComponent, ref, reactive, watchEffect, watch, render, onMounted, nextTick } from 'vue'
import { VAceEditor } from 'vue3-ace-editor'
import SettingItemBox from '../settingItemBox'
import { Form, Select, Input, Button, Checkbox, InputNumber } from 'ant-design-vue'
import { updateChartsData } from '@/utils/updateChartsData'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { typeList, dataSourceList, methodList, headersList } from './data'
import * as echarts from 'echarts'
import { IState } from '@/store/modules/editor'
import _ from 'lodash'
;

const { Option } = Select

export default defineComponent({
  name: 'actionsConfig',
  components: {
    VAceEditor,
  },
  setup(props, context) {
    const store = useStore()
    const mockDataRef = ref<any | null>(null)
    const {
      componentConfig,
      mainJson,
      moveablerRef,
      selecto
    }: any = userMapState(
      'editor',
      ['componentConfig', 'mainJson', 'moveablerRef','selecto']
    ) as IState

    const state = reactive({
      optionStr: '',
      mockDataStr: '',
      requestHeaderStr: '{}',
      requestBodyStr: '{}',
      requestHeaderConfig: {
        lang: 'javascript', //解析 json,javascript
        theme: 'clouds_midnight',
        wrap: 'free',
        options: {
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          wrap: true,
          tabSize: 2,
          showPrintMargin: false,
          fontSize: 12,
          useWorker: false,
          highlightSelectedWord: true,
        }
      }
    })

    //是否有选中的数据
    const hasSelected = computed(() => componentConfig.value.id)

    const isDark = computed(() => mainJson.value.isDark)

    watch(
      () => componentConfig.value.id,
      () => {
        getEditorData()
      }
    )

    onMounted(() => {
      getEditorData()
    })

    const getEditorData = () => {
      if (!hasSelected.value) return  state.optionStr = ''
      nextTick(() => {
        const component = mainJson.value.body.children.find(item => item.id === hasSelected.value)
        const { action } = component
        state.optionStr = action || ''
      })
    }

    // const setChartsData = (data) => {
    //   const { targetCharts, options } = useMoveableTarget(selecto)
    //   targetCharts.setOption(data, false)
    // }

    const saveActions = () => {
      const action = state.optionStr
      store.dispatch('editor/setMainJsonAttribute', {
        id: hasSelected.value,
        path: ['action'],
        value: action
      })
      // setChartsData(option)
    }

    //mock数据插槽
    const optionsConfigSlot = () => {
      const { requestHeaderConfig } = state
      return (
        <Form.Item>
          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <span></span>
            <Button size={'small'} type="primary" onClick={saveActions}>设置</Button>
          </div>
          <VAceEditor
            value={state.optionStr}
            v-model:value={state.optionStr}
            ref={mockDataRef}
            lang={requestHeaderConfig.lang}
            theme={isDark.value ? requestHeaderConfig.theme : 'chrome'}
            options={requestHeaderConfig.options}
            style={{
              height: 'calc(100vh - 188px)',
              width: '100%',
            }}
          />
        </Form.Item>
      )
    }


    return () => (
      <div class={'data-setting'}>
        <SettingItemBox title="配置编辑" v-slots={optionsConfigSlot} />
      </div>
    )
  }
})

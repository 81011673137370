import { userMapState } from '@/store/userMapper'
import { computed, defineComponent, reactive, watch } from 'vue'
import SettingItemBox from '../../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Button, Table, Input, Switch, RadioGroup, Tabs, TabPane } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import { PolarComponent } from 'echarts/components'

type Key = string | number

interface DataItem {
  key: number
  label: string
  value: string
  checked: boolean
  extraParams?: string | number | null | Record<string, any>
}

interface IState {
  dataSource: DataItem[]
  selectedRowKeys: Key[]
}

// table columns
const columns = [
  {
    title: '选项',
    dataIndex: 'label',
    key: 'label',
  },
  {
    title: '值',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: '',
    key: 'delete',
  },
]

const tabTypes = [
  { label: '默认', value: 'line' },
  { label: '卡片', value: 'card' }
]

export default defineComponent({
  name: 'formOptions',
  order: 6,
  setup(props, ctx) {
    const { mainJson, componentConfig, selecto }: any = userMapState('editor', [
      'mainJson',
      'componentConfig',
      'selecto',
    ]) as IState

    // mock数据
    const staticData =
      useMoveableTarget(selecto)?.targetCharts.proxy.staticData.source

    const targetComponent = mainJson.value.body.children.find(item => item.id === componentConfig.value.id)

    const state = reactive<IState>({
      dataSource: [],
      selectedRowKeys: [],
    })

    // mock数据转换成Array<Object>
    const convertStaticData = data => {
      if (!data || !data.length) return []
      const result = []
      const [labels, values, checkedList, extraParamList] = data
      labels.forEach((item, idx) => {
        const _row = {
          key: idx,
          label: item,
          value: values[idx],
          checked: checkedList[idx],
          extraParams: extraParamList[idx]
        }
        result.push(_row)
      })
      return result
    }

    // 重新渲染组件
    const reRenderComponent = () => {
      const { targetCharts } = useMoveableTarget(selecto)
      targetCharts.setOption(componentConfig.value.optionData, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const setProperty = (key: string[], value: any): void => {
      useSetOptionProperty({
        id: componentConfig.value.id,
        key,
        value,
      })
    }

    // 新增tab
    const handleAddTab = () => {
      let newTabOpts = {
        key: state.dataSource.length,
        label: '',
        value: '',
        checked: false,
        extraParams: null
      }
      state.dataSource.push(newTabOpts)
    }

    // 删除
    const handleDelete = (index: number) => {
      state.dataSource.splice(index, 1)
      setProperty(['options'], state.dataSource)
      reRenderComponent()
    }

    // 表格插槽
    const bodyCellSlot = ({ column, record, index }) =>
      Object.keys(record).includes(column.key) ? (
        <div class="setting-row__content">
          {column.key !== 'label' ? (
            <Input
              size="small"
              onChange={reRenderComponent}
              v-model:value={record.value}
            />
          ) : (
            <Input
              size="small"
              onChange={reRenderComponent}
              v-model:value={record.label}
            />
          )}
        </div>
      ) : (
        <a onClick={() => handleDelete(index)}>
          <CloseCircleOutlined style="color:red" />
        </a>
      )

    // 设置选中
    const setChecked = index => {
      state.dataSource.forEach(item => {
        item.checked = false
      })
      state.dataSource[index].checked = true
    }

    // 选中
    const handleSelectedChange = (selectedRowKeys: Key[], selectedRows) => {
      console.log(
        'handleSelectedChange',
        selectedRowKeys,
        state.dataSource,
        selectedRows
      )
      state.selectedRowKeys = selectedRowKeys
      const [index] = selectedRowKeys
      const activeKey = state.dataSource[index].value
      setChecked(index)
      setProperty(['activeKey'], activeKey)
      reRenderComponent()
    }

    // 是否开启额外的参数配置
    const handleUpdateEnableExtraParams = (enableExtraParams: boolean) => {
      setProperty(['enableExtraParams'], enableExtraParams)
      reRenderComponent()
    }

    // 更改tab样式
    const handleChangeTabType = (tabType: string) => {
      setProperty(['tabType'], tabType)
      reRenderComponent()
    }

    watch(() => targetComponent.staticData.source, (data) => {
      console.log('watch staticData--', data)
      state.dataSource = convertStaticData(data)
      setProperty(['options'], state.dataSource)
      reRenderComponent()
    })

    const init = () => {
      const { options } = componentConfig.value.optionData
      state.dataSource = options.length
        ? options
        : convertStaticData(staticData)
      const selectedRowIdx = state.dataSource.findIndex(item => item.checked)
      state.selectedRowKeys = [selectedRowIdx]
      setProperty(['options'], state.dataSource)
    }

    init()

    // 边框插槽
    const optionsSlot = () => (
      
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__content">
            <Button
              size="small"
              class="editable-add-btn"
              style="margin-bottom: 8px;"
              onClick={handleAddTab}
            >
              添加
            </Button>
            <Table
              size="small"
              bordered
              pagination={false}
              dataSource={state.dataSource}
              rowSelection={{
                type: 'radio',
                selectedRowKeys: state.selectedRowKeys,
                onChange: handleSelectedChange,
              }}
              v-slots={{
                bodyCell: ({ column, record, index }) =>
                  bodyCellSlot({ column, record, index }),
              }}
              columns={columns}
            ></Table>
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">额外参数</div>
          <div class="setting-row__content">
          <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.stripe}
              onChange={(enableExtraParams: boolean) =>
                handleUpdateEnableExtraParams(enableExtraParams)
              }
            />
          </div>
        </div>

        {/* <div class="setting-row">
          <div class="setting-row__label">tab类型</div>
          <div class="setting-row__content">
            <RadioGroup
              size="small"
              v-model:value={componentConfig.value.optionData.tabType}
              optionType={'button'}
              options={tabTypes}
              onChange={(value) => handleChangeTabType(value.target.value)}
            />
          </div>
        </div> */}
      </div>
    )

    return () => <SettingItemBox title="选项" v-slots={optionsSlot} />
  },
})

export const option = {
  label: '按钮',
  dataset: {
    source: []
  },
  mold: 'default',
  size: '大',
  series: [
    {
      type: 'button'
    }
  ]
}

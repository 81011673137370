import useMoveableTarget from '@/hooks/useMoveableTarget'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { Switch } from 'ant-design-vue'
import { defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import _ from 'lodash'

export default defineComponent({
  name: 'Thead',
  order: 0,
  setup(props) {
    const store = useStore()
    const { componentConfig, moveablerRef, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto'
    ]) as IState

    const state = reactive({
      colorPickerShow: false
    })

    const theadDefaultOptions = [
      {
        textStyle: {
          fontSize: 14
        },
      },
    ]

    const handleChange = (type: string, value: any) => {
      const propKeyMap = {
        show: ['thead', '0', 'show']
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })

      const { targetCharts, options } = useMoveableTarget(selecto)
      options.thead = componentConfig.value.optionData.thead
      targetCharts.setOption(options, false)
    }


    const theadSlot = () => (
      <div class="thead-box common-setting-item-box">
        {console.log('-----theadSlot', componentConfig.value.optionData.thead)}
        <div class="setting-row">
          <div class="setting-row__label">是否显示</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.thead[0].show}
              onChange={value => handleChange('show', value)}
            />
          </div>
        </div>
      </div>
    )

    return () => <SettingItemBox title="表头" v-slots={theadSlot} />
  },
})

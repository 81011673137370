export const option = {
  thead: [
    {
      show: true
    }
  ],
  grid: [
    {
      top: '5%',
      left: '5%',
      bottom: '5%',
      right: '5%'
    }
  ],
  row: {
    rowAutoHeight: true,
    rowHeight: '',
    splitArea: {
      show: false
    },
    rowBackground: 'rgba(0,0,0,0)'
  },
  series: [
    {
      type: ''
    }
  ],
  dataset: {
    source: []
  }
}

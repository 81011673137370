export const option = {
//   backgroundColor: 'transparent',
//   series: [{
//     name: '水球图',
//     type: 'liquidFill',
//     radius: '80%',
//     center: ['50%', '55%'],
//     waveAnimation: 10, // 动画时长
//     amplitude: 20, // 振幅
//     itemStyle: {
//         shadowBlur: 0
//     },
//     color: 'rgba(68, 197, 253,1)',
//     label: {
//         normal: {
//             color: '#44C5FD',
//             textStyle: {
//                 fontSize: 16,
//                 fontWeight: 'normal'
//             },
//         }
//     },
//     outline: {
//         show: true,
//         borderDistance: 5,
//         itemStyle: {
//             borderColor: 'rgba(68, 197, 253,1)',
//             borderWidth: 4
//         }
//     },
//     backgroundStyle: {
//         color: '#050038'
//     },
//     data:[0.6]
//   }]
backgroundColor: 'transparent',
    series: [
        {
            type: 'liquidFill', //水位图
            data: [0.6],
            radius: '80%', //显示比例
            center: ['50%', '50%'], //中心点
            amplitude: 20, //水波振幅
            // data: [0.5,.5,.5], // data个数代表波浪数
            color: [
                {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: '#446bf5'
                        },
                        {
                            offset: 1,
                            color: '#2ca3e2'
                        },
                    ],
                    globalCoord: false
                },
            ], //波浪颜色
            backgroundStyle: {
                borderWidth: 1, //外边框
                // borderColor: '#23cc72', //边框颜色
                color: 'RGBA(51, 66, 127, 0.7)' //边框内部填充部分颜色
            },
            label: {
                //标签设置
                position: ['50%', '45%'],
                formatter: '50%', //显示文本,
                textStyle: {
                    fontSize: '52px', //文本字号,
                    color: '#fff'
                }
            },
            outline: {
                show: true,
                borderDistance: 5,
                itemStyle: {
                    borderColor: 'rgba(68, 197, 253,1)',
                    borderWidth: 4
                }
            }
        }
    ]
}


// backgroundColor: 'transparent',
//     series: [
//         {
//             type: 'liquidFill', //水位图
//             data: [0.6],
//             radius: '80%', //显示比例
//             center: ['50%', '50%'], //中心点
//             amplitude: 20, //水波振幅
//             // data: [0.5,.5,.5], // data个数代表波浪数
//             color: [
//                 {
//                     type: 'linear',
//                     x: 0,
//                     y: 0,
//                     x2: 0,
//                     y2: 1,
//                     colorStops: [
//                         {
//                             offset: 0,
//                             color: '#446bf5'
//                         },
//                         {
//                             offset: 1,
//                             color: '#2ca3e2'
//                         },
//                     ],
//                     globalCoord: false
//                 },
//             ], //波浪颜色
//             backgroundStyle: {
//                 borderWidth: 1, //外边框
//                 // borderColor: '#23cc72', //边框颜色
//                 color: 'RGBA(51, 66, 127, 0.7)' //边框内部填充部分颜色
//             },
//             label: {
//                 //标签设置
//                 position: ['50%', '45%'],
//                 formatter: '50%', //显示文本,
//                 textStyle: {
//                     fontSize: '52px', //文本字号,
//                     color: '#fff'
//                 }
//             },
//             outline: {
//                 show: true,
//                 borderDistance: 5,
//                 itemStyle: {
//                     borderColor: 'rgba(68, 197, 253,1)',
//                     borderWidth: 4
//                 }
//             }
//         }
//     ]
import { defineComponent, onMounted } from 'vue'
import { userMapState } from '@/store/userMapper'
import SettingItemBox from '../../../settingItemBox'
import { Input, RadioGroup, RadioButton } from 'ant-design-vue'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import useMoveableTarget from '@/hooks/useMoveableTarget'

export default defineComponent({
  name: 'config',
  setup() {
    const { componentConfig, selecto }: any = userMapState('editor', [
      'componentConfig',
      'selecto',
    ])

    // 渲染组件
    const renderComponent = (key: string[], value: any) => {
      const _key = key.join()
      const { targetCharts, options } = useMoveableTarget(selecto)
      const _options = { ...options, ...componentConfig.value.optionData }
      // 复杂对象需要手动赋值替换（array or object）
      if (Array.isArray(_options[_key])) {
        _options[_key] = value
      }
      console.log('_options---', _options, componentConfig.value.optionData)
      targetCharts.setOption(_options, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const setProperty = (key: string[], value: any): void => {
      useSetOptionProperty({
        id: componentConfig.value.id,
        key,
        value,
      })
      renderComponent(key, value)
    }

    const handleChange = (key: string[], value: any): void => {
      setProperty(key, value)
      // todo 触发更新
      // setProperty(['updateConfig'], value)
    }

    // X轴配置
    const XAxisKeyConfig = () => {
      const { config } = componentConfig.value.optionData
      return (
        <div class="setting-row">
          <div class="setting-row__label" style={{ flex: '0 0 90px' }}>x轴字段</div>
          <div class="setting-row__content">
            <Input
              size="small"
              v-model:value={config.axisKey}
              placeholder="请输入x轴字段"
              onInput={(event: Event) => {
                handleChange(
                  ['config', 'axisKey'],
                  (event.target as HTMLInputElement).value
                )
              }}
            />
          </div>
        </div>
      )
    }

    // Y轴字段
    const SeriesMapKeyConfig = () => {
      const { config } = componentConfig.value.optionData
      return (
        <div class="setting-row">
          <div class="setting-row__label" style={{ flex: '0 0 90px' }}>Y轴字段</div>
          <div class="setting-row__content">
            <Input
              size="small"
              v-model:value={config.seriesMapKey}
              placeholder="请输入Y轴字段"
              onInput={(event: Event) => {
                handleChange(
                  ['config', 'seriesMapKey'],
                  (event.target as HTMLInputElement).value
                )
              }}
            />
          </div>
        </div>
      )
    }

    // 系列配置
    const SeriesKeyConfig = () => {
      const { config } = componentConfig.value.optionData
      return (
        <div class="setting-row">
          <div class="setting-row__label" style={{ flex: '0 0 90px' }}>系列字段</div>
          <div class="setting-row__content">
            <Input
              size="small"
              v-model:value={config.seriesKey}
              placeholder="请输入系列字段"
              onInput={(event: Event) => {
                handleChange(
                  ['config', 'seriesKey'],
                  (event.target as HTMLInputElement).value
                )
              }}
            />
          </div>
        </div>
      )
    }

    // 堆叠字段配置 stackKey
    const StackKeyConfig = () => {
      const { config } = componentConfig.value.optionData
      return (
        <div class="setting-row">
          <div class="setting-row__label" style={{ flex: '0 0 90px' }}>堆叠字段</div>
          <div class="setting-row__content">
            <Input
              size="small"
              v-model:value={config.stackKey}
              placeholder="请输入堆叠字段"
              onInput={(event: Event) => {
                handleChange(
                  ['config', 'stackKey'],
                  (event.target as HTMLInputElement).value
                )
              }}
            />
          </div>
        </div>
      )
    }

    // 横轴
    const MainAxisConfig = () => {
      const { config } = componentConfig.value.optionData
      return (
        <div class="setting-row">
          <div class="setting-row__label" style={{ flex: '0 0 90px' }}>横轴</div>
          <div class="setting-row__content">
            <RadioGroup 
              size="small" 
              v-model:value={config.mainAxis}
              onChange={(event: any) => {
                handleChange(
                  ['config', 'mainAxis'],
                  (event.target as HTMLInputElement).value
                )
              }}
            >
              <RadioButton value="x">X轴</RadioButton>
              <RadioButton value="y">Y轴</RadioButton>
            </RadioGroup>
          </div>
        </div>
      )
    }
    
    const renderDomSlot = () => (
      <div class="setting-container">
        {/* x轴配置 */}
        <XAxisKeyConfig />
        {/* 系列映射字段 */}
        <SeriesMapKeyConfig />
        {/* 系列字段配置 */}
        <SeriesKeyConfig />
        {/* 堆叠字段配置 */}
        <StackKeyConfig />
        {/* 主轴配置 */}
        <MainAxisConfig />
      </div>
    )

    onMounted(() => {
      console.log('lineBarChart componentConfig', componentConfig.value)
    })

    return () => <SettingItemBox title="图配置" v-slots={renderDomSlot} />
  },
})

import { defineComponent, reactive, ref, nextTick, onMounted, watchEffect, onUnmounted } from 'vue'
import {
  Button,
  Modal,
  Input,
  Switch,
  Select,
  Table,
  message,
  Upload,
  InputNumber,
  RadioGroup,
  Checkbox,
  Tooltip
} from 'ant-design-vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../../settingItemBox'
import { userMapState } from '@/store/userMapper'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { cloneDeep, uniqueId } from 'lodash'
import type { ColumnsType } from 'ant-design-vue/es/table/interface'
import Sortable, { SortableEvent } from 'sortablejs'
import { DragOutlined } from '@ant-design/icons-vue'
import { randomString, treeToArray } from '@/utils'
import { ITableData } from '@/types'
import { CloseCircleOutlined, UploadOutlined, QuestionCircleFilled } from '@ant-design/icons-vue'
import API from '@/api'
import { ChooseType } from '@/enums'
import './index.less'

const chooseTypeList = [
  { label: '无', value: 0 },
  { label: '多选', value: 1 },
  { label: '单选', value: 2 },
]

export default defineComponent({
  name: 'PropertiesConfig',
  order: 2,
  emits: ['onRowSelection'],
  setup(props, { emit }) {
    const { componentConfig, selecto, moveablerRef, mainJson }: any = userMapState('editor', [
      'moveablerRef',
      'mainJson',
      'componentConfig',
      'selecto',
    ])

    // const tableRef = ref<HTMLElement>(null)
    const tableRef = ref<any | null>(null)

    // Modal 列配置 表格columns
    const columns: ColumnsType = [
      {
        title: '拖动',
        dataIndex: 'drag',
        key: 'drag',
      },
      {
        title: '字段类型',
        dataIndex: '_type',
        key: '_type',
        width: 100,
      },
      {
        title: '中文名',
        dataIndex: 'title',
        key: 'title',
        width: 200,
      },
      {
        title: '字段名',
        dataIndex: 'dataIndex',
        key: 'dataIndex',
        width: 150,
      },
      {
        title: '宽度',
        dataIndex: 'width',
        key: 'width',
        width: 80,
      },
      {
        title: '是否汇总',
        dataIndex: 'isSummary',
        key: 'isSummary',
        width: 80,
      },
      {
        title: '汇总单位',
        dataIndex: 'summary',
        key: 'summary',
        width: 80,
      },
      {
        title: '固定',
        dataIndex: 'fixed',
        key: 'fixed',
        width: 80,
      },
      {
        title: '显示',
        dataIndex: 'isShow',
        key: 'isShow',
        width: 80,
      },
      {
        title: '允许换行',
        dataIndex: 'allowWrapperLine',
        key: 'allowWrapperLine',
        width: 80
      },
      {
        title: '溢出隐藏',
        dataIndex: 'ellipsis',
        key: 'ellipsis',
        width: 80
      },
      {
        title: '附加配置',
        dataIndex: 'extraConfig',
        key: 'extraConfig'
      },
      {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
      }
    ]

    // 字段类型下拉
    const fieldTypes = Object.freeze([
      {
        typeName: '文本',
        typeValue: 'text'
      },
      {
        typeName: '颜色文本',
        typeValue: 'colorText'
      },
      {
        typeName: '标签',
        typeValue: 'tag'
      },
      {
        typeName: '图片',
        typeValue: 'image'
      },
      {
        typeName: '链接',
        typeValue: 'link'
      },
      {
        typeName: '进度条',
        typeValue: 'process'
      }
    ])

    const state = reactive({
      showDialog: false,
      dataSource: [],
      flatTreeData: [],
      sortableObj: null,
      indexSource: [],
      flatDataSource: [],
    })

    // 渲染组件
    const renderComponent = (key: string[], value: any) => {
      const _key = key.join()
      const { targetCharts, options } = useMoveableTarget(selecto)
      const _options = { ...options, ...componentConfig.value.optionData }
      // 复杂对象需要手动赋值替换（array or object）
      if (Array.isArray(_options[_key])) {
        _options[_key] = value
      }
      console.log('_options---', _options, componentConfig.value.optionData)
      targetCharts.setOption(_options, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const setProperty = (key: string[], value: any): void => {
      useSetOptionProperty({
        id: componentConfig.value.id,
        key,
        value,
      })
      renderComponent(key, value)
    }

    // 设置索引
    const setIndexColumn = () => {
      const indexColumn = {
        dataIndex: 'index',
        fixed: true,
        title: '#',
        width: 50,
        isShow: true,
      }
      const { showIndex, columns } = componentConfig.value.optionData
      const _columns = showIndex ? [indexColumn, ...columns] : columns.filter(column => column.dataIndex !== 'index')
      setProperty(['columns'], _columns)
    }

    // 设置选择类型
    const setChooseType = () => {
      const { chooseType, rowChange, columnClick } = componentConfig.value.optionData
      const isCheckbox = chooseType === ChooseType.CheckBok
      const isRadio = chooseType === ChooseType.Radio
      const isRowOrColumnClick = rowChange || columnClick
      const _processType = (isCheckbox || isRadio || isRowOrColumnClick) ? 'entry' : 'display'
      setProperty(['processType'], _processType)
    }

    // 设置合计行
    const setSummary = () => {
      const showSummary = componentConfig.value.optionData['show-summary']
      if (!showSummary) {
        setProperty(['isFixedSummary'], false)
        setProperty(['sum-text'], '')
      }
    }

    const mutexProps = (key: string[]) => {
      const { isScrollLoadModel, isPolling, isScrollTo } = componentConfig.value.optionData
      const IS_SCROLL_LOAD_MODEL = 'isScrollLoadModel'
      const IS_POLLING = 'isPolling'
      const IS_SCROLL_TO = 'isScrollTo'
      // 是否滚动加载
      const hasScrollLoadModel = key.join() === IS_SCROLL_LOAD_MODEL
      // 是否轮循
      const hasPolling = key.join() === IS_POLLING
      // 是否翻滚
      const hasScrollTo = key.join() === IS_SCROLL_TO
       
       // 滚动加载 是否轮循 否翻滚 三个选项互斥
      if (hasScrollLoadModel && isScrollLoadModel) {
        setProperty([IS_POLLING], false)
        setProperty([IS_SCROLL_TO], false)
      }
      if (hasPolling && isPolling) {
        setProperty([IS_SCROLL_LOAD_MODEL], false)
        setProperty([IS_SCROLL_TO], false)
      }
      if (hasScrollTo && isScrollTo) {
        setProperty([IS_SCROLL_LOAD_MODEL], false)
        setProperty([IS_POLLING], false)
      }
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const handleChange = (key: string[], value: any): void => {
      
      // 是否开启索引
      const hasIndex = key.join() === 'showIndex'
      // 选择类型
      const hasChooseType = key.join() === 'chooseType'
      // 是否滚动加载
      const hasScrollLoadModel = key.join() === 'isScrollLoadModel'
      // 是否轮循
      const hasPolling = key.join() === 'isPolling'
      // 是否翻滚
      const hasScrollTo = key.join() === 'isScrollTo'
      // 开启合计行
      const hasShowSummary = key.join() === 'show-summary'
      setProperty(key, value)

      // 是否轮循 是否翻滚 是否翻滚 三个属性互斥、只能同时开启一个
      if (hasScrollLoadModel || hasPolling || hasScrollTo) {
        mutexProps(key)
      }
      hasIndex && setIndexColumn()
      hasChooseType && setChooseType()
      hasShowSummary && setSummary()
    }

    // 上传图片
    const handleCustomRequest = (info, index) => {
      // const { targetCharts, options } = useMoveableTarget(selecto)
      const formData = new FormData()
      formData.append('file', info.file)


      const { columns } = componentConfig.value.optionData
      const targetIndex = columns.findIndex(item => item.dataIndex == index)
      console.log('componentConfig.value.optionData', componentConfig.value.optionData)


      API.pageManage.uploadBackgroundImage(formData, mainJson.value.pageID).then((res: any) => {
        if (!res.success) return
        const { targetCharts, options } = useMoveableTarget(selecto)
        setProperty(['indexSource', index], res.data.filePath)
        componentConfig.value.optionData.indexSource[index] = res.data.filePath
        targetCharts.setOption(options, false)
      })
    }

    //mock数据插槽
    const optionsIndexIMGSlot = (column, record, index) => {

      if (column.key === 'index') {
        return (
          <span>{index + 1}</span>
        )
      }
      if (column.key === 'url') {
        return (
          <img v-show={record.length > 0} src={record} style="height:24px" />
        )
      }
      if (column.key === 'update') {
        return (
          <Upload
            name="background"
            class="background-upload"
            showUploadList={false}
            maxCount={1}
            customRequest={(info) => handleCustomRequest(info, index)}
          >
            <Button size="small">
              <UploadOutlined></UploadOutlined>
            </Button>
          </Upload>
        )
      }
      if (column.key === 'delete') {
        return (
          <a onClick={() => onDelete(index)}><CloseCircleOutlined style="color:red" /></a>
        )
      }
    }

    // 打开配置弹窗
    const handleOpenModal = () => {
      // 去除索引列
      const { columns } = componentConfig.value.optionData
      state.dataSource = columns.filter(row => row.dataIndex !== 'index')
      state.showDialog = true
      nextTick(() => {
        initDrag()
      })
    }

    const onDelete = (index: number) => {
      componentConfig.value.optionData.indexSource.splice(index, 1)
      setProperty(['indexSource'], componentConfig.value.optionData.indexSource)
    }

    // 基础属性配置
    const BasicPropertiesConfig = () => (
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">斑马</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.stripe}
              onChange={(isChecked: boolean) =>
                handleChange(['stripe'], isChecked)
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">边框</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.border}
              onChange={(isChecked: boolean) =>
                handleChange(['border'], isChecked)
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">尺寸</div>
          <div class="setting-row__content">
            <Select
              size="small"
              v-model:value={componentConfig.value.optionData.size}
              onChange={(size: string) => handleChange(['size'], size)}
            >
              <Select.Option value="small">小</Select.Option>
              <Select.Option value="middle">中</Select.Option>
              <Select.Option value="large">大</Select.Option>
            </Select>
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">显示表头</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData['show-header']}
              onChange={(isShow: boolean) =>
                handleChange(['show-header'], isShow)
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">行点击</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.rowChange}
              onChange={(isClick: boolean) => {
                handleChange(['rowChange'], isClick)
                if (isClick) {
                  componentConfig.value.optionData.columnClick && handleChange(['columnClick'], !isClick)
                }
                setChooseType()
              }
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">列点击</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.columnClick}
              onChange={(isClick: boolean) => {
                handleChange(['columnClick'], isClick)
                if (isClick) {
                  componentConfig.value.optionData.rowChange && handleChange(['rowChange'], !isClick)
                }
                setChooseType()
              }
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">选择类型</div>
          <div class="setting-row__content">
            <RadioGroup
              size="small"
              v-model:value={componentConfig.value.optionData.chooseType}
              optionType={'button'}
              options={chooseTypeList}
              onChange={(value) => handleChange(['chooseType'], value.target.value)}
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">显示索引</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.showIndex}
              onChange={(isShow: boolean) =>
                handleChange(['showIndex'], isShow)
              }
            />
          </div>
        </div>

        <div class="setting-row" v-show={componentConfig.value.optionData.showIndex}>
          {/* <div class="setting-row__label">索引图片</div> */}
          <div class="setting-row__content">
            <div class="setting-row__label">索引图片 <Button size='small' class="editable-add-btn" style="margin-bottom: 8px;" onClick={handleAddIndexIMG}>添加</Button></div>
            <Table
              v-show={componentConfig.value.optionData?.indexSource?.length > 0}
              showHeader={false}
              id="indexTable"
              size='small'
              bordered
              pagination={false}
              v-model:dataSource={componentConfig.value.optionData.indexSource}
              v-slots={{
                bodyCell: ({ column, record, index }) => optionsIndexIMGSlot(column, record, index)
              }}
              columns={[
                {
                  title: '',
                  key: 'index'
                },
                {
                  title: '图片',
                  key: 'url',
                }, {
                  title: '',
                  key: 'update',
                },
                {
                  title: '',
                  key: 'delete',
                }
              ]}
            >
            </Table>
          </div>

        </div>

        <div class="setting-row">
          <div class="setting-row__label">页面条数</div>
          <div class="setting-row__content">
            <Input
              size="small"
              v-model:value={componentConfig.value.optionData.pageSize}
              placeholder="请输入页面条数"
              onInput={(event: Event) => {
                handleChange(
                  ['pageSize'],
                  (event.target as HTMLInputElement).value
                )
              }}
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">滚动加载</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.isScrollLoadModel}
              onChange={(isLoad: boolean) =>
                handleChange(['isScrollLoadModel'], isLoad)
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">是否轮循</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.isPolling}
              onChange={(isPolling: boolean) =>
                handleChange(['isPolling'], isPolling)
              }
            />
          </div>
        </div>

        <div class="setting-row" v-show={componentConfig.value.optionData.isPolling}>
          <div class="setting-row__label">轮循时间</div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              v-model:value={componentConfig.value.optionData.pollingTime}
              placeholder="请输入轮循时间"
              onChange={(value: number) => {
                handleChange(
                  ['pollingTime'],
                  value
                )
              }}
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">是否翻滚</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.isScrollTo}
              onChange={(isScrollTo: boolean) =>
                handleChange(['isScrollTo'], isScrollTo)
              }
            />
          </div>
        </div>

        <div class="setting-row" v-show={componentConfig.value.optionData.isScrollTo}>
          <div class="setting-row__label">翻滚速度</div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              defaultValue={10000}
              v-model:value={componentConfig.value.optionData.scrollToSpeed}
              placeholder="请输入翻滚速度"
              onChange={(value: number) => {
                handleChange(
                  ['scrollToSpeed'],
                  value
                )
              }}
            />
          </div>
        </div>

        <div class="setting-row" v-show={componentConfig.value.optionData.isScrollTo}>
          <div class="setting-row__label">重复翻滚</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.scrollToRePlay}
              onChange={(scrollToRePlay: boolean) =>
                handleChange(['scrollToRePlay'], scrollToRePlay)
              }
            />
          </div>
        </div>

        <div class="setting-row" v-show={componentConfig.value.optionData.isScrollTo}>
          <div class="setting-row__label">隐藏滚动条</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.scrollShow}
              onChange={(scrollShow: boolean) =>
                handleChange(['scrollShow'], scrollShow)
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">汇总行</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData['show-summary']}
              onChange={(isShow: boolean) =>
                handleChange(['show-summary'], isShow)
              }
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">总计行</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.showAllSummary}
              onChange={(isShow: boolean) =>
                handleChange(['showAllSummary'], isShow)
              }
            />
          </div>
        </div>

        <div 
          class="setting-row"
          v-show={componentConfig.value.optionData['show-summary']}
        >
          <div class="setting-row__label">固定合计</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.isFixedSummary}
              onChange={(isFixed: boolean) =>
                handleChange(['isFixedSummary'], isFixed)
              }
            />
          </div>
        </div>

        <div
          class="setting-row"
          v-show={componentConfig.value.optionData['show-summary']}
        >
          <div class="setting-row__label">合计行文本</div>
          <div class="setting-row__content">
            <Input
              size="small"
              v-model:value={componentConfig.value.optionData['sum-text']}
              placeholder="请输入合计行文本"
              onInput={(event: Event) => {
                handleChange(
                  ['sum-text'],
                  (event.target as HTMLInputElement).value
                )
              }}
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">列配置</div>
          <div class="setting-row__content">
            <Button type="primary" size="small" onClick={handleOpenModal}>
              属性设置
            </Button>
          </div>
        </div>

        <div 
          class="setting-row"
        >
          <div class="setting-row__label">继承剩余页面高度</div>
          <div class="setting-row__content">
            <Switch
              disabled={!mainJson.value.body.style.isMAXPageHeight}
              size="small"
              v-model:checked={componentConfig.value.optionData.isFixedPageSizeH}
              onChange={(isFixedPageSizeH: boolean) =>
                handleChange(['isFixedPageSizeH'], isFixedPageSizeH)
              }
            />
          </div>
        </div>

        <div 
          class="setting-row"
        >
          <div class="setting-row__label">继承剩余页面宽度</div>
          <div class="setting-row__content">
            <Switch
              disabled={!mainJson.value.body.style.isMAXPageWidth}
              size="small"
              v-model:checked={componentConfig.value.optionData.isFixedPageSizeW}
              onChange={(isFixedPageSizeW: boolean) =>
                handleChange(['isFixedPageSizeW'], isFixedPageSizeW)
              }
            />
          </div>
        </div>
      </div>

      

      
    )

    // 新增行
    const handleAdd = (): void => {
      const rowConfig: ITableData = {
        id: randomString(5),
        title: '',
        dataIndex: '',
        width: '',
        summary: '',
        level: 1,
        fixed: false,
        isShow: true,
        resizable: true
      }
      state.dataSource.push(rowConfig)
    }

    const handleAddIndexIMG = () => {
      let newData = {
        key: componentConfig.value.optionData.indexSource.length,
        url: ''
      };
      componentConfig.value.optionData.indexSource.push(newData);
    }

    // 新增二级表头
    const handleAddSecondHead = (
      event: Event,
      record: ITableData,
      index: number
    ): void => {
      const rowConfig: ITableData = {
        id: uniqueId('sub-id'),
        level: 2,
        title: '',
        dataIndex: '',
        width: '',
        summary: '',
        fixed: false,
        isShow: true,
        resizable: true
      }
      if (
        !record.children ||
        (Array.isArray(record.children) && !record.children.length)
      ) {
        record.children = [rowConfig]
      } else {
        record.children.push(rowConfig)
      }
      console.log('handleAddSecondHead', record)
    }

    // 删除行
    const handleDelete = (event: Event, record: ITableData): void => {
      // console.log('handleDelete--', event, record, state.dataSource)
      const filterTree = (data: ITableData[]) => {
        const newData = data.filter(item => item.id !== record.id)
        console.log('handleDelete--data', newData)
        newData.forEach(item => {
          item.children && (item.children = filterTree(item.children))
        })
        return newData
      }
      state.dataSource = filterTree(state.dataSource)
    }


    const handleExpand = (expanded, record) => {
      // console.log('handleExpand', expanded, record)
    }

    const setFlatTreeData = (BeginOldIndex, beginNewIndex) => {
      const row = state.flatTreeData.splice(BeginOldIndex, 1)[0]
      state.flatTreeData.splice(beginNewIndex, 0, row)
    }

    const initSortable = () => {
      // 获取容器元素
      const tbody = document.querySelector(
        '.table-config-container .ant-table-tbody'
      )
      if (!tbody) return
      state.sortableObj = new Sortable(tbody, {
        handle: '.ant-table-row',
        // draggable: '.drag-box',
        animation: 150,
        nested: true,
        onStart: () => {
          state.flatTreeData = []
          getFlatNode(state.dataSource, state.flatTreeData)
          console.log('onStart', state.flatTreeData)
        },
        onEnd: ({ newIndex, oldIndex }) => {
          const dragRow: any = state.flatTreeData[oldIndex]
          const relatedRow: any = state.flatTreeData[newIndex]
          console.log('old new', oldIndex, newIndex, state.flatTreeData)
          if (dragRow.parentId !== relatedRow.parentId) {
            message.warning('只能同层级内排序')
            reRender(null, 'dataSource')
          } else {
            // 都无children
            if (!dragRow.children && !relatedRow.children) {
              setFlatTreeData(oldIndex, newIndex)
              // const oldData = state.flatTreeData.splice(oldIndex, 1)[0]
              // state.flatTreeData.splice(newIndex, 0, oldData)
            }
            // drag有, relate无
            if (dragRow.children && !relatedRow.children) {
              const oldData = state.flatTreeData.splice(oldIndex, 1)[0]
              state.flatTreeData.splice(newIndex, 0, oldData)
              if (newIndex < oldIndex) {
                // 有子元素的，子元素需要同样跟上来
                const flatChildren = []
                getFlatNode(oldData.children || [], flatChildren)
                if (flatChildren.length > 0) {
                  for (let i = 1, len = flatChildren.length; i <= len; i++) {
                    setFlatTreeData(oldIndex + i, newIndex + i)
                    // const childData = state.flatTreeData.splice(oldIndex + i, 1)[0]
                    // state.flatTreeData.splice(newIndex + i, 0, childData)
                  }
                }
              } else {
                // 有子元素的，子元素需要同样跟下来
                const flatChildren = []
                getFlatNode(oldData.children || [], flatChildren)
                if (flatChildren.length > 0) {
                  for (let i = 1, len = flatChildren.length; i <= len; i++) {
                    setFlatTreeData(oldIndex, newIndex)
                    // const childData = state.flatTreeData.splice(oldIndex, 1)[0]
                    // state.flatTreeData.splice(newIndex, 0, childData)
                  }
                }
              }
            }
            // drag无, relate有
            if (!dragRow.children && relatedRow.children) {
              const oldData = state.flatTreeData.splice(oldIndex, 1)[0]
              state.flatTreeData.splice(newIndex, 0, oldData)
              if (newIndex > oldIndex) {
                // 有子元素的，子元素需要同样跟上来
                const flatChildren = []
                getFlatNode(relatedRow.children || [], flatChildren)
                if (flatChildren.length > 0) {
                  for (let i = 1, len = flatChildren.length; i <= len; i++) {
                    setFlatTreeData(newIndex + i, newIndex + i - 1)
                    // const childData = state.flatTreeData.splice(newIndex + i, 1)[0]
                    // state.flatTreeData.splice(newIndex + i - 1, 0, childData)
                  }
                }
              }
            }
            // drag有, relate有
            if (dragRow.children && relatedRow.children) {
              if (newIndex < oldIndex) {
                const oldData = state.flatTreeData.splice(oldIndex, 1)[0]
                state.flatTreeData.splice(newIndex, 0, oldData)
                // 有子元素的，子元素需要同样跟上来
                const flatChildren = []
                getFlatNode(oldData.children || [], flatChildren)
                if (flatChildren.length > 0) {
                  for (let i = 1, len = flatChildren.length; i <= len; i++) {
                    setFlatTreeData(oldIndex + i, newIndex + i)
                    // const childData = state.flatTreeData.splice(oldIndex + i, 1)[0]
                    // state.flatTreeData.splice(newIndex + i, 0, childData)
                  }
                }
              } else {
                const oldData = state.flatTreeData.splice(oldIndex, 1)[0]
                // relateRow的children数
                const relateFlatChildren = []
                getFlatNode(relatedRow.children || [], relateFlatChildren)
                state.flatTreeData.splice(
                  newIndex + relateFlatChildren.length,
                  0,
                  oldData
                )
                // 有子元素的，子元素需要同样跟下来
                const flatChildren = []
                getFlatNode(oldData.children || [], flatChildren)
                if (flatChildren.length > 0) {
                  for (let i = 1, len = flatChildren.length; i <= len; i++) {
                    const childData = state.flatTreeData.splice(oldIndex, 1)[0]
                    state.flatTreeData.splice(
                      newIndex + relateFlatChildren.length,
                      0,
                      childData
                    )
                  }
                }
              }
            }
            // 重新生成树的数据
            const data = getTreeData(state.flatTreeData, [])
            console.log('state.flatTreeData', state.flatTreeData, data)
            // 页面重新渲染
            reRender(data, 'dataSource')
          }
        },
      })
    }
    
    // 数组扁平化
    const getFlatNode = (nodes, flatList, childrenKey = 'children') => {
      nodes.forEach(node => {
        flatList.push(node)
        if (node[childrenKey] && node[childrenKey].length) {
          getFlatNode(node[childrenKey], flatList)
        }
      })
    }
    
    // 重新生成树结构
    const getTreeData = (nodes, resultList) => {
      const childStack: any[] = []
      let lastNode: any = {}
      nodes.forEach(node => {
        delete node.children
        const pushNode = dataNode => {
          const parentNode = childStack[childStack.length - 1] as any
          if (parentNode) {
            parentNode.children.push(dataNode)
          } else {
            resultList.push(dataNode)
          }
        }
        if (node.level < lastNode.level) {
          childStack.length = node.level - 1
          pushNode(node)
        } else if (node.level === lastNode.level) {
          pushNode(node)
        } else if (node.level > lastNode.level) {
          if (!lastNode.children) {
            lastNode.children = []
          }
          childStack.push(lastNode)
          pushNode(node)
        } else {
          resultList.push(node)
        }
        lastNode = node
      })
      return resultList
    }
    
    // 重新渲染
    const reRender = (data, dataKey) => {
      if (data) {
        state[dataKey] = []
        nextTick(() => {
          state[dataKey] = data
        })
      } else {
        const origin = [].concat(state[dataKey])
        state[dataKey] = []
        nextTick(() => {
          state[dataKey] = origin
        })
      }
    }
    
    const transverseNode = (
      nodes,
      level = 1,
      cb,
      parentNode = null,
      childKey = 'children'
    ) => {
      nodes.forEach(node => {
        if (node[childKey] && node[childKey].length > 0) {
          transverseNode(node[childKey], level + 1, cb, node, childKey)
        }
        cb(node, level, parentNode)
      })
      return nodes
    }
    
    const initDrag = () => {
      state.flatTreeData = []
      transverseNode(state.dataSource, 1, (node, level, parentNode) => {
        node.level = level
        node.parentId = parentNode ? parentNode.id : -1
      })
      
      initSortable()
      // getFlatNode(state.dataSource, state.flatTreeData)
      // console.log('init dataSource-flatTreeData', state.dataSource, state.flatTreeData)
    }

    onUnmounted(() => {
      state.sortableObj = null
    })

    // Modal 确定
    const handleConfirm = () => {
      console.log('confirm-state.dataSource', state.dataSource)
      setProperty(['columns'], state.dataSource)
      state.showDialog = false
    }

    // tableSlots
    const tableSlots = {
      bodyCell: ({ column, record, text, index }) => {
        const templateMap = {
          drag: () => <DragOutlined class="drag-box" />,
          _type: () => (
            <>
              <Select
                size="small"
                style="width: 100%"
                defaultValue={'text'}
                v-model:value={record._type}
                options={
                  [...fieldTypes.map(item => { return { label: item.typeName, value: item.typeValue } })]
                }
              >
              </Select>
              <Input
                v-show={record._type == 'link'}
                size="small"
                style={'margin-top:6px'}
                v-model:value={record._href}
                placeholder="链接字段"
              ></Input>
            </>
          ),
          title: () => (
            <Input
              size="small"
              v-model:value={record.title}
              placeholder="中文名"
            ></Input>
          ),
          dataIndex: () => (
            <Input
              size="small"
              v-model:value={record.dataIndex}
              placeholder="字段名"
            ></Input>
          ),
          width: () => (
            <Input
              size="small"
              v-model:value={record.width}
              placeholder="宽度"
            ></Input>
          ),
          isSummary: () => <Switch size="small" v-model:checked={record.isSummary} />,
          summary: () => (
            <Input
              size="small"
              v-model:value={record.summary}
              placeholder="汇总单位"
            ></Input>
          ),
          fixed: () => <Switch size="small" v-model:checked={record.fixed} />,
          isShow: () => <Switch size="small" v-model:checked={record.isShow} />,
          allowWrapperLine: () => <Switch size="small" v-model:checked={record.allowWrapperLine} />,
          ellipsis: () => <Switch size="small" v-model:checked={record.ellipsis} />,
          extraConfig: () => (
            <>
              {/* process配置 */}
              <div class="config-item" v-show={record._type === 'process'}>
                <div class="label">
                  默认颜色
                  <Tooltip title="默认是纯蓝色渐变、否则是随着值的大小、有不同的区间颜色、用 isProcessDefault 字段判断是否是默认颜色">
                    <QuestionCircleFilled />
                  </Tooltip>
                </div>
                <Checkbox v-model:checked={record.isProcessDefault} />
              </div>
              {/* tag配置 */}
              <div class="config-item" v-show={record._type === 'tag'}>
                <div class="label">
                  是否作为索引
                  <Tooltip title="用tag作为索引、会有作为索引特定的颜色配置、用 isUrgent 字段判断是否为加急">
                    <QuestionCircleFilled />
                  </Tooltip>
                </div>
                <Checkbox v-model:checked={record.isTagIndex} />
              </div>

              {/* 颜色文本 */}
              <div class="config-item" v-show={record._type === 'colorText'}>
                <div class="label">
                  颜色设置
                  <Tooltip title="用 isDelay 字段判断是否为添加红色">
                    <QuestionCircleFilled />
                  </Tooltip>
                </div>
              </div>
              
            </>
          ),
          operate: () => (
            <>
              <Button
                type="link"
                size="small"
                onClick={(event: Event) => handleDelete(event, record)}
              >
                删除
              </Button>
              <Button
                type="link"
                size="small"
                v-show={record.level === 1}
                onClick={(event: Event) =>
                  handleAddSecondHead(event, record, index)
                }
              >
                新增子级
              </Button>
            </>
          ),
        }

        return templateMap[column.dataIndex]?.()
      },
    }

    // 配置Modal
    const SettingTableModal = () => (
      <Modal
        v-model:visible={state.showDialog}
        title="列配置"
        width="90%"
        bodyStyle={{ height: 'auto' }}
        onOk={handleConfirm}
      >
        <div style={{ textAlign: 'right', paddingBottom: '18px' }}>
          <Button type="primary" size="small" onClick={handleAdd}>
            新增
          </Button>
        </div>
        <Table
          id="configTable"
          size="small"
          bordered
          class="table-config-container"
          ref={tableRef}
          rowKey="id"
          columns={columns}
          onExpand={handleExpand}
          dataSource={state.dataSource}
          v-slots={tableSlots}
          pagination={false}
          default-expand-all-rows={true}
        />
      </Modal>
    )

    const renderDomSlot = () => (
      <div class="setting-container">
        {/* 基础配置 */}
        <BasicPropertiesConfig />
        {/* 列配置Modal */}
        <SettingTableModal />
      </div>
    )

    return () => <SettingItemBox title="表格配置" v-slots={renderDomSlot} />
  },
})

import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { defineComponent, reactive, ref, Ref, computed } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Input, Select } from 'ant-design-vue'



export default defineComponent({
  name: 'radioGroupOptionType',
  order: 5,
  setup(props, ctx) {
    const store = useStore()
    const { componentConfig, moveablerRef, mainJson, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'mainJson',
      'selecto'
    ]) as IState
    const state = reactive({

    })


    const handleChange = (type: string, value: any) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: [type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: type,
        path: ['option', type],
        value: componentConfig.value.optionData[type],
      })

      options[type] = value
      targetCharts.setOption(options, false)
    }

    // 边框插槽
    const optionsSlot = () => (
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">选择类型</div>
          <div class="setting-row__content">
            <Select
              size="small"
              onChange={e => handleChange('optionType', e)}
              v-model:value={componentConfig.value.optionData.optionType}
              options={[
                { label: '默认', value: 'default' },
                { label: '按钮', value: 'button' },
              ]}
              placeholder="请选择类型"
            ></Select>
          </div>
        </div>
        <div class="setting-row" v-show={componentConfig.value.optionData.optionType == 'button' ? true : false }>
          <div class="setting-row__label">按钮间隔</div>
          <div class="setting-row__content">
            <Input
              size="small"
              onChange={e => handleChange('justifyType', e)}
              v-model:value={componentConfig.value.optionData.justifyType}
              placeholder="输入按钮间隔"
            ></Input>
          </div>
        </div>
      </div >
    )

    return () => <SettingItemBox title="类型" v-slots={optionsSlot} />
  },
})

import type { IState } from "@/store/modules/editor"
import { userMapState } from "@/store/userMapper"
import { fontFamilyList } from "@/utils/constance"
import { Input, InputNumber, Select } from "ant-design-vue"
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watchEffect } from "vue"
import { useStore } from "vuex"
import { ColorPicker } from 'vue-color-kit'
import SettingItemBox from '../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import type { PropType } from 'vue'
import { IBaseText } from '@/types'
import _ from 'lodash'
export default defineComponent({
  name: 'serverAPI',
  order: 0,
  props: {
    option: {
      type: Object as PropType<IBaseText>,
    },
    staticData: Array
  },
  setup(props, ctx) {
    const instance: any = getCurrentInstance()
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
    })
    const state = reactive({
    })
    const store = useStore()
    const { mainJson, moveablerRef, selecto, componentConfig }: any = userMapState('editor', [
      'mainJson',
      'moveablerRef',
      'selecto',
      'componentConfig'
    ]) as IState

    // const componentConfig = computed(() => {
    //   return mainJson.value.body.children.find(config => config.id === store.state.editor.componentConfig.id)
    // })


    const handleChange = (type: string, value: string | boolean) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: ['serverAPI', type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: 'serverAPI',
        path: ['option', 'serverAPI', type],
        value
      })

      options.serverAPI.url = value
      targetCharts.setOption(options, false)
    }

    

    const apiSlot = () => (
      <>
      <div class="title-box common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">API</div>
          <div class="setting-row__content">
            <Input
              size="small"
              v-model:value={componentConfig.value.optionData.serverAPI.url}
              onChange={value => handleChange('url', value.target.value)}
            />
          </div>
        </div>
      </div>
      </>
    )

    return () => componentConfig.value ? <SettingItemBox title="打印服务" v-slots={apiSlot} /> : null
  }
})

import { d, h, r } from '@/units'
import {
  ref,
  reactive,
  provide,
  onMounted,
  watchEffect,
  watch,
  ComponentPublicInstance,
  toRefs,
  nextTick,
  defineAsyncComponent,
  createVNode
} from 'vue'
import { savePageInfo } from '@/api/dataViews'
import { VAceEditor } from 'vue3-ace-editor'

import 'ace-builds/src-noconflict/ace'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/theme-clouds_midnight'
import 'ace-builds/src-noconflict/ext-language_tools'

import { LoadingOutlined, createFromIconfontCN, BarChartOutlined } from '@ant-design/icons-vue'

import Editor from '@/components/Editor'
import Layout from './views/layout'
import Head from './views/head'
import Modal from './views/modal'
import Button from './views/button'
import SiderLeft from './views/siderLeft'
import Content from './views/content'
import Popover from './views/popover'
import { dyncOptionsMock } from '@/mock/dyncBI/options.js'
import { queryOptionsMock } from '@/mock/queryBI/options.js'
import dyncDatamock from '@/mock/dyncBI/datas.json'
import queryDatamock from '@/mock/queryBI/datas.json'
import { getOptionsMock, getDataMock, getMainMock, changeTheme } from '@/utils/pageEditor'
import { message } from 'ant-design-vue'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import './index.less'
import _ from 'lodash'

import SettingPane from './views/settingPane'
import API from '@/api'
import { siderLeftTab, biType, DYNC_BI } from '@/utils/const'
import { getQueryVariable } from '@/utils'
import { driversOpen } from '@/utils/driver'
import { publishPage } from '@/api/dataViews'
const MyIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_3075146_xr94zw39xdb.js', // 在 iconfont.cn 上生成
})

import DataSourceManage from './components/DataSourceManage/index.vue'
import CreatePageModal from '@/pages/page-editor/components/CreatePageModal/index.vue'

import darkJson from '../../../public/dark.json'
import defaultJson from '../../../public/default.json'
import { LargeScreenDesignPublishStatus } from '@/enums'

export default d({
  name: 'pageEditor',
  components: {
    BarChartOutlined,
    VAceEditor
  },
  setup: (props, context) => {
    const router = useRouter()
    const store = useStore()
    const {
      mainJson
    }: any = userMapState(
      'editor',
      ['mainJson']
    ) as IState
    type ChildPublicInstance = { init(): void }
    const editorEL = ref<ComponentPublicInstance<{}, ChildPublicInstance>>(null)
    // const dyncOptions = dyncOptionsMock
    // const qyeryOptionsmock = queryOptionsMock
    const state = reactive({
      mainJsonTemp: {
        pageID: '',
        pageName: '',
        pageEname: '',
        pageDataSource: '',
        isDark: false,
        body: {
          type: '',
          style: {
            width: 1024,
            height: 900,
            background: '',
            backgroundImage: '',
            backgroundSize: '',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'scroll',
            backgroundColor: '',
            backgroundOpacity: '',
            backgroundBlendMode: '',
            backgroundClip: '',
            backgroundOrigin: ''
          },
          children: []
        }
      },
      optionsMock: [],
      datasMock: [],
      activeKey: '0',
      sHeight: 0,
      sWidth: 0,
      sTop: 0,
      sLeft: 0,
      sZindex: '0',
      sBackground: '',
      cBackground: '',
      sPageHeight: 0,
      sPageWidth: 0,
      tempObject: '',
      tempData: '',
      showSizeRuler: true,
      showGuideLine: true,
      loading: true,
      showModal: false,
      switchDirections: true,
      isProfessional: false,
      isDark: false,
      zoom: ref(1),
      zoomMinusDisabled: false,
      zoomPlusDisabled: true,
      zoomBox: true,

      getSiderList: [],
      mainJsonConfig: {
        lang: 'javascript', //解析 json,javascript
        theme: 'clouds_midnight',
        wrap: 'free',
        options: {
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          readOnly: true,
          wrap: true,
          tabSize: 2,
          showPrintMargin: false,
          fontSize: 12,
          useWorker: false,
          highlightSelectedWord: true,
        }
      },
      showDataSource: false,
      showPageConfig: false,
      getComponentsList: [],

      showPublishDialog: false,
      publishRemark: ''
    })


    const setProperty = (values) => {
      for (let p in values) {
        document.documentElement.style.setProperty(`--${p}`, values[p])
      }
    }
    // const changeTheme = () => {
    //   (window as any)?.less?.modifyVars(
    //     state.isDark ? {
    //       ...darkJson,
    //     } : {
    //       ...defaultJson,
    //       '@layout-header-background': '#fff',
    //     }
    //   )
    //   state.isDark ?
    //     setProperty({
    //       'canvasBackground': '#222',
    //       'rulersBackground': '#2a2a2a',
    //       'viewBackground': '#333',
    //       'siderLeftBackground': '#2a2a2a',
    //       'siderLeftBackgroundHover': 'rgba(0,12,23,0.65)',
    //       'tableSumBackground': 'transparent',
    //       'tableFixBackground': 'rgba(0,0,0,0.25)',
    //     })
    //     :
    //     setProperty({
    //       'canvasBackground': '#ddd',
    //       'rulersBackground': '#fafafa',
    //       'viewBackground': '#fff',
    //       'siderLeftBackground': '#ccc',
    //       'siderLeftBackgroundHover': 'rgba(13,1,3,0.35)',
    //       'tableSumBackground': 'transparent',
    //       'tableFixBackground': 'rgba(255,255,255,0.45)',
    //     })
    //   store.dispatch('editor/setDarkMode', state.isDark)
    // }

    const indicator = h(LoadingOutlined, {
      class: 'Loading-Outlined',
      spin: true,
    })

    const getGroupId = (pageId) => {
      if (!pageId) return
      API.pageManage.getGroupId(pageId).then((res: any) => {
        sessionStorage['groupId'] = res.data?.id
      })
    }

    let pageID: string = getQueryVariable('pageid') || sessionStorage.getItem('pageId')
    const token: string = getQueryVariable('token')
    const version: string = getQueryVariable('version')
    token && sessionStorage.setItem('token', `Bearer ${token}`)
    pageID && sessionStorage.setItem('pageId', pageID)
    getGroupId(pageID)

    const savePage = async () => {
      // const mainjson = localStorage.mainjson ? JSON.parse(localStorage.mainjson) : {}
      const mainjson = _.cloneDeep(mainJson.value) || {}
      // const { pageID } = mainjson
      mainjson.pageID = pageID
      const obj = {
        jsonConfig: JSON.stringify(mainjson)
      }
      savePageInfo(pageID, obj).then(res => {
        message.success('保存成功')
      })
    }
    const openSubmitDialog = async () => {
      // savePage() // 暂时避免
      // const _id = pageID || getQueryVariable('pageid') || sessionStorage.getItem('pageId')
      // publishPage(_id).then((res) => {
      //   message.success('发布成功')
      // })
      state.showPublishDialog = true
    }

    const handlePublishPage = () => {
      const _id = pageID || getQueryVariable('pageid') || sessionStorage.getItem('pageId')
      if (!state.publishRemark) {
        return message.warning('备注不能为空')
      }
      publishPage(_id, {
        remark: state.publishRemark
      }).then((res) => {
        message.success('发布成功')
        state.showPublishDialog = false
      })
    }

    // mock数据  api接口数据
    const ENV = 'mock'
    const data = {
      mock: () => {
        state.getSiderList = state.optionsMock
        debugger
        console.log('-----mock数据--------')
      },
      api: () => {
        API.pageManage.getPageList().then((res: any) => {
          debugger
          state.getSiderList = res.data.map((item, index) => {
            return {
              ...item,
              iconFont: siderLeftTab.find((val, idx) => index == idx).iconFont
            }
          })
          console.log('---------查询组件列表------', state.getSiderList)
        })
      }
    }

    //初始化场景
    const initScene = (pageType: string) => {
      state.optionsMock = getOptionsMock(biType[pageType])
      state.datasMock = getDataMock(biType[pageType])
      state.mainJsonTemp = getMainMock(pageType) as any
      state.isDark = state.mainJsonTemp.isDark
      data[ENV]()
    }

    //初始化页面配置信息
    const initPageConfig = (isFirst = false) => {
      if (pageID) {
        const params: Record<string, any> = {
          isPublish: LargeScreenDesignPublishStatus.Draft
        }
        version && (params.version = version)
        API.pageManage.getPageInfo(pageID, token, params).then((res: any) => {
          initScene(res.data.pageType)
          console.log(state.mainJsonTemp, '---------------getPageInfo------------')
          if (res.data.jsonConfig == '') {
            state.mainJsonTemp.pageID = res.data.id
            state.mainJsonTemp.pageName = res.data.pageName
            state.mainJsonTemp.pageEname = res.data.pageEname
            state.mainJsonTemp.pageDataSource = res.data.dataResourceId
            Object.keys(state.mainJsonTemp).forEach((key: string) => {
              mainJson.value[key] = state.mainJsonTemp[key]
            })
            // localStorage.setItem('mainjson', JSON.stringify(state.mainJsonTemp))
            console.log('------------------editorEL-----------------', editorEL.value)
            editorEL.value?.init()
            setTimeout(() => {
              changeTheme(state.isDark)
              state.loading = false
            }, 1000)
          } else {
            Object.keys(JSON.parse(res.data.jsonConfig)).forEach((key: string) => {
              mainJson.value[key] = JSON.parse(res.data.jsonConfig)[key]
            })
            mainJson.value.pageName = res.data.pageName
            mainJson.value.pageEname = res.data.pageEname
            mainJson.value.pageDataSource = res.data.dataResourceId
            // localStorage.setItem('mainjson', JSON.stringify(JSON.parse(res.data.jsonConfig)))

            setTimeout(() => {
              state.isDark = JSON.parse(res.data.jsonConfig).isDark
              changeTheme(state.isDark)
              editorEL.value?.init()
              state.loading = false
            }, 2000)
          }
          isFirst && driversOpen()
        }).catch(err => {
          // mainJson.value.pageID = ''
          // mainJson.value.pageName = '未命名页面'
          // state.loading = false
        })
      } else {
        const dync_bi = '2'
        const _pageType = getQueryVariable('pageType') || dync_bi
        initScene(_pageType)
        changeTheme(state.isDark)
        state.loading = false
        state.showPageConfig = true
      }
    }
    initPageConfig()

    const getComponentsList = () => {
      let newArr = []
      const files = require.context(
        '../../componentsTemplate/',
        true,
        /^.*\.(tsx|vue|ts|js)$/
      )
      files.keys().forEach(file => {
        let path = file.replace('./', '')
        let res: any = require('@/componentsTemplate/' + path)
        newArr.push(res.option)
      })
      state.getComponentsList = newArr
    }
    getComponentsList()

    // 获取组件列表
    const getComponents = (data, type) => {
      let options = {}
      let res: any = require(`@/componentsTemplate/${type}.js`)
      res.option.type = type
      if (typeof (res.option) == 'function') {
        options = res.option(JSON.parse(data).source)
      } else {
        options = res.option
      }
      return options
    }

    const dragComponent = (e, item, type, data) => {
      // const componentsList = _.cloneDeep(getComponents(data))
      // const option = componentsList.find(v => v.type === type)
      const option = _.cloneDeep(getComponents(data, type))
      item.option = Object.assign(option, item.option)
      console.log(item, option, 'eDragComponent');
      (window as any).ComponentOption = item;
      (window as any).ComponentData = data
      // e.dataTransfer.setData('item', JSON.stringify(item))
      // console.log('####################', item.option)
      // e.dataTransfer.setData('data', data)
    }
    const imgmock='http://paasengine-api.test.huansi.net/file/1436219781039656960/1588348607723937792/300941369697329152.png'
    const VNode = {
      render() {
        return h(r('a-spin'), {
          spinning: state.loading,
          delay: 0,
          size: 'large',
          indicator: indicator,
        }, () => [
          // 发布弹窗
          h(Modal, {
            visible: state.showPublishDialog,
            title: '发布',
            cancelText: '取消',
            okText: '发布',
            onOk: handlePublishPage,
            onCancel: () => {
              state.showPublishDialog = false
            }
          }, () => [
            h(r('a-form'), {
              layout: 'inline',
              style: { width: '50%'},
            }, () => [
              h(r('a-form-item'), {
                label: '备注'
              }, () => h(r('a-input'),
                  {
                    modalValue: state.publishRemark,
                    placeholder: '请输入备注',
                    onInput: (event) => {
                      // console.log('备注', event)
                      state.publishRemark = event.target.value
                    }
                  }
                )
              )
            ])
          ]),

          // 导入导入
          h(Modal, {
            visible: state.showModal,
            centered: true,
            width: '90%',
            height: '90%',
            ref: 'editorModal',
            footer: null,
            title: '导入导出',
            onOk: () => {
              state.showModal = false
            },
            onCancel: () => {
              state.showModal = false
            }
          },
            () => [h(VAceEditor, {
              value: JSON.stringify(mainJson.value, null, 2),
              lang: state.mainJsonConfig.lang,
              theme: state.isDark ? state.mainJsonConfig.theme : 'chrome',
              options: state.mainJsonConfig.options,
              style: {
                height: '80vh',
                width: '100%',
              }
            }),
            h(r('a-upload'), {
              accept: '.json,.js,.ts,.text',
              beforeUpload: (file, fileList) => {
                return new Promise((resolve, reject) => {
                  const reader: any = new FileReader()
                  reader.readAsText(file)
                  reader.onload = (e) => {
                    console.log('e----------------', e)
                    const result = JSON.parse(e.target.result)
                    result.pageID = mainJson.value.pageID
                    result.pageName = mainJson.value.pageName
                    console.log(result)
                    store.commit('editor/SET_MAIN_JSON', result)
                    store.dispatch('editor/saveMainJson', result)

                    //初始化编辑器
                    editorEL.value?.init()
                  }
                })
              },
              showUploadList: false,
              onChange: (info) => {
                if (info.file.status !== 'uploading') {
                  console.log(info)
                }
              }
            },
              h(Button, {
                type: 'primary',
                style: {
                  margin: ' 10px 10px 0 0'
                },
              }, '导入')
            ),
            h(Button, {
              type: 'primary',
              style: {
                margin: ' 10px 10px 0 0'
              },
              onClick: () => {
                const outJSON = store.getters.getEditor.mainJson
                console.log(outJSON)
                const blob = new Blob([JSON.stringify(outJSON)], { type: 'application/json' })
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url

                a.download = `${outJSON.pageName}-JSON.json`
                document.documentElement.appendChild(a)
                a.click()
                document.documentElement.removeChild(a)

              }
            }, '导出'),
            h(Button, {
              style: {
                margin: ' 10px 10px 0 0'
              },
              onClick: () => {
                state.showModal = false
              }
            }, '关闭')
            ]
          ),
          h(CreatePageModal, {
            visible: state.showPageConfig,
            onClose: () => state.showPageConfig = false,
            onCreated: (value) => {
              pageID = value.pageId
              initPageConfig(true)
              getGroupId(pageID)
            },
            onSaved: (value) => {
              mainJson.value.pageName = value.pageName
              mainJson.value.pageEname = value.pageEname
              mainJson.value.pageDataSource = value.dataResourceId
            }
          }),
          h(DataSourceManage, {
            visible: state.showDataSource,
            pageId: pageID,
            onClose: () => state.showDataSource = false,
            onConfirm: () => {
              state.showDataSource = false
            }
          }),
          h('div', {
            style: {
              position: 'absolute',
              bottom: '30px',
              right: '330px',
              zIndex: '999',
            },
            // id: 'second'
          },
            h(Popover, {
              placement: 'topRight',
              title: '新手指引'
            }, () => '欢迎使用，请按照提示操作')
          ),
          h(Head, () => [
            h(r('a-form'), { layout: 'inline', style: { width: '50%' }, labelAlign: 'left' }, () => [
              h(r('a-form-item'), () => `动态BI - ${state.isProfessional ? '(专业版)' : '(普通版)'}`),
              h(r('a-form-item'),
                {
                  label: '标尺',
                },
                () => h(r('a-switch'),
                  {
                    size: 'small',
                    checked: state.showSizeRuler,
                    onChange: (e) => {
                      state.showSizeRuler = e
                    }
                  }
                )
              ),
              h(r('a-form-item'),
                {
                  label: '辅助线',
                },
                () => h(r('a-switch'),
                  {
                    size: 'small',
                    checked: state.showGuideLine,
                    onChange: (e) => {
                      state.showGuideLine = e
                    }
                  }
                )
              ), h(r('a-form-item'),
                {
                  label: '自动吸附',
                },
                () => h(r('a-switch'),
                  {
                    size: 'small',
                    checked: state.switchDirections,
                    onChange: (e) => {
                      state.switchDirections = e
                    }
                  }
                )
              ),
              h(r('a-form-item'),
                {
                  label: '专业版',
                },
                () => h(r('a-switch'),
                  {
                    size: 'small',
                    checked: state.isProfessional,
                    onChange: (e) => {
                      state.isProfessional = e
                    }
                  }
                )
              ),
              h(r('a-form-item'),
                {
                  label: '黑暗模式',
                },
                () => h(r('a-switch'),
                  {
                    size: 'small',
                    checked: state.isDark,
                    onChange: (e) => {
                      state.isDark = e
                      changeTheme(state.isDark)
                    }
                  }
                )
              ),
            ]),

            h(r('a-form'), {
              layout: 'inline',
              style: { width: '50%', flexFlow: 'row-reverse' },
              labelAlign: 'right'
            }, () => [

              h(r('a-form-item'), {},
                () => h(r('a-button'),
                  {
                    onClick: () => openSubmitDialog()
                  }, () => '发布'
                )
              ),
              h(r('a-form-item'), {},
                () => h(r('a-button'),
                  {
                    type: 'primary',
                    onClick: () => savePage()
                  }, () => '保存'
                )
              ),
              h(r('a-form-item'), {},
                () => h(r('a-button'),
                  {
                    type: 'primary',
                    onClick: () => state.showDataSource = true
                  }, () => '数据管理'
                )
              ),
              h(r('a-form-item'), {},
                () => h(r('a-button'),
                  {
                    type: 'primary',
                    onClick: () => state.showPageConfig = true
                  }, () => '页面管理'
                )
              ),
              h(r('a-form-item'), {},
                () => h(r('a-button'),
                  {
                    type: 'primary',
                    ghost: true,
                    onClick: () => {
                      const { href } = router.resolve({
                        name: 'preview',
                        query: {
                          pageid: pageID,
                          token: token,
                        }
                      })
                      window.open(href, 'target', '')
                    }
                  }, () => '预览'
                )
              ),
              h(r('a-form-item'), {
                style: {
                  paddingRight: '30px'
                }
              },
                () => h(r('a-button'),
                  {
                    onClick: () => state.showModal = true
                  }, () => '导入导出'
                )
              ),
            ])
          ]),
          h(Layout, () => [
            h(SiderLeft, {
              class: 'siderLeft',
            }, () => h('div', {
              // id: 'first'
            },
              [
                state.getSiderList.map((item, index) => h('div', {
                  class: 'left-Template left-Template-hover',
                },
                  [
                    h(MyIcon, { type: item.unitIconFont, style: { fontSize: '30px' } }),
                    h('div', {
                      class: 'templateHover'
                    }, [item.list.map((val, idx) => h('div', {
                      class: 'templateItem',
                      ondragstart: event => dragComponent(event, val, val.type, JSON.stringify(state.datasMock.find(data => val.id == data.id)?.dataset)),
                      draggable: true
                    }, h('div', { class: 'templateItemSon' }, [h('img', { src:imgmock|| val.image }), h('div', {}, val.name)])))])
                  ]
                ))
              ]
            )),
            h(Content, {
              style: {
                overflow: 'hidden'
              }
            },
              () => h(Editor, {
                // style:{
                //   width:'100wv',
                // },
                ref: editorEL,
                sizeRuler: state.showSizeRuler,
                guideLine: state.showGuideLine,
                isDark: state.isDark,
                switchDirections: state.switchDirections
              })
            ),
            h(SettingPane, {
              isProfessional: state.isProfessional
            }),
          ]
          )
        ]
        )
      }
    }
    return () => h(VNode)
  }
})

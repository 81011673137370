import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import { Input } from 'ant-design-vue'
import { typeList } from '../data'
import useSetAttribute from '@/hooks/useSetAttribute'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import SettingItemRow from '../../settingItemRow'

export default defineComponent({
  name: 'anotherName',
  order: 1,
  setup() {
    const store = useStore()
    const { componentConfig }: any = userMapState('editor', ['componentConfig',]) as IState

    const handleChangeContent = (value) => {
      // const values = value.replace(/[^\a-\z\A-\Z\d\_]/g,"");
      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['anotherName'],
        value: value
      })
      store.commit('editor/SET_ANOTHER_NAME', value)
    }

    return () => (
      <SettingItemRow title={'数据源'}>
        <Input
          style={{fontSize:'14px!important'}}
          size="small"
          onChange={e => handleChangeContent(e.target.value)}
          v-model:value={componentConfig.value.anotherName}
          placeholder="请输入数据源内容"
        />
      </SettingItemRow>
    )
  }
})

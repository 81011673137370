import { defineComponent, ref } from 'vue'
// import DataApiConfig from './OptionsConfig'
import OptionsConfig from './optionsConfig'
import ActionsConfig from './actionsConfig'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import { Tabs } from 'ant-design-vue'


export default defineComponent({
  name: 'dataTabPane',
  setup(props) {
    const activeTab = ref('style')
    const {
      componentConfig,
    }: any = userMapState(
      'editor',
      ['componentConfig']
    ) as IState

    return () => (
      <>
        <Tabs
          size="small"
          type="card"
        // v-model:activeKey={activeTab.value}
        // onChange={handleChange}
        >
          {/* {
          componentConfig.value.id ? <OptionsConfig/> : ''
        } */}
          <Tabs.TabPane key="option" tab="options">
            <OptionsConfig/>
          </Tabs.TabPane>
          <Tabs.TabPane key="action" tab="actions & events">
            <ActionsConfig/>
          </Tabs.TabPane>
        </Tabs>
      </>
    )
  },
})

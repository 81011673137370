export const option = {
  label: '标题',
  options: [],
  dataset: {
    source: []
  },
  size: 'middle',
  optionType: 'default',
  justifyType: 'inherit',
  series: [
    {
      type: 'radio'
    }
  ]
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4479330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-page-db" }
const _hoisted_2 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    afterClose: _ctx.afterClose,
    maskClosable: false,
    onCancel: _ctx.handleCancel,
    onOk: _ctx.handleSave,
    cancelText: "取消",
    class: "create-page-modal",
    destroyOnClose: "",
    title: "页面配置 ",
    visible: _ctx.showModal,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showModal) = $event)),
    width: "60%"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_button, { onClick: _ctx.handleCancel }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          onClick: _ctx.handleSave,
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("保存")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        "label-col": _ctx.labelCol,
        model: _ctx.formData,
        rules: _ctx.rules,
        ref: "formRef"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: "页面名称",
            name: "pageName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                maxlength: 30,
                placeholder: "请输入页面名称",
                value: _ctx.formData.pageName,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.pageName) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "PageCode",
            name: "pageEname"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                disabled: _ctx.hasPageId,
                maxlength: 30,
                placeholder: "请输入PageName",
                value: _ctx.formData.pageEname,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.pageEname) = $event))
              }, null, 8, ["disabled", "value"])
            ]),
            _: 1
          }),
          false
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 0,
                label: "页面标签",
                name: "tagName"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_auto_complete, {
                    options: _ctx.tagsList,
                    onSearch: _ctx.onSearchTag,
                    onSelect: _ctx.onSelectTag,
                    placeholder: "请选择或输入页面标签",
                    value: _ctx.formData.tagName,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.tagName) = $event))
                  }, null, 8, ["options", "onSearch", "onSelect", "value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_a_form_item, { label: "页面数据源" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_a_switch, {
                  checked: _ctx.formData.isPageDataSource,
                  "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.isPageDataSource) = $event))
                }, null, 8, ["checked"]),
                (_ctx.formData.isPageDataSource)
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 0,
                      options: _ctx.dataSourceList,
                      placeholder: "请选择页面数据源",
                      style: {"margin-top":"16px"},
                      value: _ctx.formData.dataResourceId,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.dataResourceId) = $event))
                    }, null, 8, ["options", "value"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["label-col", "model", "rules"])
    ]),
    _: 1
  }, 8, ["afterClose", "onCancel", "onOk", "visible"]))
}
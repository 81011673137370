export const option = {
  backgroundColor: 'transparent',
  legend: [{ show: true }],
  xAxis: [{
    show: false,
    type: 'category',
    splitArea: {
      show: false
    },
  }],
  yAxis: [{
    type: 'category',
    splitArea: {
      show: false
    },
    axisLabel: {
      // formatter: function(data) {
      //   if(data.indexOf('-') > -1) {
      //      return ''
      //   } else {
      //     return data ? data+'区' : ''
      //   }
      // }
    }
  }],
  visualMap: {
    show: false,
    min: 0.01,
    max: 1,
    calculable: true,
    orient: 'horizontal',
    left: 'center',
    bottom: '15%',
    color:['#073e4c','#0d4852','#135258','#195c5e','#1f6663','#257069','#2b7a6e','#328474','#388d79','#3f977f','#45a084'],
  },
  grid: [
    {
    }
  ],
  series: [
    {
      name: '',
      type: 'heatmap',
      // data: data,
      itemStyle: {
        borderWidth:1,
        borderType:'solid',
        borderColor:'#fff',
      },
      // label: {
      //       normal: {
      //           fontSize:20,
      //           show: true,
      //           formatter: function(data) {
      //             console.log('params',data )
      //             let _this = data.value[1] ? data.value[1].replaceAll('-','')+data.value[2]+'\r\n'+(data.value[3]*100).toFixed(2)  + '%'
      //               :
      //               (data.value[3]*100).toFixed(2)  + '%'
      //             return _this
      //           }
      //       }
      //   },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ],
  label: {},
  title: [{}],
  resize: [false,false]
}

import { r, d, h } from "@/units"
import "./index.less"

export default d({
  name: 'pageEditorLayoutHeader',
  setup: (props, context) => {
    const VNode = {
      render() {
        return h(
          r("a-layout-header"),
          {
            "class": "editorHeader"
          },
          () => context.slots.default && context.slots.default()
        )
      }
    }
    return () => h(VNode)
  }
})
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { defineComponent, reactive, ref, Ref, computed } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Button, Table, Input } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'

type Key = string | number;

interface DataItem {
  key: Key;
  check: boolean;
  label: string;
  value: string;
}

export default defineComponent({
  name: 'formOptions',
  order: 6,
  setup(props, ctx) {
    const store = useStore()
    const { componentConfig, moveablerRef, mainJson, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'mainJson',
      'selecto'
    ]) as IState
    const state = reactive<{
      selectedRowKeys: Key[];
    }>({
      selectedRowKeys: []
    });

    //是否有选中的数据
    const hasSelected = computed(() => {
      return componentConfig.value.id
    })


    const coverData = (data: any) => {
      if (!data || !data[0]) return []
      let CData = []
      for (let i = 0; i < data[1].length; i++) {
        let obj = {
          "key": i,
          "label": data[0][i],
          "value": data[1][i],
          "check": data[2] ? data[2][i] : false
        }
        CData.push(obj)
      }
      return CData
    }

    const saveMockData = (data) => {
      const { staticData } = data
      // const { targetCharts, options } = useMoveableTarget(selecto)
      store.dispatch('editor/setMainJsonAttribute', {
        id: hasSelected.value,
        path: ['staticData'],
        value: staticData
      })
    }

    const unCoverData = (data: any) => {
      if (!data) return
      let CData = [[], [], []]
      for (let i = 0; i < data.length; i++) {
        CData[0].push(data[i].label)
        CData[1].push(data[i].value)
        CData[2].push(data[i].check)
      }
      let tempSource = { "staticData": { "source": CData } }
      saveMockData(tempSource)
      return CData
    }

    const getData = coverData(useMoveableTarget(selecto)?.targetCharts.proxy.staticData.source)
    const dataSource: Ref<DataItem[]> = ref(getData)
    // console.log(dataSource.value)
    // console.log(useMoveableTarget(selecto).targetCharts.ctx.staticData)
    dataSource.value.forEach((item,index) => {
      if(item.check) state.selectedRowKeys.push(index)
    })
    const handleAdd = () => {
      let newData = {
        key: dataSource.value.length,
        check: false,
        label: '',
        value: ''
      };
      dataSource.value.push(newData);
    }

    const handleEdit = (type: string, value: string | number, index: number) => {
      dataSource.value[index][type] = value
      console.log(dataSource.value)
      useMoveableTarget(selecto).targetCharts.proxy.staticData.source = unCoverData(dataSource.value)
    }

    const onDelete = (key: number) => {
      // dataSource.value = dataSource.value.filter((item) => item.key !== key);
      dataSource.value.splice(key,1)

      console.log(dataSource.value)
      useMoveableTarget(selecto).targetCharts.proxy.staticData.source = unCoverData(dataSource.value)

    }

    const onSelectChange = (selectedRowKeys: Key[]) => {
      state.selectedRowKeys = selectedRowKeys;
      for(let i in dataSource.value) {dataSource.value[i].check = false }
      state.selectedRowKeys.forEach((item) => {
        let _item = dataSource.value.find((items) => items.key == item)
        _item.check = true
      })
      useMoveableTarget(selecto).targetCharts.proxy.staticData.source = unCoverData(dataSource.value)
    };

    // 边框插槽
    const optionsSlot = () => (
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__content">
            <Button size='small' class="editable-add-btn" style="margin-bottom: 8px;" onClick={handleAdd}>添加</Button>
            <Table
              row-selection={{
                selectedRowKeys: state.selectedRowKeys,
                onChange: onSelectChange,
                hideDefaultSelections: true,
                type: 'radio'
              }}
              size='small'
              bordered
              pagination={false}
              dataSource={dataSource.value}
              v-slots={{
                bodyCell: ({ column, record, index }) => {
                  let { label, value, key } = record
                  return (
                    Object.keys(record).includes(column.key) ? <div class="setting-row__content">
                      {
                        column.key !== 'label' ? <Input
                          size="small"
                          onChange={e => handleEdit('value', e.target.value, index)}
                          v-model:value={value}
                        /> :
                          <Input
                            size="small"
                            onChange={e => handleEdit('label', e.target.value, index)}
                            v-model:value={label}
                          />
                      }
                    </div> :
                      <a onClick={()=>onDelete(index)}><CloseCircleOutlined style="color:red" /></a>
                  )
                }
              }}
              columns={[
                {
                  title: '选项',
                  dataIndex: 'label',
                  key: 'label',
                },
                {
                  title: '值',
                  dataIndex: 'value',
                  key: 'value',
                },
                {
                  title: '',
                  key: 'delete',
                },
              ]}>
            </Table>
          </div>
        </div>
      </div >
    )

    return () => <SettingItemBox title="选项" v-slots={optionsSlot} />
  },
})

/**
 * @description 动画翻牌器 配置描述
 */
export const option = {
  dataset: {
    // todo 数据返回在source中
    source: []
  },
  info: true,
  initValue: 0,
  updateValue: 0,
  // flipperLength: 4,
  width: 65,
  height: 112,
  fontSize: 80,
}

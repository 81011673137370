// export const option = {
//   backgroundColor: 'transparent',
//   legend: [{ show: true }],
//   series: [
//     {
//       legendHoverLink: false,
//       grid: [
//         {
//           x: '0',
//           y: '0',
//           y2: '0',
//           x2: '0'
//         }
//       ],
//       legend: {
//         selectedMode: false
//       },
//       itemStyle: {},
//       label: {
//         show: true,
//         formatter: '{b}: {@[1]}({d}%)'
//       },
//       type: 'pie',
//       radius: ['0%', '70%']
//     }
//   ],
//   label: {
//     show: true
//   },
//   title: [{}]
// }
export const option = {
type: 'chart-pie3D',
    backgroundColor: 'transparent',
    legend: {
      show: false
    },
    xAxis3D: {
      min: -1,
      max: 1
    },
    yAxis3D: {
      min: -1,
      max: 1
    },
    zAxis3D: {
      min: -1,
      max: 1
    },
    grid3D: {
      show: false,
      boxHeight: 10,
      //top: '30%',
      bottom: '50%',
      viewControl: {
        distance: 300,
        alpha: 25,
        beta: 45
      }
    }
  }
export const option = {
  label: '标题',
  options: [],
  size: 'middle',
  dataset: {
    source: []
  },
  series: [
    {
      type: 'checkbox'
    }
  ]
}

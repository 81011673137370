export const option = {
  backgroundColor: 'transparent',
  legend: [{ show: true }],
  yAxis: [{ type: 'value' }],
  xAxis: [{ type: 'category' }],
  grid: [
    {
      top: '15%',
      left: '20%',
      bottom: '15%',
      right: '15%'
    }
  ],
  series: [
    {
      itemStyle: {
        color: {
          x: '0',
          y: '0',
          y2: '1',
          global: false,
          x2: '0',
          colorStops: [],
          type: 'linear'
        }
      },
      type: 'bar'
    }
  ],
  label: {},
  title: [{}],
  resize: [false,false]
}

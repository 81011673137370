import useMoveableTarget from '@/hooks/useMoveableTarget'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { InputNumber } from 'ant-design-vue'
import { ColorPicker } from 'vue-color-kit'
import { defineComponent, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import _ from 'lodash'

export default defineComponent({
  name: 'NumberLength',
  order: 5,
  setup(props) {
    const store = useStore()
    const { componentConfig, moveablerRef, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto'
    ]) as IState

    const state = reactive({
      color: '',
      colorPickerShow: 'none'
    })

    const defaultValue = 7

    watchEffect(() => {
      state.color = componentConfig.value?.option?.generalTitle?.color
    })


    const handleChangeColor = (type: string, value: string | boolean) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: ['generalTitle', type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: 'generalTitle',
        path: ['option', 'generalTitle', 'color'],
        value
      })
      options.generalTitle.color = value
      targetCharts.setOption(options, false)
    }

    const handleChange = (type: string, value: any) => {
      if (!value) value = defaultValue
      const propKeyMap = {
        numberLength: ['numberLength'],
        color: ['generalTitle', 'color'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })

      const { targetCharts, options } = useMoveableTarget(selecto)
      options.numberLength = componentConfig.value.optionData.numberLength
      targetCharts.setOption(options, false)
    }

    const changeColor = _.debounce(handleChangeColor, 1000)

    const numberLengthSlot = () => (
      <>
        <div class="setting-row">
          <div class="setting-row__label">值长度</div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              min={1}
              max={20}
              precision={0}
              defaultValue={defaultValue}
              v-model:value={componentConfig.value.optionData.numberLength}
              onChange={value => handleChange('numberLength', value)}
            />

          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__content">
            <input type="button" class="colorBtn"
              style={{ backgroundColor: componentConfig.value.optionData?.generalTitle.color }}
              onClick={
                (e) => {
                  state.colorPickerShow = 'block'
                }
              }
              onBlur={
                (e: any) => {
                  // color-picker-box 内元素不触发
                  if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                    if (e.relatedTarget.tagName == 'INPUT') {
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener(
                        'blur',
                        (el: any) => {
                          setTimeout(() => state.colorPickerShow = 'none', 100)
                        }
                      )
                    } else {
                      e.target.focus()
                    }
                    return
                  } else {
                    setTimeout(() => state.colorPickerShow = 'none', 100)
                  }
                }
              }
            ></input>
            <ColorPicker
              style={{ display: state.colorPickerShow }}
              theme="dark"
              suckerCanvas="null"
              suckerArea={[]}
              color={componentConfig.value.option?.generalTitle?.color}
              onChangeColor={(value) => {
                changeColor('color', value.hex)
                // state.colorPickerShow = 'none'
              }}
              {...(props as any)}
            />
          </div>
        </div>
      </>
    )
    return () => componentConfig.value ? <SettingItemBox title="值长度" v-slots={numberLengthSlot} /> : null
  },
})

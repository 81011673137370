export enum KeyCode {
  BlankSpace = 32,
  N = 78,
  Backspace = 8,
  Delete = 46,
  Ctrl = 18,
  Command = 224,
  C = 67,
  V = 86,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40
}
export const option = {
  dataset: {
    source: []
  },
  generalTitle: {
    fontSize:"",
    fontFamily:"",
    fontAlign:"",
    fontWeight:"",
    fontStyle:"",
    color:""
  }
}

import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { Switch, InputNumber, Select } from 'ant-design-vue'
import { defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'

import { fontFamilyList } from '@/utils/constance'
import { ColorPicker } from 'vue-color-kit'
import * as echarts from 'echarts'
import { isDisabled } from '@/utils'
import _ from 'lodash'

import useMoveableTarget from '@/hooks/useMoveableTarget'

export default defineComponent({
  name: 'Legend',
  order: 0,
  setup(props, ctx) {
    const store = useStore()
    const {
      componentConfig,
      moveablerRef,
      selecto
    }: any = userMapState(
      'editor',
      ['componentConfig', 'moveablerRef','selecto']
    ) as IState

    const state = reactive({
      colorPickerShow: false
    })

    // 设置边框
    const handleChange = (type, value) => {

      const propKeyMap = {
        show: ['legend', '0', 'show'],
        fontSize: ['legend', '0', 'textStyle', 'fontSize'],
        fontFamily: ['legend', '0', 'textStyle', 'fontFamily'],
        color: ['legend', '0', 'textStyle', 'color'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })


      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })
      const { targetCharts, options } = useMoveableTarget(selecto)
      type == 'show' ? options.legend[0][type] = value : options.legend[0]['textStyle'][type] = value
      options.legend[0][type] = value
      targetCharts.setOption(options,false)
    }
    const changeColor = _.debounce(handleChange, 1000)
    const legendSlot = () => (
      <>
        <div class="setting-row">
          <div class="setting-row__label">图例显示</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.legend[0].show}
              onChange={value => handleChange('show', value)} />
          </div>
        </div>
        <div class="setting-row color-picker-box">
          <div class="setting-row__label">字体颜色</div>
          <div class="setting-row__content">
            <input type="button" class="colorBtn"
              style={{ backgroundColor: componentConfig.value.optionData.legend[0].textStyle.color }}
              onClick={
                (e) => {
                  state.colorPickerShow = true
                }
              }
              onBlur={
                (e: any) => {
                  // color-picker-box 内元素不触发
                  if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                    if (e.relatedTarget.tagName == 'INPUT') {
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener(
                        'blur',
                        (el: any) => {
                          setTimeout(() => state.colorPickerShow = false, 100)
                        }
                      )
                    } else {
                      e.target.focus()
                    }
                    return
                  }
                  setTimeout(() => state.colorPickerShow = false, 100)
                }
              }
            ></input>
            {
              state.colorPickerShow &&
              <ColorPicker
                theme="dark"
                suckerCanvas="null"
                suckerArea={[]}
                color={componentConfig.value.optionData.legend[0].textStyle.color}
                onChangeColor={(value) => {
                  changeColor('color', value.hex)
                }}
                {...(props as any)}
              />
            }
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__label">字体</div>
          <div class="setting-row__content">
            <Select
              v-model:value={
                componentConfig.value.optionData.legend[0].textStyle.fontFamily
              }
              size="small"
              onChange={value => handleChange('fontFamily', value)}
            >
              {fontFamilyList.map(item => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              min={11}
              precision={0}
              v-model:value={
                componentConfig.value.optionData.legend[0].textStyle.fontSize
              }
              onChange={value => handleChange('fontSize', value)}
            />
          </div>
        </div>
      </>
    )


    return () => <SettingItemBox title="图例" v-slots={legendSlot} />
  },
})

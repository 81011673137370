export const dyncOptionsMock = [
  {
    unitId: '1',
    unitName: '折线图',
    unitIconFont: 'icon-zhexiantu',
    list: [
      {
        id: '10',
        name: '折线柱状图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-line.png?tenant_code=paas',
        type: 'lineBarCharts',
        width: '410px',
        height: '340px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '11',
        name: '基础折线图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-line.png?tenant_code=paas',
        type: 'chart-line',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '12',
        name: '基础面积图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/area-line.png?tenant_code=paas',
        type: 'chart-line',
        option: {
          processType: 'display',
          seriesType: '0',
          series: [
            {
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'line',
              seriesLayoutBy: 'row'
            }
          ]
        }
      }
    ]
  },
  {
    unitId: '2',
    unitName: '柱状图',
    unitIconFont: 'icon-zhuzhuangtu02-01',
    list: [
      {
        id: '21',
        name: '基础柱状图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'display',
          seriesType: '0',
          yAxis: [{ type: 'value' }],
          xAxis: [{ type: 'category' }]
        }
      },
      {
        id: '22',
        name: '堆叠柱状图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'display',
          seriesType: '0',
          yAxis: [{ type: 'value' }],
          xAxis: [{ type: 'category' }],
          series: [
            {
              stack: 'Ad',
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'bar'
            }
          ]
        }
      },
      {
        id: '23',
        name: '基础条形图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-strip-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'display',
          seriesType: '0',
          yAxis: [{ type: 'category' }],
          xAxis: [{ type: 'value' }],
          series: [
            {
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'bar'
            }
          ]
        }
      },
      {
        id: '24',
        name: '堆叠条形图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-strip-bar.png?tenant_code=paas',
        type: 'chart-bar',
        option: {
          processType: 'display',
          seriesType: '0',
          yAxis: [{ type: 'category' }],
          xAxis: [{ type: 'value' }],
          series: [
            {
              stack: 'Ad',
              itemStyle: {
                color: {
                  x: '0',
                  y: '0',
                  y2: '1',
                  global: false,
                  x2: '0',
                  colorStops: [],
                  type: 'linear'
                }
              },
              type: 'bar'
            }
          ]
        }
      },
      {
        id: '25',
        name: '3D堆叠柱状图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-bar.png?tenant_code=paas',
        type: 'chart-bar3D',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '3',
    unitName: '饼图',
    unitIconFont: 'icon-bingtu',
    list: [
      {
        id: '31',
        name: '基本饼图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/cake-pie.png?tenant_code=paas',
        type: 'chart-pie',
        option: {
          processType: 'display',
          seriesType: '0',
          series: [
            {
              legendHoverLink: false,
              grid: [
                {
                  x: '0',
                  y: '0',
                  y2: '0',
                  x2: '0'
                }
              ],
              legend: {
                selectedMode: false
              },
              itemStyle: {},
              label: {
                show: true,
                formatter: '{b}: {@[1]}({d}%)'
              },
              type: 'pie',
              radius: ['0%', '70%']
            }
          ]
        }
      },
      {
        id: '32',
        name: '环形图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/basics-annular.png?tenant_code=paas',
        type: 'chart-pie',
        option: {
          processType: 'display',
          seriesType: '0',
          series: [
            {
              legendHoverLink: false,
              grid: [
                {
                  x: '0',
                  y: '0',
                  y2: '0',
                  x2: '0'
                }
              ],
              legend: {
                selectedMode: false
              },
              itemStyle: {},
              label: {
                show: true,
                formatter: '{b}: {@[1]}({d}%)'
              },
              type: 'pie',
              radius: ['40%', '70%']
            }
          ]
        }
      },
      {
        id: '33',
        name: '3D环形图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-bar.png?tenant_code=paas',
        type: 'chart-pie3D',
        option: {
          processType: 'display',
          seriesType: '1'
        }
      },
      {
        id: '34',
        name: '水球图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-bar.png?tenant_code=paas',
        type: 'chart-liquidFill',
        option: {
          processType: 'display',
          seriesType: '1'
        }
      },
      {
        id: '35',
        name: '热力图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/stack-bar.png?tenant_code=paas',
        type: 'chart-heatmap',
        option: {
          processType: 'display',
          seriesType: '1'
        }
      }
    ]
  },
  {
    unitId: '4',
    unitName: '表格',
    unitIconFont: 'icon-biaoge',
    list: [
      {
        id: '41',
        name: '表格',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/biaoge.png?tenant_code=paas',
        type: 'table',
        width: '410px',
        height: '340px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '42',
        name: '复杂表格',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/fuzabiaoge.png?tenant_code=paas',
        type: 'antd-table',
        width: '960px',
        height: '300px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '5',
    unitName: '文本',
    unitIconFont: 'icon-text',
    list: [
      {
        id: '51',
        name: '文本',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'text',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '52',
        name: '复杂文本',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'complexText',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '53',
        name: '时间',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'time',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '54',
        name: '翻牌器',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shuzifanpaiqi.png?tenant_code=paas',
        type: 'flap',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '55',
        name: '文字跑马灯',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'carouselText',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '56',
        name: '液晶数字',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'liquid',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '57',
        name: '动画翻牌器',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'flipperAnimate',
        width: '475px',
        height: '200px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '6',
    unitName: '表单',
    unitIconFont: 'icon-form',
    list: [
      {
        id: '61',
        name: '输入框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shurukuang.png?tenant_code=paas',
        type: 'form-input',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '62',
        name: '下拉框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/xialakuang.png?tenant_code=paas',
        type: 'form-select',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '63',
        name: '数字区间',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shuzhiqujian.png?tenant_code=paas',
        type: 'form-inputNumber',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '64',
        name: '日期区间',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/riqiqujian.png?tenant_code=paas',
        type: 'form-rangePicker',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '65',
        name: '日期',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/rili.png?tenant_code=paas',
        type: 'form-datePicker',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '66',
        name: '单选框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/danxuan.png?tenant_code=paas',
        type: 'form-radio',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '67',
        name: '多选框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duoxuan.png?tenant_code=paas',
        type: 'form-checkbox',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '68',
        name: '查询按钮',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/chaxun.png?tenant_code=paas',
        type: 'form-button',
        option: {
          processType: 'other',
          seriesType: '0',
          type: 'primary'
        }
      },
      {
        id: '601',
        name: '开关',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/chaxun.png?tenant_code=paas',
        type: 'form-switch',
        width: '165px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '7',
    unitName: '图片',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '71',
        name: '图片',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/tupian.png?tenant_code=paas',
        type: 'image',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '72',
        name: '图片跑马灯',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/tupian.png?tenant_code=paas',
        type: 'carouselImage',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '9',
    unitName: '功能',
    unitIconFont: 'icon-text',
    list: [
      {
        id: '91',
        name: '打印',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/duohangwenben.png?tenant_code=paas',
        type: 'outPrint',
        width: '65px',
        height: '50px',
        option: {
          processType: 'output',
          seriesType: '0'
        }
      },
      {
        id: '92',
        name: '导出EXCEL',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'outExcel',
        width: '103px',
        height: '50px',
        option: {
          processType: 'output',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '10',
    unitName: '容器',
    unitIconFont: 'icon-rongqi',
    list: [
      {
        id: '101',
        name: '标签页',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'form-tabs',
        width: '260px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      },
      {
        id: '102',
        name: '模态框',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'antd-modal',
        width: '400px',
        height: '300px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '11',
    unitName: '地图',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '111',
        name: '中国地图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'chart-chinaMap',
        width: '250px',
        height: '250px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '12',
    unitName: '数据',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '121',
        name: '数据路由',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'data',
        width: '50px',
        height: '50px',
        option: {
          processType: 'entry',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '13',
    unitName: '监听',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '131',
        name: '监听数字',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'watch',
        width: '100px',
        height: '200px',
        option: {
          processType: 'output',
          seriesType: '0'
        }
      }
    ]
  },
  {
    unitId: '1000',
    unitName: '蓝思羽',
    unitIconFont: 'icon-tupian',
    list: [
      {
        id: '1001',
        name: '车间班组看板列表',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'board-list',
        width: '445px',
        height: '442px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1002',
        name: '地台库存看板列表',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'platform-list',
        width: '250px',
        height: '250px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1003',
        name: '被业绗缝看板列表',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'station-list',
        width: '336px',
        height: '281px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1004',
        name: '外贸包装看板列表',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'foreign-list',
        width: '336px',
        height: '694px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      // {
      //   id: '1005',
      //   name: '班组看板款式信息统计',
      //   image:
      //     '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
      //   type: 'team-kanban-info',
      //   width: '940px',
      //   height: '600px',
      //   option: {
      //     processType: 'display',
      //     seriesType: '0'
      //   }
      // },
      {
        id: '1006',
        name: '被业车间作业记录-款式信息',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'style-info-carousel',
        width: '940px',
        height: '600px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1007',
        name: '主推概念',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'main-concepts',
        width: '300px',
        height: '150',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1008',
        name: '营销部-市场资讯',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'market-information',
        width: '300px',
        height: '150',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1009',
        name: '主推产品',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'main-product',
        width: '449px',
        height: '300px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1010',
        name: '开发部-市场资讯',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'dev-market-information',
        width: '440px',
        height: '220px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1011',
        name: '裁剪车间看板列表',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'tailor-list',
        width: '336px',
        height: '694px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1012',
        name: '模拟热力图',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'thermodynamic',
        width: '1000px',
        height: '360px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1013',
        name: '机台效率',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'machineEfficiency',
        width: '665px',
        height: '300px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      },
      {
        id: '1014',
        name: '开发部-横向-市场资讯',
        image:
          '//47.110.145.204:59168/FileResource/api/file/DyncBI/shijian.png?tenant_code=paas',
        type: 'devHorizontalMarketInformation',
        width: '440px',
        height: '220px',
        option: {
          processType: 'display',
          seriesType: '0'
        }
      }
    ]
  }
]

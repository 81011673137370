import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import { Input } from 'ant-design-vue'
import SettingItemRow from '../../settingItemRow'
import useSetAttribute from '@/hooks/useSetAttribute'
export default defineComponent({
  name: 'remark',
  order: 1,
  setup() {
    const store = useStore()
    const { componentConfig }: any = userMapState('editor', [
      'componentConfig',
    ]) as IState

    const handleChangeContent = value => {
      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['remark'],
        value
      })
      store.commit('editor/SET_REMARK_NAME', value)
    }

    return () => (
      <SettingItemRow title={'备注'}>
        <Input
          style={{ fontSize: '14px!important' }}
          size="small"
          onChange={e => handleChangeContent(e.target.value)}
          v-model:value={componentConfig.value.remark}
          placeholder="请输入备注"
        />
      </SettingItemRow>
    )
  },
})

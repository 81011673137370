import { r, d, h } from "@/units"
export default d({
  name: 'pageEditorModal',
  props: {
    nextDom: Object
  },
  setup: (props,context) => {
    const VNode = {
      render() {
        return h(
          r("a-modal"),
          context.slots.default
        )
      }
    }
    return () => h(VNode)
  }
})

import { dyncOptionsMock } from '@/mock/dyncBI/options'
import { queryOptionsMock } from '@/mock/queryBI/options'
import { message } from 'ant-design-vue'
import dyncDatamock from '@/mock/dyncBI/datas.json'
import queryDatamock from '@/mock/queryBI/datas.json'
import { DYNC_BI, QUERY_BI } from '@/utils/const'
import darkJson from '../../public/dark.json'
import defaultJson from '../../public/default.json'
import store from '@/store'

// 场景化 options
export const getOptionsMock = (type: string) => {
  const optionsMap = {
    [DYNC_BI]: dyncOptionsMock,
    [QUERY_BI]: queryOptionsMock,
  }
  if (!type) return message.warning('场景类型不能为空')
  return optionsMap[type]
}

// 场景化 data
export const getDataMock = (type: string) => {
  const dataMap = {
    [DYNC_BI]: dyncDatamock,
    [QUERY_BI]: queryDatamock,
  }
  if (!type) return message.warning('场景类型不能为空')
  return dataMap[type]
}

export const getMainMock = (type: string) => {
  const biType = {
    '2': {
      isDark: true,
      width: '1920',
      height: '1080'
    },
    '3': {
      isDark: false,
      width: '1024',
      height: '900'
    }
  }
  return {
    pageID: '',
    pageName: '',
    isDark: biType[type].isDark,
    body: {
      type: '',
      style: {
        width: biType[type].width,
        height: biType[type].height,
        background: '',
        backgroundImage: '',
        backgroundSize: '',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '',
        backgroundOpacity: '',
        backgroundBlendMode: '',
        backgroundClip: '',
        backgroundOrigin: ''
      },
      children: []
    }
  }
}

const setProperty = (values) => {
  for (let p in values) {
    document.documentElement.style.setProperty(`--${p}`, values[p])
  }
}

export const changeTheme = (isDark: boolean) => {
  (window as any)?.less?.modifyVars(
    isDark ? {
      ...darkJson,
    } : {
      ...defaultJson,
      '@layout-header-background': '#fff',
    }
  )
  isDark ?
    setProperty({
      'canvasBackground': '#222',
      'rulersBackground': '#2a2a2a',
      'viewBackground': '#333',
      'siderLeftBackground': '#2a2a2a',
      'siderLeftBackgroundHover': 'rgba(0,12,23,0.65)',
      'tableSumBackground': 'transparent',
      'tableFixBackground': 'rgba(0,0,0,0.25)',
    })
    :
    setProperty({
      'canvasBackground': '#ddd',
      'rulersBackground': '#fafafa',
      'viewBackground': '#fff',
      'siderLeftBackground': '#ccc',
      'siderLeftBackgroundHover': 'rgba(13,1,3,0.35)',
      'tableSumBackground': 'transparent',
      'tableFixBackground': 'rgba(255,255,255,0.45)',
    })
  store.dispatch('editor/setDarkMode', isDark)
}

export const option = {
  label: '标题',
  placeholder: '请输入',
  options: [],
  mode: '',
  dataset: {
    source: []
  },
  size: 'middle',
  series: [
    {
      type: 'inputNumber'
    }
  ]
}

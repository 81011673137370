import useMoveableTarget from '@/hooks/useMoveableTarget'
import { IState } from '@/store/modules/editor'
import {
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
  UploadOutlined,
  LinkOutlined
} from '@ant-design/icons-vue'
import { userMapState } from '@/store/userMapper'
import { Input, Upload, Button, RadioGroup, RadioButton, InputNumber, Checkbox } from 'ant-design-vue'
import { defineComponent, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import API from '@/api'

export default defineComponent({
  name: 'src',
  order: 6,
  setup() {
    const store = useStore()
    const { componentConfig, moveablerRef, selecto, mainJson }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto',
      'mainJson'
    ]) as IState

    const state = reactive({
      loading: false,
      backgroundImageWidth: 0,
      backgroundImageHeight: 0,
      scale: 0
    })


    const { targetCharts, options } = useMoveableTarget(selecto)

    const handleChange = (type: string, value: any) => {

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: type,
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', type],
        value
      })
      
      options.src = componentConfig.value.optionData.src
      options.backgroundPosition = componentConfig.value.optionData.backgroundPosition

      options.backgroundImageWidth = componentConfig.value.optionData.backgroundImageWidth
      options.backgroundImageHeight = componentConfig.value.optionData.backgroundImageHeight

      console.log('options', options)
      targetCharts.setOption(options, false)
    }

    onMounted(()=>{
      state.scale = componentConfig.value.optionData.backgroundImageWidth / componentConfig.value.optionData.backgroundImageHeight
    })
    // 上传钩子
    const beforeUpload = file => {
      console.log('file', file)
      return true
    }
    // 上传图片
    const handleCustomRequest = info => {
      
      const formData = new FormData()
      formData.append('file', info.file)
      API.pageManage.uploadBackgroundImage(formData, mainJson.value.pageID).then((res: any) => {
        if (!res.success) return
        const target = moveablerRef.value.target[0]
        store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
          attribute: 'src',
          value: res.data.filePath
        })
        store.dispatch('editor/setMainJsonAttribute', {
          id: componentConfig.value.id,
          path: ['option', 'src'],
          value: res.data.filePath,
        })
        options['src'] = res.data.filePath
        targetCharts.setOption(options, false)

        let tempImg = document.createElement('img')
          tempImg.src = res.data.filePath
          document.body.appendChild(tempImg)
          tempImg.onload = () => {
            state.backgroundImageWidth = tempImg.offsetWidth
            state.backgroundImageHeight = tempImg.offsetHeight
            state.scale = state.backgroundImageWidth / state.backgroundImageHeight

            handleChange('backgroundImageWidth', state.backgroundImageWidth)
            handleChange('backgroundImageHeight', state.backgroundImageHeight)
            handleChange('scale', state.scale)
          }
      })
    }

    const columnSlot = () => (
      <>
        <div class="setting-row">
          <div class="setting-row__label">URL</div>
          <div class="setting-row__content">
            <Input
              size="small"
              onChange={e => {
                handleChange('src', e.target.value)
                console.log('targetCharts--------------------',targetCharts)
                let tempImg = document.createElement('img')
                tempImg.src = e.target.value
                document.body.appendChild(tempImg)
                tempImg.onload = () => {
                  state.backgroundImageWidth = tempImg.offsetWidth
                  state.backgroundImageHeight = tempImg.offsetHeight
                  state.scale = state.backgroundImageWidth / state.backgroundImageHeight

                  handleChange('backgroundImageWidth', state.backgroundImageWidth)
                  handleChange('backgroundImageHeight', state.backgroundImageHeight)
                  handleChange('scale', state.scale)
                }
              }}
              v-model:value={componentConfig.value.optionData.src}
              placeholder="请输入标题内容"
            />
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__label">上传图片</div>
          <div class="setting-row__content">
            <Upload
              name="background"
              class="background-upload"
              showUploadList={false}
              beforeUpload={beforeUpload}
              maxCount={1}
              customRequest={handleCustomRequest}
            >
                <Button size="small">
                <UploadOutlined></UploadOutlined>
                  点击上传
                </Button>
            </Upload>
          </div>
        </div>
        <div class="setting-row" v-show={componentConfig.value.optionData.src}>
            <div class="setting-row__label">图片位置</div>
            <div class="setting-row__content">
              <RadioGroup
                size="small"
                v-model:value={componentConfig.value.optionData.backgroundPosition}
                onChange={event =>
                  handleChange('backgroundPosition', event.target.value)
                }
              >
                <RadioButton value="top left">左上</RadioButton>
                <RadioButton value="center center">中央</RadioButton>
                <RadioButton value="top right">右上</RadioButton>
              </RadioGroup>
            </div>
          </div>

          <div class="setting-row" v-show={componentConfig.value.optionData.src}>
            <div class="setting-row__label">图片大小</div>
            <div class="setting-row__content">
              <InputNumber
                style={{ width: '45%' }}
                value={componentConfig.value.optionData.backgroundImageWidth}
                addonBefore="W"
                size="small"
                precision={0}
                onChange={(value: number) => {
                  state.backgroundImageWidth = value
                  state.backgroundImageHeight = Number(value / state.scale)
                  // handleChange('backgroundSize', `${state.backgroundImageWidth}px ${state.backgroundImageHeight}px`)
                  handleChange('backgroundImageWidth', state.backgroundImageWidth)
                  handleChange('backgroundImageHeight', state.backgroundImageHeight)
                  handleChange('scale', state.scale)
                }}
              />
              <LinkOutlined style={{ width: '20px' }} />
              <InputNumber
                style={{ width: '45%' }}
                value={componentConfig.value.optionData.backgroundImageHeight}
                addonBefore="H"
                size="small"
                precision={0}
                onChange={(value: number) => {
                  state.backgroundImageHeight = value
                  state.backgroundImageWidth = Number(value * state.scale)
                  handleChange('backgroundImageWidth', state.backgroundImageWidth)
                  handleChange('backgroundImageHeight', state.backgroundImageHeight)
                  handleChange('scale', state.scale)
                }}
              />
            </div>
          </div>
      </>
    )

    return () => <SettingItemBox title="图片地址" v-slots={columnSlot} />
  },
})

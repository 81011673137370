export const option = {
  label: '标题',
  placeholder: '请输入',
  paramsConfig: {},
  size: 'middle',
  defaultValue: '',
  dataset: {
    source: []
  },
  series: [
    {
      type: 'input'
    }
  ]
}

import { defineComponent, nextTick, onMounted, reactive, watchEffect } from 'vue'
import SettingItemBox from '../settingItemBox'
import { Form, InputNumber, Switch, Select } from 'ant-design-vue'
import { useStore } from 'vuex'
import { isDisabled } from '@/utils'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
export default defineComponent({
  name: 'LocationSize',
  setup() {
    const store = useStore()

    const {
      pageSize,
      viewportRef,
      mainJson,
      componentConfig

    }: any = userMapState(
      'editor',
      ['viewportRef', 'pageSize', 'mainJson', 'componentConfig']
    ) as IState

    const state = reactive({
      width: 0,
      height: 0,
      displayType: '',
      inWebframe: mainJson.value.body.style.inWebframe || false,
      isMAXPageHeight: mainJson.value.body.style.isMAXPageHeight || false,
      isMAXPageWidth: mainJson.value.body.style.isMAXPageWidth || false
    })
    const displayTypeCommon = [{
      value: '1280*720',
      label: '笔记本(1280*720)'
    }, {
      value: '1366*768',
      label: '普通液晶显示器(1366*768)'
    }, {
      value: '1440*900',
      label: '苹果常用(1440*900)'
    }, {
      value: '1920*1080',
      label: '高清液晶显示器(1920*1080)'
    }, {
      value: '2560*1440',
      label: '2K高清显示器(2560*1440)'
    }, {
      value: '3840*2160',
      label: '4K高清显示器(3840*2160)'
    }]
    const displayTypeWebframe = [
      {
        value: '1060*520',
        label: '笔记本(1060*520)'
      }, {
        value: '1146*568',
        label: '普通液晶显示器(1146*568)'
      }, {
        value: '1220*700',
        label: '苹果常用(1220*700)'
      }, {
        value: '1700*880',
        label: '高清液晶显示器(1700*880)'
      }, {
        value: '2340*1240',
        label: '2K高清显示器(2340*1240)'
      }, {
        value: '3620*1960',
        label: '4K高清显示器(3620*1960)'
      }
    ]
    let displayType = []
    watchEffect(
      () => {
        state.height = mainJson.value.body.style.height
        state.width = mainJson.value.body.style.width
        state.displayType = `${state.width}*${state.height}`
        state.inWebframe = mainJson.value.body.style.inWebframe || false
        state.isMAXPageHeight = mainJson.value.body.style.isMAXPageHeight
        state.isMAXPageWidth = mainJson.value.body.style.isMAXPageWidth
        if (viewportRef.value.style) {
          viewportRef.value.style.width = `${state.width}px`
          viewportRef.value.style.height = `${state.height}px`
        }
        if (state.inWebframe) {
          displayType = displayTypeWebframe
        } else {
          displayType = displayTypeCommon
        }

      })
    // 位置尺寸
    const pageSizeData = [
      {
        addonAfter: 'px',
        addonBefore: 'W',
        ref: 'pageWidth',
        label: 'width'
      },
      {
        addonAfter: 'px',
        addonBefore: 'H',
        ref: 'pageHeight',
        label: 'height'
      }
    ]

    const handleFixHeightChange = (value) => {
      store.commit('editor/SET_PAGE_ATTRIBUTE', {
        attribute: 'isMAXPageHeight',
        value,
      })
      store.dispatch('editor/setMainJsonStyle', {
        path: 'isMAXPageHeight',
        value
      })
      if (!value) {
        mainJson.value.body.children.find(
          item => {
            if (item.option.isFixedPageSizeH) item.option.isFixedPageSizeH = false
          }
        )

        if (componentConfig.value.optionData.isFixedPageSizeH) componentConfig.value.optionData.isFixedPageSizeH = false
      }

    }
    const handleFixWidthChange = (value) => {
      store.commit('editor/SET_PAGE_ATTRIBUTE', {
        attribute: 'isMAXPageWidth',
        value,
      })
      store.dispatch('editor/setMainJsonStyle', {
        path: 'isMAXPageWidth',
        value
      })
      if (!value) {
        mainJson.value.body.children.find(
          item => {
            if (item.option.isFixedPageSizeW) item.option.isFixedPageSizeW = false
          }
        )

        if (componentConfig.value.optionData.isFixedPageSizeW) componentConfig.value.optionData.isFixedPageSizeW = false
      }

    }

    // 设置位置和尺寸
    const handleChange = (type, value) => {
      !value && (value = 20)
      store.commit('editor/SET_PAGE_ATTRIBUTE', {
        attribute: type,
        value,
      })
      store.dispatch('editor/setMainJsonStyle', {
        path: type,
        value
      })
      viewportRef.value.style[type] = value + 'px'
    }

    const handleChangeSize = (size) => {
      const [width, height] = size.split('*')
      state.width = parseInt(width)
      state.height = parseInt(height)
      state.displayType = `${width}*${height}`
      mainJson.value.body.style.height = parseInt(height)
      mainJson.value.body.style.width = parseInt(width)
    }
    const handleInWebframeChange = (v) => {
      state.inWebframe = v
      mainJson.value.body.style.inWebframe = v
      const width =state.width
      const height = state.height
      if (v) {
        state.width = width - 220
        state.height = height - 200
      } else {
        state.width = width + 220
        state.height = height + 200
      }
      state.displayType = `${state.width}*${state.height}`
      mainJson.value.body.style.height = state.height
      mainJson.value.body.style.width = state.width
    }
    // 位置和尺寸插槽
    const pageSizeSlot = () => (
      <div>
        <div class="common-setting-item-box">
          <div class="setting-row">
            <div class="setting-row__label">设备</div>
            <div class="setting-row__content">
              <Select
                size="small"
                v-model:value={state.displayType}
                onChange={(size: string) => handleChangeSize(size)}
              >
                {
                  displayType.map(item => (<Select.Option value={item.value}>{item.label}</Select.Option>))
                }
              </Select>
            </div>
          </div>
        </div>
        <div class="common-setting-item-box">
          <div class="setting-row">
            <div class="setting-row__label">frame显示</div>
            <div class="setting-row__content">
              <Switch
                size="small"
                v-model:checked={state.inWebframe}
                onChange={(e: boolean) =>
                  handleInWebframeChange(e)
                }
              />
            </div>
          </div>
        </div>
        <Form
          layout="inline"
          wrapperCol={{ style: { width: '120px' } }}
        >

          {
            pageSizeData.map(item => (
              <Form.Item>
                <InputNumber
                  disabled={((item.label == 'height' && mainJson.value.body.style.isMAXPageHeight) || (item.label == 'width' && mainJson.value.body.style.isMAXPageWidth)) ? true : false}
                  addonAfter={item.addonAfter}
                  addonBefore={item.addonBefore}
                  size="small"
                  ref={item.ref}
                  precision={0}
                  v-model:value={state[item.label]}
                  // disabled={isDisabled(mainJson.value.body.style[item.label])}
                  onChange={value => handleChange(item.label, value)}
                />
              </Form.Item>
            ))
          }
          <div
            class="setting-row"
          >
            <div class="setting-row__label">继承宽度</div>
            <div class="setting-row__content">
              <Switch
                size="small"
                v-model:checked={state.isMAXPageWidth}
                onChange={(e: boolean) =>
                  handleFixWidthChange(e)
                }
              />
            </div>
            <div class="setting-row__label">继承高度</div>
            <div class="setting-row__content">
              <Switch
                size="small"
                v-model:checked={state.isMAXPageHeight}
                onChange={(e: boolean) =>
                  handleFixHeightChange(e)
                }
              />
            </div>
          </div>
        </Form>
      </div>

    )

    return () => (
      <SettingItemBox title="尺寸" v-slots={pageSizeSlot} />
    )
  },
})

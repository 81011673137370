import { defineComponent, reactive } from 'vue'
import SettingItemBox from '../../../settingItemBox'
import { userMapState } from '@/store/userMapper'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Input, InputNumber } from 'ant-design-vue'

export default defineComponent({
  name: 'info',
  setup(props) {
    const { componentConfig, selecto, mainJson }: any = userMapState('editor', [
      'mainJson',
      'componentConfig',
      'selecto',
    ])

    const state = reactive({
      initValue: 0,
      updateValue: 0
    })

    const initSetValue = () => {
      const { targetCharts, options } = useMoveableTarget(selecto)
      const { dataSource, staticData, option } = targetCharts.props
      // 如果没有值的话 赋值为mock数据
      const isMock = !dataSource || dataSource === 'mock'

      if (isMock) {
        state.initValue = staticData.source[0].oldValue
        state.updateValue = staticData.source[0].newValue
      } else {
        state.initValue = option.dataset.source[0].oldValue
        state.updateValue = option.dataset.source[0].newValue
      }
    }

    // 渲染组件
    const renderComponent = (key: string[], value: any) => {
      const _key = key.join()
      const { targetCharts, options } = useMoveableTarget(selecto)
      const _options = { ...options, ...componentConfig.value.optionData }
      // 复杂对象需要手动赋值替换（array or object）
      if (Array.isArray(_options[_key])) {
        _options[_key] = value
      }
      console.log('_options---', _options, componentConfig.value.optionData)
      targetCharts.setOption(_options, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const setProperty = (key: string[], value: any): void => {
      useSetOptionProperty({
        id: componentConfig.value.id,
        key,
        value,
      })
      renderComponent(key, value)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const handleChange = (key: string[], value: any): void => {
      setProperty(key, value)
    }

    const infoSlot = () => (
      <div class="setting-container">
        {/* <div class="setting-row">
          <div class="setting-row__label">初始值</div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              v-model:value={state.initValue}
              placeholder="请输入初始值"
              onChange={value => {
                handleChange(['staticData', 'source', '0', 'oldValue'], value)
              }}
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">更新值</div>
          <div class="setting-row__content">
            <InputNumber
                size="small"
                v-model:value={state.updateValue}
                placeholder="请输入更新值"
                onChange={value => {
                  handleChange(['staticData', 'source', '0', 'newValue'], value)
                }}
              />
          </div>
        </div> */}

        <div class="setting-row">
          <div class="setting-row__label">宽度</div>
          <div class="setting-row__content">
            <InputNumber
                size="small"
                v-model:value={componentConfig.value.optionData.width}
                placeholder="请输入宽度"
                onChange={value => {
                  handleChange(['width'], value)
                }}
              />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">高度</div>
          <div class="setting-row__content">
            <InputNumber
                size="small"
                v-model:value={componentConfig.value.optionData.height}
                placeholder="请输入高度"
                onChange={value => {
                  handleChange(['height'], value)
                }}
              />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">字体大小</div>
          <div class="setting-row__content">
            <InputNumber
                size="small"
                v-model:value={componentConfig.value.optionData.fontSize}
                placeholder="请输入字体大小"
                onChange={value => {
                  handleChange(['fontSize'], value)
                }}
              />
          </div>
        </div>

        {/* <div class="setting-row">
          <div class="setting-row__label">数值长度</div>
          <div class="setting-row__content">
            <InputNumber
                size="small"
                v-model:value={componentConfig.value.optionData.flipperLength}
                placeholder="请输入数值长度"
                onChange={value => {
                  handleChange(['flipperLength'], value)
                }}
              />
          </div>
        </div> */}
      </div>
    )
    return () => <SettingItemBox title="基础配置" v-slots={infoSlot} />
  },
})

import { defineComponent } from "vue";
import './index.less'



interface IProps {
  title?: string
}

export default defineComponent({
  name: 'SettingItemRow',
  props: {
    title: {
      type: String,
      default: '默认标题'
    }
  },
  setup(props: IProps, { slots }) {
    return () => (
      <div class="setting-row ">
        <div class="setting-row__label">{props.title}</div>
        <div class="setting-row__content">{ slots.default?.() }</div>
      </div>
    )
  }
})

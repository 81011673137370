import { userMapState } from '@/store/userMapper'
import { Input, InputNumber } from 'ant-design-vue'
import { defineComponent, unref } from 'vue'
import SettingItemBox from '../../../../settingItemBox'
import useSetOptionProperty from '@/hooks/useSetOptionProperty'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { set } from 'lodash'
export default defineComponent({
  name: 'BasicConfig',
  setup() {
    const { componentConfig, selecto }: any = userMapState('editor', ['componentConfig', 'selecto'])
    const {
      id,
      optionData: { basicConfig },
    } = unref(componentConfig)

    // 渲染组件
    const renderComponent = (key: string[], value: any) => {
      const [_, property] = key
      const { targetCharts, options } = useMoveableTarget(selecto)
      const _options = { ...options, ...componentConfig.value.optionData }
      // 复杂对象需要手动赋值替换（array or object）
      if (Array.isArray(_options[property])) {
        // _options[_key] = value
        set(_options, key, value)
      }
      console.log('_options---', _options, componentConfig.value.optionData)
      targetCharts.setOption(_options, false)
    }

    /**
     * @param {Array} key 要修改的路劲 lodash [key]
     * @param value
     * @returns
     */
    const handleChange = (key: string[], value: unknown): void => {
      useSetOptionProperty({
        id: id,
        key,
        value,
      })
      renderComponent(key, value)
    }

    const basicConfigSlot = () => (
      <>
        <div class="setting-row">
          <div class="setting-row__label">标题</div>
          <div class="setting-row__content">
            <Input
              size="small"
              placeholder="请输入模态框标题"
              v-model:value={basicConfig.title}
              onChange={e => handleChange(['basicConfig', 'title'], e.target.value)}
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">宽度</div>
          <div class="setting-row__content">
            <InputNumber 
              size="small"
              v-model:value={basicConfig.width}
              onChange={(value: number) => handleChange(['basicConfig', 'width'], value)}
            />
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">iframe链接</div>
          <div class="setting-row__content">
            <Input
              size="small"
              placeholder="请输入模态框宽度"
              v-model:value={basicConfig.iframePath}
              onChange={e => handleChange(['basicConfig', 'iframePath'], e.target.value)}
            />
          </div>
        </div>
      </>
    )

    return () => <SettingItemBox title="基本配置" v-slots={basicConfigSlot} />
  },
})

import type { IState } from "@/store/modules/editor"
import { userMapState } from "@/store/userMapper"
import { fontFamilyList } from "@/utils/constance"
import { Input, InputNumber, Select } from "ant-design-vue"
import { BoldOutlined, ItalicOutlined, AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined } from "@ant-design/icons-vue"
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watchEffect } from "vue"
import { useStore } from "vuex"
import { ColorPicker } from 'vue-color-kit'
import SettingItemBox from '../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import type { PropType } from 'vue'
import { IComplexText } from '@/types'
import _ from 'lodash'
export default defineComponent({
  name: 'LableTitle',
  order: 1,
  props: {
    option: {
      type: Object as PropType<IComplexText>,
    },
    staticData: Array
  },
  setup(props, ctx) {
    const state = reactive({
      color: '',
      colorPickerShow: 'none',
      text: ''

    })
    const store = useStore()
    const { mainJson, moveablerRef, selecto }: any = userMapState('editor', [
      'mainJson',
      'moveablerRef',
      'selecto'
    ]) as IState

    const componentConfig = computed(() => {
      return mainJson.value.body.children.find(config => config.id === store.state.editor.componentConfig.id)
    })

    const handleChangeContent = (value) => {
      const { targetCharts, options } = useMoveableTarget(selecto)
      targetCharts.props.staticData.source[0][0] = value

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['staticData', 'source', '0', '0'],
        value
      })

      // options.generalTitle.text = value
      targetCharts.setOption(options)
    }

    watchEffect(() => {
      state.color = componentConfig.value?.option?.lableTitle?.color
      if (componentConfig.value?.type == 'complexText') state.text = useMoveableTarget(selecto)?.targetCharts?.props?.staticData?.source[0][0]
    })

    const handleChange = (type: string, value: string | boolean) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: ['lableTitle', type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: 'lableTitle',
        path: ['option', 'lableTitle', 'color'],
        value
      })

      options.lableTitle.color = value
      targetCharts.setOption(options, false)
    }
    const changeColor = _.debounce(handleChange, 1000)

    const handleChangeStyle = (type, value) => {

      const propKeyMap = {
        fontFamily: ['lableTitle', 'fontFamily'],
        fontSize: ['lableTitle', 'fontSize'],
        fontWeight: ['lableTitle', 'fontWeight'],
        fontStyle: ['lableTitle', 'fontStyle'],
        fontAlign: ['lableTitle', 'fontAlign'],
        lableWidth: ['lableTitle', 'width'],
        color: ['lableTitle', 'color'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })
      const { targetCharts, options } = useMoveableTarget(selecto)
      options.lableTitle[type] = value
      targetCharts.setOption(options)
    }

    const changeFontWeight = computed(() => {
      let _fontWeight = componentConfig.value.option.lableTitle?.fontWeight
      return _fontWeight == 'bolder' ? 'primary' : 'default'
    })
    const changeFontStyle = computed(() => {
      let _fontStyle = componentConfig.value.option.lableTitle?.fontStyle
      return _fontStyle == 'italic' ? 'primary' : 'default'
    })

    const textSlot = () => (
      <>
        <div class="title-box common-setting-item-box">
          <div class="setting-row">
            <div class="setting-row__label">标题内容</div>
            <div class="setting-row__content">
              <Input
                size="small"
                onChange={e => handleChangeContent(e.target.value)}
                v-model:value={state.text}
                placeholder="请输入标题内容"
              />
            </div>
          </div>
          <div class="setting-row">
            <div class="setting-row__label">宽度</div>
            <div class="setting-row__content">
              <InputNumber
                size="small"
                addonAfter='px'
                onChange={(e) => handleChangeStyle('lableWidth',e)}
                v-model:value={componentConfig.value.option.lableTitle.width}
                placeholder="输入标题宽度"
              />
            </div>
          </div>
          <div class="setting-row">
            <div class="setting-row__label">横向对其</div>
            <div class="setting-row__content">
              <a-radio-group
                default-value="center"
                size="small"
                onChange={(value) => {
                  handleChangeStyle('fontAlign', value.target.value)
                }}
                v-model:value={componentConfig.value.option.lableTitle.fontAlign}
              >
                <a-radio-button value="left">
                  <AlignLeftOutlined />
                </a-radio-button>
                <a-radio-button value="center">
                  <AlignCenterOutlined />
                </a-radio-button>
                <a-radio-button value="right">
                  <AlignRightOutlined />
                </a-radio-button>
              </a-radio-group>
            </div>
          </div>

          <div class="setting-row">
            <div class="setting-row__label">字体</div>
            <div class="setting-row__content">
              <Select
                v-model:value={componentConfig.value.option.lableTitle.fontFamily}
                size="small"
                onChange={(value: string) => handleChangeStyle('fontFamily', value)}
              >
                {fontFamilyList.map(item => (
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </div>

          </div>
          <div class="setting-row">
            <div class="setting-row__label">字体大小</div>
            <div class="setting-row__content">
              <InputNumber
                size="small"
                min={11}
                precision={0}
                addon-after="px"
                v-model:value={componentConfig.value.option.lableTitle.fontSize}
                onChange={value => handleChangeStyle('fontSize', value)}
              />
            </div>
            <div class="setting-row__content" style={{ flex: 'none' }}>
              <a-button
                size="small"
                v-model:type={changeFontWeight.value}
                // onChange={() => handleChangeStyle('fontWeight', 'bolder')}
                onClick={value => handleChangeStyle('fontWeight', changeFontWeight.value == "primary" ? 'normal' : 'bolder')}
                style={{ width: '24px', padding: 0 }}>
                <BoldOutlined />
              </a-button>
            </div>
            <div class="setting-row__content" style={{ flex: 'none' }}>
              <a-button
                size="small"
                v-model:type={changeFontStyle.value}
                onClick={value => handleChangeStyle('fontStyle', changeFontStyle.value == "primary" ? 'normal' : 'italic')}
                style={{ width: '24px', padding: 0 }}>
                <ItalicOutlined />
              </a-button>
            </div>
          </div>
          <div class="setting-row">
            <div class="setting-row__label">字体颜色</div>
            <div class="setting-row__content">
              <input type="button" class="colorBtn"
                style={{ backgroundColor: componentConfig.value.option.lableTitle.color }}
                onClick={
                  (e) => {
                    state.colorPickerShow = 'block'
                  }
                }
                onBlur={
                  (e: any) => {
                    // color-picker-box 内元素不触发
                    if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                      if (e.relatedTarget.tagName == 'INPUT') {
                        // 监听 e.relatedTarget 失去焦点
                        e.relatedTarget.addEventListener(
                          'blur',
                          (el: any) => {
                            setTimeout(() => state.colorPickerShow = 'none', 100)
                          }
                        )
                      } else {
                        e.target.focus()
                      }
                      return
                    } else {
                      setTimeout(() => state.colorPickerShow = 'none', 100)
                    }
                  }
                }
              ></input>
              <ColorPicker
                style={{ display: state.colorPickerShow }}
                theme="dark"
                suckerCanvas="null"
                suckerArea={[]}
                color={componentConfig.value.option.lableTitle.color}
                onChangeColor={(value) => {
                  changeColor('color', value.hex)
                  // state.colorPickerShow = 'none'
                }}
                {...(props as any)}
              />
            </div>
          </div>

        </div>
      </>
    )

    return () => componentConfig.value ? <SettingItemBox title="标题设置" v-slots={textSlot} /> : null
  }
})

export const typeList = [
  {
    label: 'Mock',
    value: 'mock',
  },
  {
    label: 'API接口',
    value: 'api',
  },
  {
    label: 'Auto(页面级数据源)',
    value: 'auto',
  }
]
export const METHOD_GET = '0'
export const METHOD_POST = '1'
export const METHOD_PUT = '2'
export const methodList = [
  {
    label: 'GET',
    value: METHOD_GET,
  },
  {
    label: 'POST',
    value: METHOD_POST,
  },
  {
    label: 'PUT',
    value: METHOD_PUT,
  }
]

export const SOURCE_TYPE_API = '1'
export const SOURCE_TYPE_SQL = '0'

export const sourceTypeList = [
  {
    label: 'API',
    value: SOURCE_TYPE_API
  },
  {
    label: 'SQL',
    value: SOURCE_TYPE_SQL
  }
]

export const jsEditorConfig = {
  lang: 'javascript', //解析 json,javascript
  theme: 'clouds_midnight',
  wrap: 'free',
  options: {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    wrap: true,
    tabSize: 2,
    showPrintMargin: false,
    fontSize: 12,
    useWorker: false,
    highlightSelectedWord: true,
  }
}

export const sqlEditorConfig = {
  lang: 'sql',
  theme: 'dracula',
  options: {
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: true,
    wrap: true,
    tabSize: 2,
    showPrintMargin: false,
    fontSize: 12,
    useWorker: false,
    highlightSelectedWord: true,
  }
}

import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { defineComponent, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'

// import ElColorPicker from 'el-color-picker'
import { ColorPicker } from 'vue-color-kit'
import '../../vue-color-kit.css'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { InputNumber, Select, Switch } from 'ant-design-vue'
import { fontFamilyList, positionList } from '@/utils/constance'
import _ from 'lodash'
import { getComponentsType } from '@/utils'
export default defineComponent({
  name: 'Label',
  order: 4,
  setup(props, ctx) {
    const store = useStore()
    const { componentConfig, moveablerRef, mainJson, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'mainJson',
      'selecto',
    ]) as IState
    const state = reactive({
      color: '',
      typekey: true,
      colorPickerShow: 'none',
    })

    // const color = ref('rgba(255, 255, 255, 1)')

    watchEffect(() => {
      state.color = componentConfig.value.optionData?.label?.color
      const strType = getComponentsType(componentConfig.value.type, 1)
      if (strType[0] == 'chart') state.typekey = componentConfig.value.optionData?.series[0]?.type == "pie" ? false : true
    })

    const handleChange = (type: string, value: any) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)



      if (options.series[0].type == 'pie') {
        //legendHoverLink
        store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
          attribute: ['label', 'show'],
          value
        })
        store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
          attribute: ['series', '0', 'label', 'show'],
          value
        })
        store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
          attribute: ['series', '0', 'legendHoverLink'],
          value
        })
        store.dispatch('editor/setMainJsonAttribute', {
          id: componentConfig.value.id,
          target: 'option',
          key: 'label',
          path: ['option', 'label', 'show'],
          value: componentConfig.value.optionData.label['show'],
        })
        store.dispatch('editor/setMainJsonAttribute', {
          id: componentConfig.value.id,
          target: 'option',
          key: 'label',
          path: ['option', 'series', '0', 'label', 'show'],
          value: componentConfig.value.optionData.series[0].label['show'],
        })
        store.dispatch('editor/setMainJsonAttribute', {
          id: componentConfig.value.id,
          target: 'option',
          key: 'label',
          path: ['option', 'series', '0', 'legendHoverLink'],
          value: componentConfig.value.optionData.series[0]['legendHoverLink'],
        })
        options.series[0]['legendHoverLink'] = value
        options.series[0].label['show'] = value
        options.label['show'] = value
      } else {
        store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
          attribute: ['label', type],
          value
        })
        if (componentConfig.value.optionData?.series.length > 0) {
          store.dispatch('editor/setMainJsonAttribute', {
            id: componentConfig.value.id,
            target: 'option',
            key: 'label',
            path: ['option', 'label', type],
            value: componentConfig.value.optionData.label[type],
          })
          for (let i = 0; i < componentConfig.value.optionData.series.length; i++) {
            store.dispatch('editor/setMainJsonAttribute', {
              id: componentConfig.value.id,
              target: 'option',
              key: 'label',
              path: ['option', 'series', i, 'label', type],
              value: componentConfig.value.optionData.series[i].label[type],
            })
            options.series[i].label[type] = value
          }
        } else {
          store.dispatch('editor/setMainJsonAttribute', {
            id: componentConfig.value.id,
            target: 'option',
            key: 'label',
            path: ['option', 'label', type],
            value: componentConfig.value.optionData.label[type],
          })
          options.label[type] = value
        }

        // options.series[0].label[type] = value
      }

      targetCharts.setOption(options, false)
    }
    const changeColor = _.debounce(handleChange, 1000)


    // 边框插槽
    const labelSlot = () => (
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">是否显示</div>
          <div class="setting-row__content">
            <Switch size="small" v-model:checked={componentConfig.value.optionData.label.show}
              onChange={value => handleChange('show', value)} />
          </div>
        </div>
        <div class="setting-row" v-show={state.typekey}>
          <div class="setting-row__label">位置</div>
          <div class="setting-row__content">
            <Select
              v-model:value={componentConfig.value.optionData.label.position}
              defaultValue=""
              size="small"
              onChange={(value: string) => handleChange('position', value)}
            >
              {positionList.map(item => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div class="setting-row color-picker-box" v-show={state.typekey}>
          <div class="setting-row__label">字体颜色</div>
          <div class="setting-row__content">
            {/* <ElColorPicker
              v-model:value={componentConfig.value.optionData.label.color}
              showAlpha={true}
              colorFormat="rgb"
              size="mini"
              // onChange={value => handleChange('color', value)}
              onChange={value => changeColor('color', value)}
            /> */}
            <input type="button" class="colorBtn"
              style={{ backgroundColor: componentConfig.value.optionData.label.color }}
              onClick={
                (e) => {
                  state.colorPickerShow = 'block'
                }
              }
              onBlur={
                (e: any) => {
                  // color-picker-box 内元素不触发
                  if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                    if (e.relatedTarget.tagName == 'INPUT') {
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener(
                        'blur',
                        (el: any) => {
                          setTimeout(() => state.colorPickerShow = 'none', 100)
                        }
                      )
                    } else {
                      e.target.focus()
                    }
                    return
                  } else {
                    setTimeout(() => state.colorPickerShow = 'none', 100)
                  }
                }
              }
            ></input>
            <ColorPicker
              style={{ display: state.colorPickerShow }}
              theme="dark"
              suckerCanvas="null"
              suckerArea={[]}
              color={componentConfig.value.optionData.label.color}
              onChangeColor={(value) => {
                changeColor('color', value.hex)
                // state.colorPickerShow = 'none'
              }}
              {...(props as any)}
            />
          </div>
        </div>
        <div class="setting-row" v-show={state.typekey}>
          <div class="setting-row__label">字体</div>
          <div class="setting-row__content">
            <Select
              v-model:value={componentConfig.value.optionData.label.fontFamily}
              size="small"
              onChange={(value: string) => handleChange('fontFamily', value)}
            >
              {fontFamilyList.map(item => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              min={11}
              precision={0}
              v-model:value={componentConfig.value.optionData.label.fontSize}
              onChange={value => handleChange('fontSize', value)}
            />
          </div>
        </div>
      </div>
    )

    return () => <SettingItemBox title="数据标签" v-slots={labelSlot} />
  },
})

export const option = {
  label: '标题',
  placeholder: '请选择日期',
  dataset: {
    source: []
  },
  size: 'middle',
  picker: 'date',
  mode: 'date',
  valueFormat: 'YYYY-MM-DD',
  series: [
    {
      type: 'datePicker'
    }
  ]
}

import type { IState } from "@/store/modules/editor"
import { userMapState } from "@/store/userMapper"
import { fontFamilyList } from "@/utils/constance"
import { Input, InputNumber, Select } from "ant-design-vue"
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watchEffect } from "vue"
import { useStore } from "vuex"
import { ColorPicker } from 'vue-color-kit'
import SettingItemBox from '../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import type { PropType } from 'vue'
import { IBaseText } from '@/types'
import _ from 'lodash'
export default defineComponent({
  name: 'GeneralTitle',
  order: 0,
  props: {
    option: {
      type: Object as PropType<IBaseText>,
    },
    staticData: Array
  },
  setup(props, ctx) {
    const instance: any = getCurrentInstance()
    onMounted(() => {
      instance.proxy.$el.__vueComponent = instance
    })
    const state = reactive({
      color: '',
      colorPickerShow: 'none',

    })
    const store = useStore()
    const { mainJson, moveablerRef, selecto }: any = userMapState('editor', [
      'mainJson',
      'moveablerRef',
      'selecto'
    ]) as IState

    const componentConfig = computed(() => {
      return mainJson.value.body.children.find(config => config.id === store.state.editor.componentConfig.id)
    })

    // const handleChangeContent = (value) => {
    //   console.log('value', value, moveablerRef.value.$_moveable.target);
    //   // console.log('componentConfig', componentConfig.value);
    //   // console.log('targetComponent', mainJson.value.body.children)
    //   // const propKeyMap = {
    //   //   show: ['row', 'splitArea', type],
    //   // }

    //   store.dispatch('editor/setMainJsonAttribute', {
    //     id: componentConfig.value.id,
    //     path: ['option', 'generalTitle', 'text'],
    //     value
    //   })
    //   const { targetCharts, options } = useMoveableTarget(moveablerRef.value)
    //   options.generalTitle.text = value
    //   targetCharts.setOption(options)

    // }
    watchEffect(() => {
      state.color = componentConfig.value?.option?.generalTitle?.color
    })

    const handleChange = (type: string, value: string | boolean) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: ['generalTitle', type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: 'generalTitle',
        path: ['option', 'generalTitle', 'color'],
        value
      })

      options.generalTitle.color = value
      targetCharts.setOption(options, false)
    }
    const changeColor = _.debounce(handleChange, 1000)

    const handleChangeStyle = (type, value) => {
      
      const propKeyMap = {
        fontFamily: ['generalTitle', 'fontFamily'],
        fontSize: ['generalTitle', 'fontSize'],
        fontWeight: ['generalTitle', 'fontWeight'],
        fontStyle: ['generalTitle', 'fontStyle'],
        fontAlign: ['generalTitle', 'fontAlign'],
        color: ['generalTitle', 'color'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })
      const { targetCharts, options } = useMoveableTarget(selecto)
      options.generalTitle[type] = value
      targetCharts.setOption(options)
    }
    

    const textSlot = () => (
      <>
      <div class="title-box common-setting-item-box">
        {/* <div class="setting-row">
          <div class="setting-row__label">标题内容</div>
          <div class="setting-row__content">
            <Input
              size="small"
              onBlur={e => handleChangeContent(e.target.value)}
              v-model:value={componentConfig.value.option.dataset.source[0][0]}
              placeholder="请输入标题内容"
            />
          </div>
        </div> */}
        <div class="setting-row">
          <div class="setting-row__label">横向对其</div>
          <div class="setting-row__content">
            <a-radio-group
              default-value="center"
              size="small"
              onChange={(value) => {
                handleChangeStyle('fontAlign', value.target.value)}}
              v-model:value={componentConfig.value.option.generalTitle.fontAlign}
            >
              <a-radio-button value="left">
              left
              </a-radio-button>
              <a-radio-button value="center">
              center
              </a-radio-button>
              <a-radio-button value="right">
              right
              </a-radio-button>
            </a-radio-group>
          </div>
        </div>

        <div class="setting-row">
          <div class="setting-row__label">字体</div>
          <div class="setting-row__content">
            <Select
              v-model:value={componentConfig.value.option.generalTitle.fontFamily}
              size="small"
              onChange={(value: string) => handleChangeStyle('fontFamily', value)}
            >
              {fontFamilyList.map(item => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </div>
          <div class="setting-row__content">
            <InputNumber
              size="small"
              min={11}
              precision={0}
              v-model:value={componentConfig.value.option.generalTitle.fontSize}
              onChange={value => handleChangeStyle('fontSize', value)}
            />
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__content">
            <input type="button" class="colorBtn"
              style={{ backgroundColor: componentConfig.value.option.generalTitle.color }}
              onClick={
                (e) => {
                  state.colorPickerShow = 'block'
                }
              }
              onBlur={
                (e: any) => {
                  // color-picker-box 内元素不触发
                  if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                    if (e.relatedTarget.tagName == 'INPUT') {
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener(
                        'blur',
                        (el: any) => {
                          setTimeout(() => state.colorPickerShow = 'none', 100)
                        }
                      )
                    } else {
                      e.target.focus()
                    }
                    return
                  } else {
                    setTimeout(() => state.colorPickerShow = 'none', 100)
                  }
                }
              }
            ></input>
            <ColorPicker
              style={{ display: state.colorPickerShow }}
              theme="dark"
              suckerCanvas="null"
              suckerArea={[]}
              color={componentConfig.value.option?.generalTitle?.color}
              onChangeColor={(value) => {
                changeColor('color', value.hex)
                // state.colorPickerShow = 'none'
              }}
              {...(props as any)}
            />
          </div>
        </div>

      </div>
      </>
    )

    return () => componentConfig.value ? <SettingItemBox title="文本设置" v-slots={textSlot} /> : null
  }
})

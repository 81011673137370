export const option = {
  dataset: {
    source: []
  },
  model:[],
  innerKey:[],
  bordered: false,
  column: 3,
  layout: 'horizontal',
  size: 'default',
  propertiesConfig: true
}

export const option = {
  inner: '查询',
  dataset: {
    source: []
  },
  size: 'middle',
  series: [
    {
      type: 'button'
    }
  ]
}

export const option = {
  label: '标题',
  dataset: {
    source: []
  },
  size: 'middle',
  picker: 'date',
  mode: ['date', 'date'],
  valueFormat: 'YYYY-MM-DD',
  series: [
    {
      type: 'rangePicker'
    }
  ]
}

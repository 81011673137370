export const option = {
  backgroundColor: 'transparent',
  tooltip: {
    formatter: (params) => {
      if (params.seriesName !== 'mouseoutSeries') {
        return `${
          params.seriesName
        }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
          params.color
        };"></span>${option.series[params.seriesIndex].pieData.value}`;
      }
    }
  },
  xAxis3D: {
    name: '',
    type: 'category'
  },
  yAxis3D: {
    name: '',
    type: 'category',
    data: ['']
  },
  zAxis3D: {
    type: 'value',
    name: ''
  },
  grid3D: {
    boxWidth: 200,
    boxDepth: 30,
    axisPointer: {
      show: false
    },
    light: {
      main: {
        intensity: 1.2
      },
      ambient: {
        intensity: 0.3
      }
    },
    viewControl: {
      alpha: 10,
      beta: 20
    }
  },
  grid: [
    {
      top: '15%',
      left: '20%',
      bottom: '15%',
      right: '15%'
    }
  ],
  series: [
    {
      type: 'bar3D',
      barSize: 15,
      stack: 'stack',
      shading: 'lambert',
      itemStyle:{
        opacity : 0.8
      },
      encode: {
        z: [1]
      }
    }
  ],
  label: {},
  title: [{}],
  resize: [false,false]
}

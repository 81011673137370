import { computed, defineComponent, onUnmounted, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import { Select } from 'ant-design-vue'
import { typeList } from '../data'
import useSetAttribute from '@/hooks/useSetAttribute'
import SettingItemRow from '../../settingItemRow'
import '../index.less'
import { ISelectList } from '@/types'
import DataModModal from '@/pages/page-editor/components/DataModModal/index.vue'
import { getQueryVariable } from '@/utils'
import { useRoute } from 'vue-router'

interface IStateReactive {
  isError: boolean
  errTimer: any
  errMsg: string
  showDataModModal: boolean
  pageId: string
}

const { Option } = Select
const supportChartTypes: string[] = ['chart-pie', 'chart-bar', 'chart-line', 'flap']
const MODEL_B: ISelectList = {
  label: 'Mod',
  value: 'mod',
}

export default defineComponent({
  name: 'Type',
  order: 1,
  setup() {
    const store = useStore()
    const route = useRoute()
    const setAttribute = useSetAttribute(store)
    const { componentConfig, mainJson }: any = userMapState('editor', ['componentConfig', 'mainJson']) as IState

    const state = reactive<IStateReactive>({
      isError: false,
      errMsg: '',
      errTimer: null,
      showDataModModal: false,
      pageId: (route.query.pageid || sessionStorage.getItem('pageId')) as string
    })
    //是否有选中的数据
    const currentId = computed<string>(() => componentConfig.value.id)
    //当前组件的类型
    const componentType = computed<string>(() => componentConfig.value.type)
    //页面级数据源
    const pageDataSource = computed<string>(() => mainJson.value.pageDataSource)
    //类型下拉options
    const typeOptions = computed<ISelectList[]>(() =>
      supportChartTypes.includes(componentType.value)
        ? [...typeList, MODEL_B]
        : typeList
    )

    const modModalTitle = computed<string>(() => componentConfig.value.anotherName || '未命名组件')

    watchEffect(() => {
      if (currentId.value) {
        state.isError = false
      }
    })

    //设置 选项报错信息
    const setError = (msg: string) => {
      state.isError = true
      state.errMsg = msg
      componentConfig.value.api.type = typeList[0].value
      clearApiConfig()
      if (state.errTimer) clearTimeout(state.errTimer)
      state.errTimer = setTimeout(() => {
        state.isError = false
      }, 1000 * 5)
    }
    //打开 mod 配置 弹框
    const handleOpenMod = (type: string) => {
      if (type === MODEL_B.value) {
        if (!pageDataSource.value || pageDataSource.value === '0') {
          setError('请先设置页面级数据源再选择Mod')
          return
        }
        state.showDataModModal = true
      }
    }

    //选择属性
    const handleChangeAttribute = (attribute: string, value: any) => {
      const setAttribute = useSetAttribute(store)
      setAttribute(currentId.value, attribute, value)
    }

    //清空API配置项
    const clearApiConfig = () => {
      setAttribute(currentId.value, 'dataSourceId', '')
      setAttribute(currentId.value, 'dataNode', '')
    }
    //修改数据类型
    const handleChangeType = (value: string) => {
      if (value === 'auto') {
        if (!pageDataSource.value || pageDataSource.value === '0') {
          setError('请先设置页面级数据源再选择Auto')
          return
        }
        if (!componentConfig.value.anotherName) {
          setError('请先设置组件别名再选择Auto')
          return
        }
      }

      state.isError = false
      handleChangeAttribute('type', value)
      handleOpenMod(value)
      // if (value !== 'api') {
      //   clearApiConfig()
      // }
      clearApiConfig()
    }

    //保存 data mod 后
    const handleSavedDataMod = (apiId: string) => {
      setAttribute(currentId.value, 'dataSourceId', apiId)
    }

    onUnmounted(() => {
      state.errTimer = null
    })


    return () => (
      <>
        <DataModModal
          visible={state.showDataModModal}
          title={modModalTitle.value}
          type={componentType.value}
          dataSourceId={pageDataSource.value}
          componentId={currentId.value}
          pageId={state.pageId}
          apiId={componentConfig.value.api.dataSourceId}
          onClose={() => state.showDataModModal = false}
          onSaved={handleSavedDataMod}
        />
        <SettingItemRow title={'类型'}>
          <Select size="small" v-model:value={componentConfig.value.api.type}
                  class={state.isError ? 'error-form-item' : ''}
                  onSelect={(v) => handleOpenMod(v)}
                  onChange={handleChangeType}>
            {
              typeOptions.value.map(item => (
                <Option value={item.value}>{item.label}</Option>
              ))
            }
          </Select>
          {
            state.isError ? <div class="error-alert">{state.errMsg}</div> : ''
          }
        </SettingItemRow>
      </>

    )
  }
})

import useMoveableTarget from '@/hooks/useMoveableTarget'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { InputNumber, Switch } from 'ant-design-vue'
import { defineComponent, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { ColorPicker } from 'vue-color-kit'
import SettingItemBox from '../../../settingItemBox'
import _ from 'lodash'

export default defineComponent({
  name: 'Row',
  order: 6,
  setup(props) {
    const store = useStore()
    const { componentConfig, moveablerRef, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto'
    ]) as IState
    const state = reactive({
      color: '',
      colorPickerShow: 'none',
    })
    const handleChange = (type: string, value: any) => {

      const propKeyMap = {
        show: ['row', 'splitArea', 'show'],
        rowBackground: ['row', 'rowBackground'],
        rowAutoHeight: ['row', 'rowAutoHeight'],
        rowHeight: ['row', 'rowHeight'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })


      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })
      const { targetCharts, options } = useMoveableTarget(selecto)
      if(type == 'show') options.row.splitArea[type] = value
      if(type == 'rowAutoHeight') options.row[type] = value
      if(type == 'rowHeight') options.row[type] = value
      targetCharts.setOption(options)
    }
    const handleRowColorChange = (type: string, value: string | boolean) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: ['row', type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: 'row',
        path: ['option', 'row', 'rowBackground'],
        value: componentConfig.value.optionData.row.rowBackground,
      })

      options.row.rowBackground = value
      targetCharts.setOption(options, false)
    }
    const changeColor = _.debounce(handleRowColorChange, 1000)



    watchEffect(() => {
      state.color = componentConfig.value?.optionData?.row?.rowBackground
    })

    const rowSlot = () => (
      <>
        <div class="setting-row">
          <div class="setting-row__label">轴显示</div>
          <div class="setting-row__content">
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.row.splitArea.show}
              onChange={value => handleChange('show', value)} />
          </div>
        </div>


        <div class="setting-row">
          <div class="setting-row__label">轴色彩</div>
          <div class="setting-row__content">
            <input type="button" class="colorBtn"
              style={{ backgroundColor: componentConfig.value.optionData.row.rowBackground }}
              onClick={
                (e) => {
                  state.colorPickerShow = 'block'
                }
              }
              onBlur={
                (e: any) => {
                  // color-picker-box 内元素不触发
                  if (e.relatedTarget && e.relatedTarget.parentElement.className.indexOf('color') != '-1') {
                    if (e.relatedTarget.tagName == 'INPUT') {
                      // 监听 e.relatedTarget 失去焦点
                      e.relatedTarget.addEventListener(
                        'blur',
                        (el: any) => {
                          setTimeout(() => state.colorPickerShow = 'none', 100)
                        }
                      )
                    } else {
                      e.target.focus()
                    }
                    return
                  } else {
                    setTimeout(() => state.colorPickerShow = 'none', 100)
                  }
                }
              }
            ></input>
            <ColorPicker
              style={{ display: state.colorPickerShow }}
              theme="dark"
              suckerCanvas="null"
              suckerArea={[]}
              color={componentConfig.value.optionData.row.rowBackground}
              onChangeColor={(value) => {
                const color = `rgba(${value.rgba.r},${value.rgba.g},${value.rgba.b},${value.rgba.a})`
                changeColor('rowBackground', color)
              }}
              {...(props as any)}
            />
          </div>
        </div>
        <div class="setting-row">
          <div class="setting-row__label">自动高</div>
          <div class="setting-row__content" style={{"flex":"1"}}>
            <Switch
              size="small"
              v-model:checked={componentConfig.value.optionData.row.rowAutoHeight}
              onChange={value => handleChange('rowAutoHeight', value)} />
          </div>
          <div class="setting-row__content" style={{"flex":"2"}}>
            {
              componentConfig.value.optionData.row.rowAutoHeight ? '' : <InputNumber
                size="small"
                addonBefore="H"
                addonAfter="px"
                v-model:value={componentConfig.value.optionData.row.rowHeight}
                onChange={value => handleChange('rowHeight', value)}
              />
            }

          </div>
        </div>
      </>
    )

    return () => <SettingItemBox title="行" v-slots={rowSlot} />
  },
})

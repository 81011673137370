import { defineComponent, ref, onMounted } from "vue";
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue';
import './index.less'



interface IProps {
  title?: string,
  order?: number
}

export default defineComponent({
  name: 'SettingItemBox',
  props: {
    title: {
      type: String,
      default: '默认标题'
    },
    order: {
      type: Number,
      default: 0
    }
  },
  setup(props: IProps, { slots }) {
    const content: any = ref('')
    const title: any = ref('')
    const flag: any = ref(false)

    onMounted(() => {
      if (props.order > 9) {
        content.value.style.display = 'none'
        title.value.firstChild.className = 'arrowTransform'
      }
    })

    const collapseBox = (event) => {
      flag.value = !flag.value
      content.value.style.display = flag.value ? 'none' : 'block'
      event.currentTarget.firstChild.className = flag.value ? 'arrowTransform' : 'arrowTransformReturn'

    }
    return () => (
      <>
        <div class="setting-box ">
          <h4 ref={title} class="setting-box__title" onClick={collapseBox}><i><DownOutlined /></i><span>{props.title}</span></h4>
          <div ref={content} class="setting-box__content">{slots.default?.()}</div>
        </div>
      </>
    )
  }
})



//"matrix(0.707107, -0.707107, 0.707107, 0.707107, -5, -5)"

//transform: "matrix(-0.707107, 0.707107, -0.707107, -0.707107, -5, -5)"
/**
 * @description 描述
 * @param {String}  axisKey [name] x轴显示的字段
 * @param {String}  seriesMapKey [value] 系列映射字段
 * @param {String}  seriesKey [_serie] 系列标识
 */
export const option = {
  dataset: {
    // todo 数据返回在source中
    source: []
  },
  config: {
    axisKey: 'name',
    seriesMapKey: 'value',
    seriesKey: '_serie',
    stackKey: '_stack',
    mainAxis: 'x',
    seriesConfig: []
  },
  // 图基本配置
  baseOptions: {
    backgroundColor: 'transparent',
    legend: {
      show: true,
      textStyle: {
        color: '#fff'
      }
    },
    grid: {
      right: '10%',
      bottom: '8%',
      left: '10%',
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: [],
      axisLabel: {
        color: '#fff'
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#fff'
      }
    },
    series: []
  }
}

import { computed, defineComponent, nextTick, onMounted, reactive, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import SettingItemBox from '../../settingItemBox'
import SettingItemRow from '../../settingItemRow'
import { Input, RadioGroup, Select, Switch } from 'ant-design-vue'
import useSetAttribute from '@/hooks/useSetAttribute'

const { Option } = Select
export default defineComponent({
  name: 'ParamsConfig',
  setup() {
    const store = useStore()
    const {
      componentConfig,
      componentsList,
      infiniteRef
    }: any = userMapState(
      'editor',
      ['componentConfig', 'componentsList', 'infiniteRef']
    ) as IState

    const selectCompRef = ref<any | null>(null)

    const state = reactive({
      radioGroupValue: 0,
      triggerModeList: [
        { label: '立即触发', value: 0 },
        { label: '点击查询触发', value: 1 }
      ],
      overDownStyle: 0,
    })
    const isOutput = computed(() => componentConfig.value.optionData.processType === 'output')
    const paramsConfig = computed(() => componentConfig.value.api.paramsConfig)
    // const relevanceList = computed(() => componentsList.value.filter((item: any) =>
    //   !item.type.includes('form')
    // ))  && item.option.type === "antd-table"

    const _processType = computed(() => componentConfig.value.optionData.processType)
    // 复杂表格转转成 输入类型 关联列表去掉自己
    const componentsLists = computed(() => componentsList.value.filter((item: any) => {
      if (componentConfig.value.type === 'antd-table' && item.option.processType === 'entry' && componentConfig.value.id === item.id) {
        return false
      } else {
        return item
      }
    }))
    const relevanceList = computed(() => componentsLists.value.filter((item: any) => {
        return item.option.processType === 'display' || (item.option.processType === 'entry' && item.option.type === 'antd-table')
      }
    ))
    //是否有选中的数据
    const currentId = computed(() => componentConfig.value.id)
    const associateStream = computed(() => isOutput.value ? paramsConfig.value.upStream : paramsConfig.value.downStream)

    //是否是输入框 或者 下拉框
    const isInputOrSelect = computed(() => componentConfig.value.type.includes('form-'))

    //选择属性
    const handleChangeAttribute = (attribute: string, value: any) => {
      const setAttribute = useSetAttribute(store)
      setAttribute(currentId.value, ['paramsConfig', attribute], value)
    }

    //处理关联组件
    const handleChangeComponents = (attribute, stream) => {
      if (!currentId.value) return
      store.commit('editor/SET_COVER_STREAM', {
        attribute,
        stream
      })
      // store.dispatch('editor/setMainJsonAttribute', {
      //   id: hasSelected.value,
      //   path: ['api', attribute],
      //   value: value
      // })
    }

    // 关联组件 设置别名
    const setAnotherName = item => {
      const target = item?.target ? item?.target : item.type
      return item?.anotherName ? (item.anotherName + '-' + item.id) : (target + '-' + item.id)
    }

    const setClickQuery = () => {
      if (isOutput.value) {
        handleChangeAttribute('actionEvent', 1)
      }
    }

    watchEffect(() => {
      if (currentId.value) {
        setClickQuery()
      }
    })

    onMounted(() => {

    })

    const ActionEventSlot = () => (
      <SettingItemRow title={'触发方式'}>
        <RadioGroup
          size="small"
          v-model:value={componentConfig.value.api.paramsConfig.actionEvent}
          optionType={'button'}
          options={state.triggerModeList}
          onChange={(value) => handleChangeAttribute('actionEvent', value.target.value)}
        />
      </SettingItemRow>
    )

    const FixedParamsSlot = () => (
      <>
        <SettingItemRow title={'固定参数'}>
          <Switch
            size="small"
            v-model:checked={componentConfig.value.api.paramsConfig.fixedParams}
            onChange={(e) => {
              handleChangeAttribute('fixedParams', e)
            }}/>
        </SettingItemRow>
      </>
    )

    const paramsConfigSlot = () => (
      <>
        <SettingItemRow title={'传入参数'}>
          <Input
            size="small"
            v-model:value={paramsConfig.value.parameter}
            onChange={(e) => {
              handleChangeAttribute('parameter', e.target.value)
            }}
          />
        </SettingItemRow>
        <SettingItemRow title={'关联组件'}>
          <Select
            ref={selectCompRef}
            mode="multiple"
            placeholder="请选择绑定组件"
            size="small"
            style={{ width: '204px' }}
            dropdownAlign={{ offset: [state.overDownStyle, 4] }}
            dropdownStyle={{ width: 'auto' }}
            dropdownMatchSelectWidth={false}
            notFoundContent="无可绑定组件"
            v-model:value={associateStream.value}
            onChange={(value) => {
              console.log(value, '---------------value---------------')
              if (_processType.value === 'entry') {
                handleChangeAttribute('downStream', value)
                handleChangeComponents('upStream', { 'up': componentConfig.value.id, 'down': value })
              } else if (_processType.value === 'output') {
                handleChangeAttribute('upStream', value)
                handleChangeComponents('downStream', { 'up': value, 'down': componentConfig.value.id })
              } else {
                console.log(value, '---------------value---------------')
              }
            }}
            // onMouseenter={e=>{
            //   console.log('onMouseenter:',e)
            // }}
            // onChange={(value) => handleChangeComponents('type', value)}
            getPopupContainer={(triggerNode) => {
              nextTick(() => {
                // console.log('triggerNode.parentNode------------------', triggerNode.parentNode)
                const ob = triggerNode.parentNode.querySelector('.rc-virtual-list-holder-inner')
                if (!ob) return
                setTimeout(() => {
                  state.overDownStyle = selectCompRef.value?.$el.offsetWidth - parseInt(window.getComputedStyle(ob, null).width)
                })
              })
              return triggerNode.parentNode
            }}
          >
            {
              relevanceList.value?.map(item => (
                <Option value={item.id} onMouseenter={e => {
                  // container e.target.innerText.match(/- (.*)/)[1] ||
                  console.log('onMouseenter:', e.target.innerText.split('-')[1])
                  let _id = e.target.innerText.split('-')[1]
                  let _target: any = document.querySelector(`#${_id}`).parentNode
                  let _top = (_target.offsetTop + document.getElementById('container').scrollTop) - ((document.body.offsetHeight - _target.offsetHeight) / 2)
                  let _left = (_target.offsetLeft + document.getElementById('container').scrollLeft) - ((document.body.offsetWidth - _target.offsetWidth) / 2)
                  infiniteRef.value.scrollTo(_left, _top)
                  _target.classList.add('hotTarget')
                }}
                        onMouseleave={e => {
                          console.log(e, e.target.innerText.split('-')[1], 'eeee')
                          let _id = e.target.innerText.split('-')[1]
                          let _target: any = document.querySelector(`#${_id}`).parentNode
                          _target.classList.remove('hotTarget')
                        }}
                >{setAnotherName(item)}</Option>
              ))
            }
          </Select>
        </SettingItemRow>
        {!isOutput.value ? <ActionEventSlot/> : null}
        {isInputOrSelect.value ? <FixedParamsSlot/> : null}
      </>
    )

    return () => <SettingItemBox title="关联参数" v-slots={paramsConfigSlot}/>

  }
})

export default function useSetAttribute(store) {
  return (id: string, attribute: string | Array<string>, value: any) => {
    store.commit('editor/SET_API_ATTRIBUTE', {
      attribute,
      value
    })
    let path = Array.isArray(attribute)
      ? ['api', ...attribute]
      : ['api', attribute]
    store.dispatch('editor/setMainJsonAttribute', {
      id,
      path,
      value
    })
  }
}

import { computed, defineComponent, onMounted, reactive } from 'vue'
import LocationSize from './locationSize'
import Background from './background'
import { userMapState } from '@/store/userMapper'
import { IComponentConfig, IState } from '@/store/modules/editor'
import { getComponentsType } from '@/utils'

// 当前组件配置信息
interface IComponent {
  name: string
  order: number
  component: any
}
export default defineComponent({
  name: 'styleTabPane',
  setup() {
    // 当前选中的组件配置
    const { componentConfig }: any = userMapState('editor', [
      'componentConfig',
    ]) as IState

    // 所有注册的属性组件列表(静态数据不需要ref或者reactive包裹)
    let allComponents: IComponent[] = []

    // 初始化
    onMounted(() => {
      // 初始化组件列表
      getComponents()
      // console.log('components all', allComponents)
    })

    // 首字母大写
    const firstCapitalize = (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    // 组件排序
    const sortComponent = components => {
      return components.sort((a, b) => a.order - b.order)
    }

    // 获取组件列表
    const getComponents = () => {
      const files = require.context(
        "./optionsProperty",
        true,
        /^.*\.(tsx|vue|ts)$/
      );
      const _components = files.keys().map(file => {
        // "./charts/time.tsx" ==> ChartTime
        // 去除 ./ 再去除结尾的 .tsx .vue .ts
        // const componentPath = file.slice(2).slice(0, -4)
        const componentPath = file
          .replace(/^\.\//, '')
          .replace(/\.(tsx|vue|ts)$/, '')
        // 组件名称
        const name = componentPath
          .split('/')
          .map(item => firstCapitalize(item))
          .join('')
        // 组件实例
        const component = files(file).default
        // 排序属性
        const { order } = component
        return {
          name,
          order,
          component,
        }
      })

      allComponents = sortComponent(_components)
    }

    // 是否有位置尺寸信息
    const hasLocationSize = computed(
      () => Object.keys(componentConfig.value.locationSize).length
    )

    // 是否显示颜色或背景
    const hasBackground = computed(
      () =>
        componentConfig.value.backgroundImage ||
        componentConfig.value.backgroundColor
    )

    /**
     * @description 生成对应组件应有属性配置的组件
     * @param {Object} componentConfig 组件配置
     * @returns {Array} 对应组件应有属性配置的组件
     */

    const createPropertyComponent = ({
      type,
      optionData,
    }: IComponentConfig = {}): IComponent[] => {
      // console.log('optionData', optionData, componentConfig.value)
      let optionType
      const strType = getComponentsType(type, 1)
      if(strType[0] === 'chart') {
        optionType = Reflect.ownKeys(optionData).filter((item:any) => {
          if(optionData[item]?.length !== 0) return optionData[item]
        })
      } else {
        optionType = Object.keys(optionData)
      }
      // console.log('optionType', optionType,optionData)
      // 属性组件名称 = 类型 + 属性
      // console.log(strType, type, "// 属性组件名称 = 类型 + 属性")
      const optionsKeys = optionType.map(
        (propertyName:any) =>
          `${firstCapitalize(strType[0])}${strType[1] && strType[0] !=='chart' ? firstCapitalize(strType[1]) : ''}${firstCapitalize(propertyName)}`
      )
      // 当前组件应有的属性配置组件
      const propertyComponents = allComponents.filter(component => {
       
        return optionsKeys.includes(component.name)
      })
      // console.log('propertyComponents', propertyComponents)
      return propertyComponents || []
    }
    return () => (
      <>
        {/* 位置尺寸 */}
        {hasLocationSize.value ? <LocationSize /> : null}

        {/* 颜色或背景 */}
        {hasBackground.value ? <Background /> : null}

        {/* 该组件对应的属性配置组件 */}
        {createPropertyComponent(componentConfig.value).map(componentInfo => (
          <componentInfo.component />
        ))}
      </>
    )
  },
})

import useMoveableTarget from '@/hooks/useMoveableTarget'
import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { InputNumber, Select, Switch } from 'ant-design-vue'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../settingItemBox'
import { fontFamilyList } from '@/utils/constance'

export default defineComponent({
  name: 'column',
  order: 6,
  setup() {
    const store = useStore()
    const { componentConfig, moveablerRef, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'selecto'
    ]) as IState

    const handleChange = (type: string, value: any) => {
      const propKeyMap = {
        splitAreaShow: ['column', '0', 'splitArea', 'show'],
      }

      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: propKeyMap[type],
        value,
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        path: ['option', ...propKeyMap[type]],
        value
      })

      const { targetCharts, options } = useMoveableTarget(selecto)
      options.title = componentConfig.value.optionData.title
      console.log('options', options)
      targetCharts.setOption(options, false)
    }

    const columnSlot = () => (
      <div class="setting-row">
        <div class="setting-row__label">轴背景</div>
        <div class="setting-row__content">
          <Switch
            size="small"
            v-model:checked={componentConfig.value.optionData.column.splitArea.show}
            onChange={value => handleChange('splitAreaShow', value)}
          />
        </div>
      </div>
    )

    return () => <SettingItemBox title="列" v-slots={columnSlot} />
  },
})

import * as echarts from 'echarts'
import { seriesOption } from '@/utils/const'
import { getComponentsType } from '@/utils'
export default function useMoveableTarget(selecto) {
  const selectedNode =  selecto.value.selectedTargets
  if(selectedNode.length === 0) return null
  let targetCharts, options
  const strType = getComponentsType(selectedNode[0].getAttribute('type'), 1)
  if ( strType[0] == 'chart') {
    targetCharts = echarts.getInstanceByDom(selectedNode[0]?.firstChild)
    
    
    // let datas = selectedNode[0]?.firstChild.__vueParentComponent.attrs.staticData
    options = targetCharts?.getOption() as echarts.EChartsOption
    console.log(options)
    if(options.dataset != undefined) targetCharts.setOption(seriesOption(options, options.dataset[0]), false)
  } else {
    targetCharts = selectedNode[0]?.firstChild.__vueComponent
    options = targetCharts.props.option
    targetCharts.setOption(options, false)
  }
  // options.grid[0][type] = value.target._value
  
  return {
    targetCharts, options, selectedNode
  }
}
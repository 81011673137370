import { computed, defineComponent, reactive, onMounted, watch, nextTick, ref } from 'vue'
import { userMapState } from '@/store/userMapper'
import { IState } from '@/store/modules/editor'
import SettingItemBox from '../../settingItemBox'
import { Button, message, Modal, Textarea } from 'ant-design-vue'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons-vue'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { cloneDeep, debounce } from 'lodash'
import { useStore } from 'vuex'
import { VAceEditor } from 'vue3-ace-editor'
import { seriesOption } from '@/utils/const'
import { jsEditorConfig } from '../data'
import { updateChartsData } from '@/utils/updateChartsData'
import { getComponentsType, getComponentsFormat, converData } from '@/utils'
import RequestApi from '@/utils/RequestApi'

// ace组件类型
type VAceEditorCtx = InstanceType<typeof VAceEditor>

enum StaticDataType {
  Array = 'array',
  Object = 'object'
}

type Data = {
  data: any[]
}

type SourceArray = any[]

type SourceObject = {
  dataset: Data
}

type StaticData = {
  source: SourceArray | SourceObject
}

type MockStaticData = {
  staticData: StaticData
}

export default defineComponent({
  name: 'MockEditor',
  order: 5,
  components: {
    VAceEditor,
  },
  setup() {
    const store = useStore()
    const {
      componentConfig,
      selecto,
      mainJson
    }: any = userMapState(
      'editor',
      ['componentConfig', 'selecto', 'mainJson']
    ) as IState

    const state = reactive({
      jsEditorConfig,
      visible: false,
      staticDataType: '',
      mockDataStr: '',
      clipboardStr: '',
      showMockModal: false,
    })

    const vAceEditorMiniRef = ref<VAceEditorCtx | null>(null)

    watch(
      () => componentConfig.value.id,
      () => init()
    )

    onMounted(() => {
      init()
    })

    // 获取staticData参数类型
    const getStaticDataType = (staticData: Record<string, any>) => {
      const { source } = staticData
      state.staticDataType = Array.isArray(source) ? StaticDataType.Array : StaticDataType.Object
    }

    const init = () => {
      if (!hasSelected.value) return
      nextTick(() => {
        const component = mainJson.value.body.children.find(item => item.uid === hasSelected.value)
        const { staticData } = component
        getStaticDataType(staticData)
        staticData && (state.mockDataStr = JSON.stringify({ staticData }, null, 2))
      })
    }

    const hasSelected = computed(() => {
      return componentConfig.value.id
    })

    //是否有选中的数据
    const currentId = computed(() => componentConfig.value.id)

    const dataSourceId = computed(() => componentConfig.value.api.dataSourceId)

    const isMock = computed(() => componentConfig.value.api.type === 'mock')

    const isApi = computed(() => ['api', 'mod'].includes(componentConfig.value.api.type))

    const isDark = computed(() => mainJson.value.isDark)

    // 如果是数组
    const isArray = computed(() => state.staticDataType === StaticDataType.Array)

    const pasteHandler = async () => {
      state.visible = true
    }

    const handleEditMock = () => {
      state.showMockModal = true
    }

    const handleCancelMock = () => {
      state.showMockModal = false
    }

    const handleOk = () => {
      saveMockData()
    }

    const pasteCover = async () => {
      let textPlain = state.clipboardStr
      parseMockData(textPlain).then(() => {
        state.visible = false
      })
    }

    // 组装二维数组
    const assembleDoubleArray = (toArr: string[]) => {
      const mockStatic: MockStaticData = {
        staticData: {
          source: []
        }
      }
      let res = [] as Array<any>
      toArr.forEach((item) => res.push(item.replace(/(^\s*)|(\s*$)/g, '').replace(/\s+/g, ' ').split(' ')))
      mockStatic.staticData.source = res
      return mockStatic
    }

    // 组装对象数组
    const assembleArray = (toArr: string[]) => {
      const mockStatic: MockStaticData = {
        staticData: {
          source: {
            dataset: {
              data: []
            }
          }
        }
      }
      const [keysStr, ..._rows] = toArr

      const keys = keysStr.split('\t')
      const rows = _rows.map(row => row.split('\t')) || []

      const res = Object.values(rows).map(row => {
          let _row: Record<string, any> = {}
          ;(row as unknown as Array<any>).forEach((value, index) => {
            const key = keys[index] as string
            _row[key] = value
          })
          return _row
        })

      ;(mockStatic.staticData.source as SourceObject).dataset.data = res
      return mockStatic
    }

    //mock数据转换
    const parseMockData = (data) => new Promise((resolve, reject) => {
      try {
        const toArr = data.split('\n')
        let mockDataStr = ''
        if (isArray.value) {
          mockDataStr = JSON.stringify(assembleDoubleArray(toArr), null, 2)
        } else {
          mockDataStr = JSON.stringify(assembleArray(toArr), null, 2)
        }
        state.mockDataStr = mockDataStr
        resolve(state.mockDataStr)
      } catch (error) {
        message.error('剪切板解析失败，请检查数据格式')
        console.log('error', error)
        reject(error)
      }
      return true
    })

    //保存mock数据
    const saveMockData = () => {
      let res
      try {
        res = JSON.parse(state.mockDataStr)
      } catch (error) {
        message.error('数据格式不正确')
        return
      }
      const { staticData } = res
      store.dispatch('editor/setMainJsonAttribute', {
        id: currentId.value,
        path: ['staticData'],
        clipboardStr: '',
        value: staticData
      })
      // if (componentConfig.value.type == 'chart') {
      //   setChartsData(staticData)
      // } else {
      //   setStaticData(staticData)
      // }
    }

    //设置 chart 数据
    const setChartsData = (data) => {
      const { targetCharts, options } = useMoveableTarget(selecto)
      //通过 element 获取组件实例
      const deepOpt = cloneDeep(options)
      // const dimensionLength = data.source[0].length - 1
      // const _seriesLength = deepOpt.series.length

      // console.log('dimensionLength', dimensionLength)
      // console.log('_seriesLength', _seriesLength)

      deepOpt.dataset = data

      // let dataset = deepOpt.dataset = data
      // targetCharts.setOption(deepOpt, true) // 更新数据
      console.log('data', data, deepOpt)
      targetCharts.setOption(seriesOption(deepOpt, data, 'edit'), true)
    }

    //设置静态数据
    const setStaticData = (data) => {
      if (!data) return
      const { targetCharts } = useMoveableTarget(selecto)
      const { optionData: options } = componentConfig.value
      const hasAntdTable = options.type === 'antd-table'
      const deepOpt = cloneDeep(options)
      targetCharts.props.staticData = data
      if (deepOpt.dataset) deepOpt.dataset['source'] = hasAntdTable ? { columns: [], dataset: { data: [] } } : []
      targetCharts.setOption(deepOpt, false)
    }

    // 复制数据至Mock
    const handleCopyData = () => {
      if (isApi.value && !dataSourceId.value) {
        message.warning('未选择数据源！请先选择数据源')
        return
      }
      const controlCode = componentConfig.value.id
      const requestApi = new RequestApi({
        dataSourceId: dataSourceId.value,
        controlCode,
        dataNode: componentConfig.value.api.dataNode,
        anotherName: componentConfig.value.anotherName,
        apiType: componentConfig.value.api.type,
        objectFormat: getComponentsFormat(componentConfig.value.type, componentConfig.value.api.type),
        reloadCallBack: (res: any) => {
          console.log('reloadCallBack', res)
          const { data } = res
          let _data = converData(data, getComponentsFormat(componentConfig.value.type, componentConfig.value.api.type))
          let staticData = { 'source': _data || [] }
          state.mockDataStr = JSON.stringify({ staticData }, null, 2)
          saveMockData()
          const strType = getComponentsType(componentConfig.value.type, 1)
          if (strType[0] == 'chart') {
            setChartsData(staticData)
          } else {
            setStaticData(staticData)
          }
        }
      })
      requestApi.request()
    }

    const handleChange = debounce(() => {
      // console.log('handleChange', state.mockDataStr)
      let _data = JSON.parse(state.mockDataStr)['staticData']['source']
      _data = converData(_data, getComponentsFormat(componentConfig.value.type, componentConfig.value.api.type))
      let staticData = { 'source': _data }
      const strType = getComponentsType(componentConfig.value.type, 1)
      if (strType[0] == 'chart') {
        setChartsData(staticData)
      } else {
        setStaticData(staticData)
      }
      saveMockData()
    }, 10)

    const pasteDataModal = () => (
      <Modal v-model:visible={state.visible} title="剪切板粘贴" onOk={pasteCover}>
        <Textarea
          v-model:value={state.clipboardStr}
          placeholder="将Excel数据粘贴到此处"
          auto-size
        />
      </Modal>
    )

    const mockDataModal = () => (
      <>
        <Modal
          title="配置Mock数据"
          width={'80%'}
          v-model:visible={state.showMockModal}
          onOk={handleOk}
          destroyOnClose
          v-slots={{
            footer: () => (
              <Button
                type="primary"
                onClick={handleCancelMock}
                v-slots={{ icon: () => <FullscreenExitOutlined/> }}/>
            )
          }}
        >
          <VAceEditor
            value={state.mockDataStr}
            v-model:value={state.mockDataStr}
            lang={state.jsEditorConfig.lang}
            theme={isDark.value ? state.jsEditorConfig.theme : 'chrome'}
            options={state.jsEditorConfig.options}
            style={{
              height: '80vh',
              width: '100%',
            }}
          />
        </Modal>
      </>
    )


    const btnSlot = () => (
      <div style={{ display: 'flex' }}>
        {/*<Button style={{ marginBottom: '10px' }} block type="primary" onClick={handleEditMock}>配置Mock数据</Button>*/}
        <Button style={{ margin: '10px 5px' }} type="primary" onClick={pasteHandler}>粘贴剪切板</Button>
        <Button style={{ margin: '10px 5px' }} disabled={isMock.value} type="primary"
                onClick={handleCopyData}>复制数据至Mock</Button>
      </div>
    )


    return () => (
      <>
        <pasteDataModal/>
        <mockDataModal/>
        <SettingItemBox title={'Mock配置'}>
          <btnSlot/>
          <div class="editor-box" style={{ position: 'relative', height: '100%' }}>
            <VAceEditor
              v-model:ref={vAceEditorMiniRef}
              value={state.mockDataStr}
              onChange={handleChange}
              v-model:value={state.mockDataStr}
              lang={state.jsEditorConfig.lang}
              theme={isDark.value ? state.jsEditorConfig.theme : 'chrome'}
              options={state.jsEditorConfig.options}
              style={{
                height: '500px',
                width: '100%',
              }}
            />
            <Button
              style={{ position: 'absolute', bottom: '10px', right: '15px' }}
              type="primary"
              // size="small"
              onClick={handleEditMock}
              v-slots={{ icon: () => <FullscreenOutlined/> }}/>
          </div>
        </SettingItemBox>
      </>
    )
  }
})



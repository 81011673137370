import { IState } from '@/store/modules/editor'
import { userMapState } from '@/store/userMapper'
import { defineComponent, reactive, ref, Ref, computed } from 'vue'
import { useStore } from 'vuex'
import SettingItemBox from '../../../../settingItemBox'
import useMoveableTarget from '@/hooks/useMoveableTarget'
import { Select } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'



export default defineComponent({
  name: 'formMode',
  order: 5,
  setup(props, ctx) {
    const store = useStore()
    const { componentConfig, moveablerRef, mainJson, selecto }: any = userMapState('editor', [
      'componentConfig',
      'moveablerRef',
      'mainJson',
      'selecto'
    ]) as IState
    const state = reactive({

    })

    //是否有选中的数据
    const hasSelected = computed(() => {
      return componentConfig.value.id
    })

    const handleChange = (type: string, value: any) => {
      if (!moveablerRef) return
      const { targetCharts, options } = useMoveableTarget(selecto)
      store.commit('editor/SET_OPTIONS_ATTRIBUTE', {
        attribute: [type],
        value
      })

      store.dispatch('editor/setMainJsonAttribute', {
        id: componentConfig.value.id,
        target: 'option',
        key: type,
        path: ['option', type],
        value: componentConfig.value.optionData[type],
      })

      options[type] = value
      targetCharts.setOption(options, false)
    }

    // 边框插槽
    const optionsSlot = () => (
      <div class="common-setting-item-box">
        <div class="setting-row">
          <div class="setting-row__label">选择模式</div>
          <div class="setting-row__content">
            <Select
              size="small"
              onChange={e => handleChange('mode', e)}
              v-model:value={componentConfig.value.optionData.mode}
              options={[
                { label: '单选', value: '' },
                { label: '多选', value: 'multiple' },
              ]}
              placeholder="请选择模式"
            ></Select>
          </div>
        </div>
      </div >
    )

    return () => <SettingItemBox title="模式" v-slots={optionsSlot} />
  },
})

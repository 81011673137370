import { r, d, h } from "@/units"
import { reactive } from "vue"
import "./index.less"
import { siderLeftPx } from "@/utils/const"
export default d({
  name: 'pageEditorLayoutSider',
  setup: (props, context) => {
    const VNode = {
      render() {
        return h(
          r("a-layout-sider"),
          {
            "width": siderLeftPx + "px"
          },
          context.slots.default
        )
      }
    }
    return () => h(VNode)
  }
})